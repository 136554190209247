import React, { useEffect, useState } from 'react';
import {
  validateUserInput,
  authenticateUser,
  sendValidateOtp,
  forgotPasswordAction,
  updateCustomer,
} from '../../../actionCreators/auth';
import './Login.scss';
import { useDispatch, useSelector } from 'react-redux';
import { validateLoginCreds, validateSingaporeMobileNumber } from './utils';
import apiClient from '../../../helpers/apiClient';
import { apiPath } from '../../../config';
import { showGlobalHeaderFooter } from '../../../actionCreators/header';
import { setToastMessage } from '../../../actionCreators/common';
import { parseQueryParams } from '../../../own-days/helper';
import { odPushClevertapEvent } from '../../../utils/clevertap';
import Top from './Top';

const Login = props => {
  const { history } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isMobileLogin, setIsMobileLogin] = useState(false);
  const [showOTPField, setShowOTPField] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(30);
  const [timerRunning, setTimerRunning] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [visitReason, setVisitReason] = useState('buyGlasses');

  const dispatch = useDispatch();
  const userInputValidate = useSelector(state => state.auth?.userInputValidate);
  const loginError = useSelector(state => state.auth?.loginError);
  const isLogin = useSelector(state => state.common?.userInfo?.result?.login);
  const emailStatus = useSelector(state => state?.auth?.emailStatus);
  const forgotpassError = useSelector(state => state?.auth?.forgotpassError);
  const loginErr = useSelector(state => state?.auth?.loginError?.error);
  const userInputError = useSelector(state => state?.auth?.userInputErr?.message);
  const otpSentError = useSelector(state => state?.auth?.oTPSent?.error?.message);
  const otpSentToken = useSelector(state => state.auth?.oTPSent?.result?.token);
  const authLoading = useSelector(state => state?.auth?.loading);

  const updateCustomerLoaded = useSelector(state => state.auth.updateCustomerLoaded);
  const loginSuccess = useSelector(state => state.auth.loginSuccess);
  const oTPLoginSuccess = useSelector(state => state.auth.oTPLoginSuccess);
  const { storeCode } = parseQueryParams(['storeCode']);
  const { whatsappId, mobileNo, staffCode } = parseQueryParams([
    'whatsappId',
    'mobileNo',
    'staffCode',
    'storeCode',
  ]);

  const isSpecialLInk = storeCode || whatsappId || staffCode || mobileNo;

  useEffect(() => {
    if (mobileNo) {
      setUsername(mobileNo);
    }
  }, [mobileNo]);

  const prevUrl = props?.location?.state?.prevUrl;

  useEffect(() => {
    dispatch(showGlobalHeaderFooter({ header: false, footer: false, secondaryHeader: false }));
  }, [dispatch]);

  useEffect(() => {
    odPushClevertapEvent('Page Viewed', {
      pageName: 'Login Page',
      pageUrl: window.location.href,
    });
  }, []);

  // {
  //   if (otpSent === true && isMobileLogin) {
  //     const data = {
  //       username,
  //       code: otpValue,
  //       phoneCode: "+65",
  //       type: 'VALIDATE_OTP'
  //     }
  //     dispatch(sendValidateOtp(data));
  //   }
  // }

  // {
  //   const data = {
  //     data: {
  //       username,
  //     },
  //     phoneCode: "+65",
  //     captchaKey: null,
  //     type: 'SEND_OTP'
  //   }
  //   dispatch(sendValidateOtp(data));
  // }

  useEffect(() => {
    console.log('this is loginErr ===> loginErr', loginErr);
    dispatch(setToastMessage({ warning: true, message: loginErr, timeout: 3000 }));
  }, [loginErr]);

  useEffect(() => {
    console.log('this is loginErr ===> otpSentError', otpSentError);
    dispatch(setToastMessage({ warning: true, message: otpSentError, timeout: 3000 }));
  }, [otpSentError]);

  useEffect(() => {
    console.log('this is loginErr ===> userInputError', loginErr);
    dispatch(setToastMessage({ warning: true, message: userInputError, timeout: 3000 }));
  }, [userInputError]);

  useEffect(() => {
    let interval;
    if (timerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      // If timer reaches 0, stop the timer
      setTimerRunning(false);
    }

    // Cleanup function
    return () => clearInterval(interval);
  }, [timer, timerRunning]);

  const handleSubmit = e => {
    e.preventDefault();

    // 1. Basic validation
    const obj = validateLoginCreds(username, password);

    // 2. If creds are valid
    if (obj.isValid) {
      setErrorMsg(obj.msg);

      // Check user account exist or not.
      if (!otpSent) {
        dispatch(
          validateUserInput({
            isMobileUserOnly: obj.isMobileUserOnly,
            validBlock: obj.validBlock,
            username,
            password,
          })
        );
      }

      if (otpSent && isMobileLogin) {
        const data = {
          username,
          code: otpValue,
          phoneCode: '+65',
          type: 'VALIDATE_OTP',
        };
        dispatch(sendValidateOtp(data));
      }
    } else {
      setErrorMsg(obj.msg);
    }
  };

  useEffect(() => {
    console.log('this is user input validate ==>', userInputValidate);
    if (userInputValidate?.result?.accounts?.length > 0) {
      if (isMobileLogin && !otpSent) {
        sendOtp();
      } else {
        dispatch(authenticateUser({ username, password }));
      }
    }
  }, [userInputValidate]);

  useEffect(() => {
    if (isSpecialLInk && updateCustomerLoaded) {
      if (otpSentToken) {
        window.location.href = '/';
      }
    }

    if (otpSentToken && !isSpecialLInk) {
      window.location.href = '/';
    }
  }, [otpSentToken, updateCustomerLoaded]);

  useEffect(() => {
    const { storeCode } = parseQueryParams(['storeCode']);

    const redirectTo = prevUrl ? prevUrl.replace(/\/sg\/en/g, '') : '';

    if (isSpecialLInk) {
      if (isLogin && updateCustomerLoaded) {
        if (redirectTo?.length > 0 || redirectTo === '') {
          window.location.href = redirectTo === '/' ? redirectTo : `/sg/en${redirectTo}`;
          // history.push(redirectTo);
        } else {
          history.go(-1);
        }
      }
    } else if (isLogin) {
      if (redirectTo?.length > 0 || redirectTo === '') {
        window.location.href = redirectTo === '/' ? redirectTo : `/sg/en${redirectTo}`;
        // history.push(redirectTo);
      } else {
        history.go(-1);
      }
    }
  }, [history, isLogin, loginSuccess, oTPLoginSuccess, prevUrl, updateCustomerLoaded]);

  useEffect(() => {
    const { mobileNo, staffCode, storeCode } = parseQueryParams([
      'mobileNo',
      'staffCode',
      'storeCode',
    ]);

    if ((loginSuccess || oTPLoginSuccess) && (storeCode || staffCode || whatsappId)) {
      dispatch(updateCustomer({ storeCode, staffCode, mobileNo, visitReason, whatsappId }));
    }
  }, [dispatch, loginSuccess, oTPLoginSuccess]);

  useEffect(() => {
    // On each username input validating is it mobile login or email login...
    setIsMobileLogin(false);
    setOtpSent(false);
    if (validateSingaporeMobileNumber(username)) {
      setIsMobileLogin(true);
    }
  }, [username]);

  // This is handling otp errors
  // useEffect(() => {
  //   if (otpSent?.error?.message) {
  //     const message = otpSent?.error?.message?.replaceAll("lenskart", "owndays");
  //     setErrorMsg(message);
  //   }
  // }, [otpSent]);

  const sendOtp = () => {
    const client = apiClient();
    client.setHeader();
    client
      .post(`${apiPath.mobileLoginSendOTP}`, {
        telephone: username,
        captcha: null,
        phoneCode: '+65',
        type: 'SEND_OTP',
      })
      .then(res => {
        console.log('this is send otp response =====> res', res);
        setShowOTPField(true);
        setOtpSent(true);
        setTimerRunning(true);
      })
      .catch(err => {
        console.log('this is send otp response =====> err', err);
        setShowOTPField(false);
        setOtpSent(false);
        const msg = err?.message?.replaceAll('lenskart', 'owndays');
        setErrorMsg(msg);
        setTimerRunning(true);
      });
  };

  const handleResendOTP = () => {
    // Simulating OTP generation
    // const newOTP = Math.floor(1000 + Math.random() * 9000);
    // setOTP(newOTP);

    // Reset timer
    setTimer(30);
    setTimerRunning(true);
    sendOtp();
  };

  const handleForgotEmail = e => {
    e.preventDefault();
    dispatch(forgotPasswordAction({ email: forgotEmail }));
  };

  return (
    <div className="od-login-page-m">
      <Top/>
      <div className="od-login-page-m__content">
        {isForgotPassword ? (
          <>
            <p className="od-login-page-m-p">Please enter your email to reset password</p>
            <form id="forgotPasswordMobile" onSubmit={handleForgotEmail}>
              <input
                required
                className="od-login-page-m__input"
                placeholder="Please enter your email id"
                type="email"
                value={forgotEmail}
                onChange={e => setForgotEmail(e.target.value)}
              />
            </form>
            {forgotpassError ? (
              <p className="od-login-page-m-input-error">
                {forgotpassError?.message?.replaceAll('phone number or', '')}
              </p>
            ) : null}
            {emailStatus == 'sent' ? (
              <p className="od-login-page-m-p">
                We have sent you a mail containing the link to change the password of your account.
              </p>
            ) : null}
            {/* {emailStatus == 'fail' ? <p>Something went wrong</p> : null} */}
          </>
        ) : null}
        {!isForgotPassword ? (
          <>
            <h4 className="od-login-page-m-h4">Login</h4>
            <p className="od-login-page-m-p">Login into your account to continue checkout</p>
            <form id="loginODMobile" onSubmit={handleSubmit}>
              <div className="od-login-page-m-number-wrapper">
                {isMobileLogin && (
                  <input
                    disabled
                    className="od-login-page-m-number-wrapper_code od-login-page-m__input"
                    placeholder="+65"
                  />
                )}
                <input
                  required
                  className={`od-login-page-m__input ${
                    isMobileLogin ? 'od-login-page-m-number-wrapper_number' : ''
                  }`}
                  maxLength={isMobileLogin ? 8 : 100}
                  placeholder="Phone no/email id"
                  type="text"
                  value={username}
                  onChange={e => {
                    const userLoginValue = e.target.value;
                    if (isMobileLogin) {
                      setUsername(userLoginValue.slice(0, 8));
                    } else {
                      setUsername(e.target.value);
                    }
                  }}
                />
              </div>
              {!isMobileLogin && (
                <>
                  <input
                    required
                    className="od-login-page-m__input"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  {storeCode && (
                    <select
                      className="visitPurpose_od_mobile"
                      id="visitPurpose"
                      name="genvisitPurposeder"
                      value={visitReason}
                      onChange={e => setVisitReason(e.target.value)}
                    >
                      <option value="buyGlasses">Glasses</option>
                      <option value="contactLens">Contact Lens</option>
                      <option value="lensReplacement">Lens Replacement</option>
                      <option value="afterCare">Warranty</option>
                    </select>
                  )}
                </>
              )}
              {errorMsg && <div className="od-login-page-m-input-error">{errorMsg}</div>}
              {userInputValidate?.result?.accounts?.length === 0 && (
                <div className="od-login-page-m-input-error">Account does not exists</div>
              )}
              {loginError?.message && (
                <div className="od-login-page-m-input-error">{loginError?.message}</div>
              )}
              {!isMobileLogin && (
                <div
                  className="od-login-page-m__linkText"
                  onClick={() => setIsForgotPassword(true)}
                >
                  Forgot password
                </div>
              )}
              {isMobileLogin && showOTPField && (
                <>
                  <input
                    required
                    className="od-login-otp-input od-login-page-m__input"
                    maxLength="4"
                    placeholder="Enter OTP"
                    type="number"
                    value={otpValue}
                    onChange={e => setOtpValue(e.target.value)}
                  />
                  {storeCode && (
                    <select
                      className="visitPurpose_od_mobile"
                      id="visitPurpose"
                      name="genvisitPurposeder"
                      value={visitReason}
                      onChange={e => setVisitReason(e.target.value)}
                    >
                      <option value="buyGlasses">Glasses</option>
                      <option value="contactLens">Contact Lens</option>
                      <option value="lensReplacement">Lens Replacement</option>
                      <option value="afterCare">Warranty</option>
                    </select>
                  )}
                </>
              )}
              {!timerRunning && isMobileLogin && showOTPField && (
                <span className="od-login-page-m__linkText" onClick={handleResendOTP}>
                  Resend OTP
                </span>
              )}
              {timerRunning && isMobileLogin && showOTPField && (
                <span className="od-login-page-m__linkText">Resend OTP in {timer}</span>
              )}
            </form>
          </>
        ) : null}
      </div>
      <div className="od-login-page-m__footer">
        {/* <div className="divider-line-m">
          <div className="divider-line-m-left" />
          <div className="divider-line-m-text">Or</div>
          <div className="divider-line-m-right" />
        </div> */}
        <div className="od-login-page-m__footer__content">
          <div className="text-center">
            Need an account?{' '}
            <span
              className="od-login-page-m__linkText"
              onClick={() => {
                if (storeCode) {
                  window.location.href = `/sg/en/customer/account/signup?whatsappId=${whatsappId}&mobileNo=${mobileNo}&storeCode=${storeCode}`;
                } else {
                  // window.location.href = '/sg/en/customer/account/signup';
                  if (prevUrl && prevUrl?.includes('cart')) {
                    return history.push({
                      pathname: '/customer/account/signup',
                      state: { prevUrl },
                    });
                  }

                  history.push('/customer/account/signup');
                }
              }}
            >
              Sign up
            </span>
          </div>
          {!isForgotPassword ? (
            <button className="od-login-page-m__sb" form="loginODMobile" type="submit">
              {authLoading ? 'Loading...' : 'Login'}
            </button>
          ) : (
            <button className="od-login-page-m__sb" form="forgotPasswordMobile" type="submit">
              {authLoading ? 'Loading...' : 'Continue'}
            </button>
          )}
        </div>
      </div>
      {/* <div className='od-login-page-right'>
        <div className="od-login-page-right-flex">
          <h3 className='od-login-page-right-h1'>Please sign in or create an account to complete checkout</h3>
          <div className='od-login-page-right-form'>
            <h4>Sign in</h4>
            <form onSubmit={handleSubmit}>
              <div className='od-login-page-number-wrapper'>
                {isMobileLogin && <input className='od-login-page-number-wrapper_code' disabled placeholder='+65' />}
                <input required className={isMobileLogin ? 'od-login-page-number-wrapper_number' : ''} value={username} onChange={(e) => setUsername(e.target.value)} type="text" placeholder='Phone no/email id' />
              </div>
              {!isMobileLogin && <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder='Password' />}
              {!isMobileLogin && <div className='od-login-page-right-form-fp'>Forgot password</div>}
              {isMobileLogin && showOTPField && (
                <input onChange={(e) => setOtpValue(e.target.value)} value={otpValue} type="number" placeholder='Enter OTP' class="od-login-otp-input" maxlength="4" />
              )}
              {!timerRunning && isMobileLogin && showOTPField && <span className="od-login-page-right-form-fp" onClick={handleResendOTP}>Resend OTP in 30</span>}
              {timerRunning && isMobileLogin && showOTPField && <span className="od-login-page-right-form-fp">Resend OTP in {timer}</span>}
              <button className='od-login-page-right-form-sb' type='submit'>Login</button>
              {errorMsg && <div className='od-login-page-input-error'>{errorMsg}</div>}
              {userInputValidate?.result?.accounts?.length === 0 && <div className='od-login-page-input-error'>Account does not exists</div>}
              {loginError?.message && <div className='od-login-page-input-error'>{loginError?.message}</div>}
            </form>
            <div className="divider-line">
              <div className='divider-line-left' />
              <div className='divider-line-text'>Or</div>
              <div className='divider-line-right' />
            </div>
            <div className='text-center'>Need an account? <span className='od-login-page-right-form-fp' onClick={() => history.push('/customer/account/signup')}>Sign up</span></div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Login;
