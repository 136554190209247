import React from 'react';
import './Top.scss';

const BENEFITS = [
  {
    iconPath: 'https://static1.lenskart.com/media/owndays/img/customer/account/icon-benefit-1.svg',
    title: `Earn membership<br />points`,
  },
  {
    iconPath: 'https://static1.lenskart.com/media/owndays/img/customer/account/icon-benefit-2.svg',
    title: `Get exclusive<br />offers`,
  },
  {
    iconPath: 'https://static1.lenskart.com/media/owndays/img/customer/account/icon-benefit-3.svg',
    title: `Early access<br />sales`,
  },
];

const Top = () => {
  return (
    <div className="od-auth-page-m__top">
      <a href="/sg/en">
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="https://www.w3.org/2000/svg"
        >
          <path d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z" fill="#6A6A6A" />
        </svg>
      </a>
      <ul>
        {BENEFITS?.map(item => (
          <li key={item.title}>
            <img src={item.iconPath} alt={item.title} />
            <p dangerouslySetInnerHTML={{__html: item.title}} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Top;
