import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../../../CommonComponents/Spinner/Spinner';

const MyAccLoadable = Loadable({
  loader: () =>
    import('../../../../src/own-days/views/mobile/MyAccount/MyAccount').then(
      module => module.default
    ),
  loading: () => <Spinner />,
});

export default MyAccLoadable;
