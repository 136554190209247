import React from 'react';
import { showBarcodeModal } from '../../../actionCreators/common.js';
import Button from '../../../CommonComponents/BootstrapComponents/Button/Button.js';
import Html5QrcodePlugin from './Html5QrcodePlugin.jsx';
import { connect } from 'react-redux';
import './QrScanner.scss';

function QrScanner(props) {
  const { showBarcodeModal, history, showSearch } = props;
  return (
    <div className="qr-modal">
      <Button className="qr-close-btn" onClick={() => showBarcodeModal(false)}>
        <img
          alt="close"
          className="cursor-pointer"
          height="10"
          src="/assets/svg/Cross.svg"
          width="10"
        />
      </Button>
      <section>
        <Html5QrcodePlugin history={history} showSearch={showSearch} />
      </section>
    </div>
  );
}

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
  return {
    showBarcodeModal: data => dispatch(showBarcodeModal(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QrScanner);
