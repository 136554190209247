import React from 'react';
import Loadable from 'react-loadable-visibility/react-loadable';
import Spinner from '../../../CommonComponents/Spinner/Spinner';

const LoadableModule = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'OfflineToast' */ './OfflineToast').then(module => module.default),
  loading: () => <Spinner />,
});

export default LoadableModule;
