import './search.scss';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
// actions
import { searchInputChange, suggestionClick } from '../../../actionCreators/search';
// utils
import { debounce, showElement } from '../../../utils/helper';
import Customevent from '../../../utils/Customevent';
// components
import { Form, Field } from 'react-final-form';
import BackButtonImg from '../../../../static/assets/svg/od-arrow-left-grey-icon.svg';
// import { Link } from 'react-router-dom';
// import LinkProvider from '../../../CommonComponents/LinkProvider/LinkProvider';
// import { langCodeAllowed } from '../../../locales';
import Spinner from '../../../CommonComponents/Spinner/Spinner';
import { v4 } from 'uuid';

function unpackSearchData(searchResult = [], searchMetaData = []) {
  let popularProducts = [];
  let matchingProducts = [];
  if (searchResult) {
    popularProducts = [...searchResult];
    matchingProducts = [...searchMetaData];
  }
  return { matchingProducts, popularProducts };
}

let prevSearchResult;
class Search extends PureComponent {
  static getDerivedStateFromProps(props) {
    if (prevSearchResult !== props.searchResult) {
      return unpackSearchData(props.searchResult, props.searchMetaData);
    }
    return null;
  }
  constructor(props) {
    super(props);
    this.state = { matchingProducts: [], popularProducts: [] };
    this.onSearchInputChange = debounce(this.onSearchInputChange, 300);
    if (props.searchResult) this.state = unpackSearchData(props.searchResult, props.searchMetaData);
    this.state = {
      showResults: false,
      loading: false,
    };
    this.inputRef = React.createRef(null);
    this.onSubmit = this.onSubmit.bind(this);
    this.offLoading = this.offLoading.bind(this);
  }
  componentDidMount() {
    const { isExchange } = this.props;
    window.callback = response => {
      const event = new Customevent('SEARCH_CALLBACK', { detail: { response } });
      event.dispatch();
    };
    if (isExchange) {
      showElement('header.header', false);
    }
    this.initialValues = { searchQuery: window.prevSearchQuery };
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { searchResult, showSearchModal } = this.props;
      if (searchResult !== prevProps.searchResult) {
        prevSearchResult = searchResult;
        this.offLoading();
      }
      if (showSearchModal !== prevProps.showSearchModal) {
        if (showSearchModal) {
          this.inputRef.current.focus();
        }
      }

      if (
        this.props.searchData !== prevProps.searchData ||
        this.props.searchDataQuery !== prevProps.searchDataQuery
      ) {
        if (this.props.searchData && this.props.searchData.webUrl) {
          window.location.href = `/sg/en/${this.props.searchData.webUrl}`;
        } else if (this.props.searchDataQuery) {
          window.location.href = `/sg/en/search?q=${this.props.searchDataQuery}`;
        }
      }
    }
  }

  componentWillUnmount() {
    window.callback = null;
  }
  onSearchInputChange(val) {
    const query = val ? val.trim() : '';
    if (!query) {
      this.resetSearchResults();
    } else {
      // odPushClevertapEvent('Search', {
      //   searchQuery: query,
      // });
      this.setState({ showResults: true, loading: true });
    }
    // OD__CLEVERTAP__EVENT (Search Click)
    // if (typeof window.clevertap !== 'undefined') {
    //   window.clevertap.event.push('Search Click', {
    //     searchName: query,
    //   });
    //   console.log(`🚀 ~ Search Click ~ query: ${query}`, window.clevertap.event);
    // }
    this.props.searchProduct(query);
    window.prevSearchQuery = val;
  }

  onSubmit(event) {
    const { showSearch, history } = this.props;
    if (!event.searchQuery) return;
    const redirectPath = `/search?q=${event.searchQuery}`;
    showSearch(false);
    history.push(redirectPath);
    this.resetSearchResults();
  }

  resetSearchResults() {
    this.setState({
      matchingProducts: [],
      popularProducts: [],
      showResults: false,
    });
  }

  offLoading() {
    this.setState({ loading: false });
  }
  boldData(data) {
    const dataParamValue = this.inputRef?.current?.value?.trim() || '';
    const parts = data.split(new RegExp(`(${dataParamValue})`, 'gi'));
    return (
      <span>
        {parts.map(part =>
          part.toLowerCase() === dataParamValue.toLowerCase() ? (
            <span key={v4()} className="od-font-bold">
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  }
  render() {
    const {
      showSearch,
      showSearchModal,
      dataLocale,
      // currencyMark,
      // localeInfo,
      // isExchange,
      // redisCommonData,
      // redisCommonData: { ENABLE_BARCODE_SCANNER },
    } = this.props;
    // const { trendingSearch_mobile } = langCodeAllowed[localeInfo.countryCode];
    const { matchingProducts, popularProducts } = this.state;
    // const { PRODUCT, CURRENCY_CODE } = dataLocale;
    // const currentMark = currencyMark[CURRENCY_CODE];
    return (
      <div className={`search-container ${showSearchModal ? 'display-flex' : 'display-none'}`}>
        <div className="search-header pd-b10 display-flex">
          {/* <span
            className="pd-l15 pd-r15 inline-block fsp24"
            style={{ transform: 'rotate(180deg)' }}
            onClick={() => showSearch(false)}
          >
            &#x2794;
          </span> */}
          <img
            alt="back"
            className="search-header-back-btn"
            src={BackButtonImg}
            onClick={() => showSearch(false)}
          />
          <Form
            initialValues={this.initialValues}
            render={({ handleSubmit }) => (
              <form className="flex-1 mr-0" onSubmit={handleSubmit}>
                <Field name="searchQuery">
                  {({ input }) => (
                    <input
                      ref={this.inputRef}
                      {...input}
                      autoComplete="off"
                      className="search-input pd-t10"
                      placeholder={`${dataLocale.SEARCH}`}
                      type="text"
                      onChange={e => {
                        const val = e.target.value;
                        input.onChange(val);
                        this.onSearchInputChange(val, e);
                      }}
                      onKeyDown={e => {
                        if (
                          e.which === 220 ||
                          e.which === 219 ||
                          e.which === 221 ||
                          e.key === '(' ||
                          e.key === ')' ||
                          e.key === '?' ||
                          e.key === '%'
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                  )}
                </Field>
              </form>
            )}
            onSubmit={this.onSubmit}
          />
        </div>
        <section className="search-results-container">
          <div>
            {this.state.showResults && matchingProducts?.length > 0 && (
              <div className="matching-keywords pd-b10">
                {matchingProducts.map(item => {
                  return (
                    <div key={v4()} className="search-results-item-wrapper">
                      <img
                        alt="true"
                        height="25px"
                        src="https://static.lenskart.com/media/owndays/desktop/img/od-misc/search-md.svg"
                        width="25px"
                      />
                      <div className="search-results-item text-dark_grey_blue fsp14">
                        <div
                          onClick={() => {
                            this.onSearchInputChange(item.query);
                            this.props.suggestionClick(item);
                          }}
                        >
                          {this.boldData(item.objectID)}
                        </div>
                        <svg
                          fill="none"
                          height="12"
                          viewBox="0 0 12 12"
                          width="12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.21 7.71v-6.5h6.5"
                            stroke="currentColor"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          ></path>
                          <path
                            d="m1.21 1.21 9.42 9.42"
                            stroke="currentColor"
                            strokeWidth="1.5"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {/* {this.state.showResults && popularProducts?.length > 0 && (
              <div className="popular-recommendarions" style={{ paddingBottom: '50px' }}>
                <div
                  className="search-results__heading fsp20 mr-b10 pd-t12"
                  style={{ borderTop: '1px solid #eaeff4' }}
                >
                  {dataLocale.WE_RECOMMEND}
                </div>
                {popularProducts.map(item => {
                  return (
                    <Link
                      key={item.id}
                      to={{
                        pathname: item.product_url.substring(
                          item.product_url?.indexOf('.com/') + 4
                        ),
                        state: { productId: '', section: 'prod' },
                      }}
                      onClick={() => showSearch(false)}
                    >
                      <div className="display-grid search-results-product text-dark_grey_blue mr-b10">
                        <div className="product-image display-flex align-items-center justify-content-center">
                          <img
                            alt={PRODUCT}
                            src={item.image_url}
                            onError={() => {
                              this.src =
                                'https://static.lenskart.com/media/mobile/images/no-image.png';
                            }}
                          />
                        </div>
                        <div className="product-name">
                          <div className="brand fsp14 fw500 pd-b4">{item?.name}</div>
                          <div className="name fsp12 fw300">{item?.brand_name}</div>
                        </div>
                        {!isExchange && (
                          <div className="price display-flex align-items-center justify-content-center fw500">
                            {currentMark} {item.prices[0].price}
                          </div>
                        )}
                      </div>
                    </Link>
                  );
                })}
              </div>
            )} */}
            {/* {!this.state.showResults && (
              <div className="trending-search">
                <div className="search-results__heading fsp20 mr-b5">
                  {dataLocale.TRENDING_SEARCHES}
                </div>
                {trendingSearch_mobile.map(item => {
                  return (
                    <div key={item.name} className="search-results-item text-dark_grey_blue fsp14">
                      <LinkProvider to={item.url} onClick={() => showSearch(false)}>
                        {item.name}
                      </LinkProvider>
                    </div>
                  );
                })}
              </div>
            )} */}
            {Boolean(
              this.state.loading && popularProducts?.length === 0 && matchingProducts?.length === 0
            ) && <Spinner />}
            {Boolean(
              this.inputRef.value &&
                !this.state.loading &&
                popularProducts?.length === 0 &&
                matchingProducts?.length === 0
            ) && <div className="pd-t10 pd-b10 fsp14">{dataLocale.NO_RESULT_FOUND}</div>}
          </div>
        </section>
        {/* {ENABLE_BARCODE_SCANNER && (
          <div className="qr-button-container">
            <QrScannerCTA />
          </div>
        )} */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const searchData = state.search.searchData;
  const algoliaSearchData = state.search.algoliaSearchData;
  const searchResult = algoliaSearchData ? algoliaSearchData.hits : null;
  const searchMetaData = algoliaSearchData ? algoliaSearchData.hits : null;
  return {
    searchData,
    searchResult,
    searchMetaData,
    searchDataQuery: state.search.query,
    isExchange: state.returnExchange.isExchange,
    dataLocale: state.locale,
    localeInfo: state.common.localeInfo,
    currencyMark: state.common.currencyMark,
    redisCommonData: state.common.redisCommonData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchProduct: val => dispatch(searchInputChange(val)),
    suggestionClick: val => dispatch(suggestionClick(val)),
  };
}

Search.defaultProps = {
  searchResult: [],
  searchMetaData: {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
