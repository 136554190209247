import React, { useEffect } from 'react';
import './PrimerCard.scss';

import { PrimerCardShimmer } from './PrimerCardShimmer';

import { showPaymentMethodsUI } from '../../../utils/primerUtils';
import { hasContactLensItems } from '../../../utils/helper';
import { connect } from 'react-redux';
import { orderPayment } from '../../../actionCreators/checkout';

const PrimerCard = props => {
  const {
    paymentMethods,
    headless,
    orderPayment,
    clientTokenData,
    orderId,
    isRetry,
    redisCommonData,
    cartData,
    paymentData,
    loadingPlaceOrder,
    isLoading,
  } = props;

  const isContactLensConsentEnabled = !!(
    hasContactLensItems(cartData?.result?.items) && redisCommonData?.CL_DISCLAIMER
  );

  useEffect(() => {
    if (headless && clientTokenData) {
      let consent;
      // Check contact lens consent
      if (isContactLensConsentEnabled) {
        // timestamp format for consent yyyy-mm-dd hh:mm:ss
        const date = new Date();
        const timestamp = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
          '0' + date.getDate()
        ).slice(-2)} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        consent = {
          type: 'contact-lens',
          timestamp,
        };
      }
      showPaymentMethodsUI({
        methodToShow: 'PAYMENT_CARD',
        device: 'desktop',
        primerToken: clientTokenData?.result?.primerClientSessionToken,
        headless,
        orderPayment,
        orderId: isRetry ? orderId : null,
        consent,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods, headless]);

  useEffect(() => {
    if (paymentData?.status === 200 && !loadingPlaceOrder) {
      const button = document.getElementById('submit-button');
      if (button) {
        button.click();
      }
    }
  }, [paymentData, loadingPlaceOrder]);

  return (
    <>
      {isLoading && <PrimerCardShimmer />}
      <div className="od-primer-card-m" style={{ display: isLoading ? 'none' : 'block' }}>
        {/* Card Number Input and Error Div */}
        <div className="od-primer-card-field-m">
          <div className="od-primer-card-field-m__input" id="checkout-card-number-input">
            <div className="card-error block fsp10 text-color_brick_red" id="cardNumberRequired" />
            <div className="axtra-error-field" id="cardNumberRequiredError">
              Error field
            </div>
          </div>
        </div>
        {/* Expiry Input and Error Div */}
        <div className="od-primer-card-m__two-fields-wrapper">
          <div className="od-primer-card-field-m">
            <div className="od-primer-card-field-m__input" id="checkout-card-expiry-input">
              <div className="card-error block fsp10 text-color_brick_red" id="expiryRequired" />
              <div className="axtra-error-field" id="expiryRequiredError">
                Error field
              </div>
            </div>
          </div>
          {/* CVV Input and Error Div */}
          <div className="od-primer-card-field-m">
            <div className="od-primer-card-field-m__input" id="checkout-card-cvv-input">
              <div className="card-error block fsp10 text-color_brick_red" id="cvvRequired" />
              <div className="axtra-error-field" id="cvvRequiredError">
                Error field
              </div>
            </div>
          </div>
        </div>
        {/* Card Name Input and Error Div */}
        <div className="od-primer-card-m__fields-wrapper">
          <div className="od-primer-card-field-m">
            <input
              className="od-primer-card-field-m__input"
              id="checkout-card-holder-input"
              placeholder="Full Name"
            />
            <div className="card-error block fsp10 text-color_brick_red" id="cardnameRequired" />
            <div className="axtra-error-field" id="cardnameRequiredError">
              Error field
            </div>
          </div>
        </div>

        <button id="submit-button" style={{ display: 'none' }}>
          Submit
        </button>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    cartData: state.cart.cartData,
    dataLocale: state.locale,
    redisCommonData: state.common.redisCommonData,
    paymentMethods: state.checkout.paymentMethods,
    clientTokenData: state.primer?.clientTokenData,
    primer: state.primer,
    paymentData: state.checkout.paymentData,
    loadingPlaceOrder: state.checkout.loadingPlaceOrder,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    orderPayment: (data, isExchange) => dispatch(orderPayment(data, isExchange)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimerCard);
