import React, { useState, useEffect, useRef } from 'react';
import './PaymentPage.scss';

import { connect, useSelector, useDispatch } from 'react-redux';
import { odPushGA4Event } from '../../../../utils/gaFour';
import { odPushTTQEvent } from '../../../../utils/tiktokPixel';

import { initPrimerHeadless } from '../../../../utils/primerUtils';
import { bindActionCreators } from 'redux';
import {
  getClientToken,
  getPaymentMethodsWithPrimer,
  primerPaymentStatusUpdate,
  resetPrimerData,
  showLoader,
  hideLoader,
} from '../../../../actionCreators/primer';
import { appendScriptToDOM } from '../../../../utils/helper';
import * as cartActions from '../../../../actionCreators/cart';
import SuccessGif from '../../../../../static/assets/img/success.gif';
import OdCartBillDetails from '../../../../mobile/containers/CartNew/OdCartBillDetails';
import { Helmet } from 'react-helmet';
import PaymentMethods from '../../../Mobile/PaymentMethods/PaymentMethods';
import { PaymentPageShimmer, PaymentPageSubmitShimmer } from './PaymentPageShimmer';
import { initiateJusPaySdk } from '../../../../actionCreators/checkout.js';
import apiClient from '../../../../helpers/apiClient.js';
import { apiPath } from '../../../../config.js';
import CheckoutBack from '../../../../mobile/components/CheckoutBack/CheckoutBack.jsx';
import { setToastMessage } from '../../../../actionCreators/common.js';

const PaymentPage = props => {
  const {
    userInfo,
    platform,
    cartData,
    clientTokenData,
    isRetry,
    orderId,
    redisCommonData,
    getClientToken,
    getPaymentMethodsWithPrimer,
    primerPaymentStatusUpdate,
    primerPaymentStatusUpdateData,
    clientTokenErrorData,
    isPrimerLoading,
    currencyMark,
    dataLocale,
    jusPayData,
    setToastMessage,
  } = props;
  const history = props?.history;
  const [isSuccess, setIsSuccess] = useState(false);
  const [headless, setHeadless] = useState(undefined);
  const [isPaymentStarted, setIsPaymentStarted] = useState(false);
  const [fakeLoading, setFakeLoading] = useState(true);
  const [successOrderId, setSuccessOrderId] = useState(null);
  const [hidePaynow, setHidePaynow] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('PRIMER_CARD');
  const [showPaymentMethods, setShowPaymentMethods] = useState(true);

  const [formValidity, setFormValidity] = useState({
    isCardNumberValid: false,
    isExpiryDateValid: false,
    isCvvValid: false,
    isCardNameValid: false,
  });

  useEffect(() => {
    console.log('🚀 ~ formValidity:', formValidity);
  }, [formValidity]);

  const dispatch = useDispatch();

  const { loaded: isCheckoutLoaded, saveShippingAddress } = useSelector(state => state.checkout);

  const { PROCEED_TO_PAYMENT } = dataLocale;

  const validateButtonPrimerRef = useRef(null);

  useEffect(() => {
    if (isCheckoutLoaded && !saveShippingAddress) {
      history.push('/cart');
    }
  }, [isCheckoutLoaded]);

  useEffect(() => {
    setTimeout(() => {
      setFakeLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (isPaymentStarted) {
      const gaPLPSelectItemData =
        JSON.parse(sessionStorage.getItem('ga-plp-select-item-data')) || {};

      const gaAddToCartData = JSON.parse(sessionStorage.getItem('ga-add-to-cart-data')) || {};

      odPushGA4Event('add_payment_info', {
        userInfo,
        cartData,
        gaPLPSelectItemData,
        gaAddToCartData,
      });

      // odPushClevertapEvent('Proceed to payment', {
      //   userInfo,
      //   cartData,
      // });

      odPushTTQEvent('AddPaymentInfo', {
        userInfo,
        cartData,
      });
    }
  }, [isPaymentStarted]);

  useEffect(() => {
    if (headless && !isPrimerLoading && !fakeLoading) {
      // odPushClevertapEvent('Payment Initiated', {
      //   paymentMethod: selectedPaymentMethod,
      // });
    }

    if (isPaymentStarted && headless) {
      // odPushClevertapEvent('Payment Initiated', {
      //   paymentInitiated: true,
      // });
    }
  }, [fakeLoading, headless, isPaymentStarted, isPrimerLoading, selectedPaymentMethod]);

  // This useEffect will check --> is payment status is success...
  // If its success show success screen.
  useEffect(() => {
    if (
      primerPaymentStatusUpdateData &&
      primerPaymentStatusUpdateData?.result?.status === 'SUCCESS'
    ) {
      odPushTTQEvent('CompletePayment', {
        userInfo,
        cartData,
      });

      setIsSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primerPaymentStatusUpdateData]);

  useEffect(() => {}, [isPrimerLoading, headless]);

  const initPrimerSdk = async () => {
    const getHeadless = await initPrimerHeadless({
      token: clientTokenData.result.primerClientSessionToken,
      onAvailablePaymentMethodsLoad: paymentMethods => {
        if (isRetry && orderId && paymentMethods)
          getPaymentMethodsWithPrimer({
            paymentMethods,
            orderId,
            redisCommonData,
            client: 'desktop',
          });
        else if (paymentMethods)
          getPaymentMethodsWithPrimer({
            paymentMethods,
            redisCommonData,
            client: 'desktop',
          });
      },
      createOrderPayment: self.createOrderPayment,
      onCheckoutComplete: param => {
        setIsPaymentStarted(false);
        const { id, orderId } = param?.payment || {};
        primerPaymentStatusUpdate({
          paymentRefId: id,
          // paymentId: paymentData?.result?.payment?.paymentId,
          paymentId: orderId,
        });
        setSuccessOrderId(orderId?.split('_')?.[0]);

        // if (orderId) {
        setIsSuccess(true);
        setTimeout(() => {
          history.push({
            pathname: '/payment-success',
            state: { cartData, orderId, paymentSuccess: true },
          });
        }, [3000]);
        // } else history.push('/customer/account');
      },
      onCheckoutFail: param => {
        const { orderId } = param?.payment || {};

        // if (orderId) {
        setIsSuccess(true);
        setTimeout(() => {
          history.push({
            pathname: '/payment-success',
            state: { cartData, orderId, paymentSuccess: false },
          });
        }, [3000]);
        // }
      },
    });

    setHeadless(getHeadless);
  };

  useEffect(() => {
    // odPushClevertapEvent('Payment Page', {
    //   redirectedToPaymentPage: true,
    // });

    odPushGA4Event('page_view', {
      screenName: 'payment-page',
      userInfo,
      platform,
    });

    // window.clevertapScreenName = 'payment-page';

    // if (typeof window.clevertap !== 'undefined') {
    //   window.clevertap.event.push('page_view', {
    //     screen_name: window.clevertapScreenName,
    //   });
    // }

    // OD_NOTE: This is temp fix b/c redisCommonData having some issues will change later
    if (!window.Primer) {
      // Primer Script Load
      appendScriptToDOM(`https://sdk.primer.io/web/v2.28.1/Primer.min.js`, '', true, () => {
        // Juno API call to get client token from primer
        if (isRetry && orderId) getClientToken(orderId);
        else getClientToken();
      });
    } else if (redisCommonData?.ACTIVATE_PRIMER && window.Primer) {
      // Juno API call to get client token from primer
      if (isRetry && orderId) getClientToken(orderId);
      else getClientToken();
    }

    dispatch(initiateJusPaySdk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clientTokenData?.result?.primerClientSessionToken) {
      initPrimerSdk();
    } else {
      // If something went wrong show message clientTokenErrorData?.message
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientTokenData]);

  useEffect(() => {
    if (headless) headless.start();
  }, [headless]);

  function hyperCallbackHandler(eventData) {
    setShowPaymentMethods(false);
    try {
      if (eventData) {
        const eventJSON = typeof eventData === 'string' ? JSON.parse(eventData) : eventData;
        const event = eventJSON.event;
        // Check for event key
        if (event === 'initiate_result') {
          console.log('initiate result ====>', event);
          // Handle initiate result here
        } else if (event === 'process_result') {
          // Handle process result here
        } else if (event === 'user_event') {
          // Handle Payment Page events
        } else {
          console.log('Unhandled event', event, ' Event data', eventData);
        }
      } else {
        console.log('No data received in event', eventData);
      }
    } catch (error) {
      console.log('Error in hyperSDK response', error);
    }
  }

  const handleGrabpayPayment = () => {
    console.log('this is jus pay data ===>', jusPayData);

    if (jusPayData && jusPayData?.payload) {
      const clientId = jusPayData?.payload?.clientId;
      const merchantId = jusPayData?.payload?.merchantId;
      const environment = jusPayData?.payload?.environment;

      const payload = {
        requestId: jusPayData?.requestId,
        service: 'in.juspay.hyperpay',
        payload: {
          clientId,
        },
      };
      window.HyperServices.preFetch(payload);

      // odPushClevertapEvent('Payment Initiated', {
      //   paymentMethod: selectedPaymentMethod,
      // });

      const initiatePayload = {
        ...jusPayData?.payload,
        integrationType: 'iframe',
        hyperSDKDiv: 'grabpayIframe', // Div ID to be used for rendering
        logLevel: 1,
      };

      const sdkPayload = {
        service: jusPayData?.service,
        requestId: jusPayData?.requestId,
        payload: initiatePayload,
      };

      window.hyperServiceObject = new window.HyperServices();
      window.hyperServiceObject.initiate(sdkPayload, hyperCallbackHandler);

      const client = apiClient();
      client.setHeader();
      client
        .post(`${apiPath.orderPayment}`, {
          device: 'desktop',
          leadSource: null,
          nonFranchiseOrder: false,
          orderId: null, // Retry
          paymentInfo: {
            card: {},
            gatewayId: 'JUSPAY',
            netbanking: {},
            partialPaymentInfo: {
              partialPayment: false,
              partialPaymentAmount: 0,
              partialPaymentMethod: null,
            },
            paymentMethod: 'juspay',
            subscriptionOrderId: null,
          },
        })
        .then(res => {
          const { payload, requestId, service } = res?.result?.payment?.actionInfo?.requestParams;
          const parsePayload = JSON.parse(payload || '');

          const {
            language,
            orderId,
            clientId,
            merchantKeyId,
            orderDetails,
            signature,
            amount,
            customerId,
            customerEmail,
            customerMobile,
            environment,
            merchantId,
            action,
          } = parsePayload;
          const processPayload = {
            requestId,
            service,
            payload: {
              // ...parsePayload,
              action,
              merchantId,
              clientId,
              orderId,
              amount,
              customerId,
              customerEmail,
              customerMobile,
              orderDetails,
              signature,
              merchantKeyId,
              language,
              environment,
            },
          };

          if (payload) {
            console.log('Callback hyper api response orderpayment ==>', processPayload);
            window.hyperServiceObject.process(processPayload);
            setHidePaynow(true);
          }
        })
        .catch(() => {});
    }
  };

  const handleCheckoutBackClick = () => {
    return history.push('/checkout/shipping');
  };

  if (clientTokenErrorData) {
    return (
      <div>
        <h1>Something went wrong</h1>
      </div>
    );
  }

  if (isSuccess) {
    // Show success gif...
    return (
      <div className="od-payment-page-m__success">
        <img alt="payment-success" className="od-payment-page-m-d-success-image" src={SuccessGif} />
      </div>
    );
  }

  return (
    <>
      <CheckoutBack onClick={handleCheckoutBackClick} />
      <div className="od-payment-page-m">
        <Helmet
          meta={[
            {
              name: 'description',
              content: 'Payment Page｜OWNDAYS ONLINE STORE - OPTICAL SHOP',
            },
          ]}
          title="Payment Page｜OWNDAYS ONLINE STORE - OPTICAL SHOP"
        />
        <div className="od-payment-page-m__content">
          <div className="od-payment-page-m__items">
            {headless && !isPrimerLoading && !fakeLoading ? (
              <>
                <div
                  id="grabpayIframe"
                  style={showPaymentMethods ? { height: 0 } : { height: 800 }}
                />
                {showPaymentMethods && (
                  <PaymentMethods
                    headless={headless}
                    isLoading={isPaymentStarted}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                  />
                )}

                {isPaymentStarted && selectedPaymentMethod !== 'GRABPAY' ? (
                  <PaymentPageSubmitShimmer />
                ) : null}

                {!hidePaynow ? (
                  <button
                    ref={validateButtonPrimerRef}
                    className="od-payment-page-m__submit od-font-bold"
                    id="validate-button-primer"
                    style={{ visibility: 'hidden', opacity: 0 }}
                    onClick={() => {
                      if (selectedPaymentMethod === 'GRABPAY') {
                        setIsPaymentStarted(true);
                        handleGrabpayPayment();
                      } else {
                        const cardNumberRequiredField =
                          document.getElementById('cardNumberRequiredError');
                        const expiryRequiredField = document.getElementById('expiryRequiredError');
                        const cvvRequiredField = document.getElementById('cvvRequiredError');
                        const cardnameRequiredField =
                          document.getElementById('cardnameRequiredError');

                        const isCardNumberValid = cardNumberRequiredField.innerText === '';
                        const isExpiryDateValid = expiryRequiredField.innerText === '';
                        const isCvvValid = cvvRequiredField.innerText === '';
                        const isCardNameValid = cardnameRequiredField.innerText === '';

                        setFormValidity({
                          isCardNumberValid,
                          isExpiryDateValid,
                          isCvvValid,
                          isCardNameValid,
                        });

                        if (
                          isCardNumberValid &&
                          isExpiryDateValid &&
                          isCvvValid &&
                          isCardNameValid
                        ) {
                          setIsPaymentStarted(true);
                        } else {
                          let errorMessage = 'Please fill in the following fields correctly:\n';

                          if (!isCardNumberValid) errorMessage += '* Card number\n';
                          if (!isExpiryDateValid) errorMessage += '* Expiry\n';
                          if (!isCvvValid) errorMessage += '* CVV\n';
                          if (!isCardNameValid) errorMessage += '* Full Name\n';
                          setToastMessage({ message: errorMessage, timeout: 4000 });
                        }
                      }
                    }}
                  >
                    {PROCEED_TO_PAYMENT}
                    <i className="fa fa-angle-right" />
                  </button>
                ) : null}
              </>
            ) : (
              <PaymentPageShimmer />
            )}
          </div>
          <div className="od-payment-page-m__add-items">
            <OdCartBillDetails
              hideFeatures
              cartActions={cartActions}
              cartObj={cartData?.result}
              currencyMark={currencyMark}
              dataLocale={dataLocale}
              handleProceed={() => validateButtonPrimerRef?.current?.click()}
              history={history}
              isPaymentStarted={isPaymentStarted}
              redisCommonData={redisCommonData}
              showSubmitBtn={headless && !isPrimerLoading && !fakeLoading}
              userInfo={userInfo}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  userInfo: state.common.userInfo,
  platform: state.DTM.platform,
  cartData: state.cart.cartData,
  dataLocale: state.locale,
  currencyMark: state.common.currencyMark,
  clientTokenData: state.primer?.clientTokenData,
  clientTokenErrorData: state.primer?.clientTokenErrorData,
  redisCommonData: state.common.redisCommonData,
  isPrimerLoading: state.primer?.isLoading,
  primerPaymentStatusUpdateData: state.primer?.primerPaymentStatusUpdateData,
  jusPayData: state.checkout?.jusPayData,
});

const mapDispatchToProps = dispatch => {
  return {
    getPaymentMethodsWithPrimer: bindActionCreators(getPaymentMethodsWithPrimer, dispatch),
    primerPaymentStatusUpdate: bindActionCreators(primerPaymentStatusUpdate, dispatch),
    showLoader: data => dispatch(showLoader(data)),
    hideLoader: data => dispatch(hideLoader(data)),
    getClientToken: bindActionCreators(getClientToken, dispatch),
    resetPrimerData: bindActionCreators(resetPrimerData, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
    setToastMessage: data => dispatch(setToastMessage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
