import React, { useState, useEffect, useMemo, useRef } from 'react';
import './OdCartBillDetails.scss';
import OdApplyCoupon from '../../../desktop/components/MainNav/NewCart/ApplyCouponCart/OdApplyCoupon';
import AddGiftCard from '../../../own-days/Mobile/AddGiftCard/AddGiftCard';
import MembershipDiscount from './../../../own-days/CommonComponents/MembershipDiscount/MembershipDiscount';
import { useSelector, useDispatch } from 'react-redux';
import {
  applyMembershipPoints,
  getMembershipPoints,
} from '../../../actionCreators/membershipPoints';
import { getCart } from '../../../actionCreators/cart';
import ArrowDown from '../../../../static/assets/svg/od-bill-chevron-down.svg';
import CartFeatureWarranty from '../../../../static/assets/svg/cart-feature-warranty.svg';
import CartFeatureSecurePayment from '../../../../static/assets/svg/cart-feature-secure-payment.svg';
import CartFeatureShipping from '../../../../static/assets/svg/cart-feature-shipping.svg';
import UnlockedDiscount from '../../../../static/assets/svg/cart-unlocked-discount.svg';
import { OdCartBillDetailsSubmitShimmer } from './OdCartBillDetailsShimmer';
import FixedBottomContainer from '../../../CommonComponents/FixedBottomContainer/FixedBottomContainer';
import ButtonOwnDays from '../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { getDiscountFromCart } from '../../../own-days/helper';

// return if needed delivery section
// import { Link } from 'react-router-dom';
// import DeliveryDetails from '../../../own-days/CommonComponents/PaymentOrderSummury/DeliveryDetails/DeliveryDetails';

const OdCartBillDetails = props => {
  const {
    cartObj,
    dataLocale,
    currencyMark,
    redisCommonData,
    cartData,
    handleProceed,
    showSubmitBtn = true,
    handleProceedDisabled = false,
    hideFeatures = false,
    isPaymentStarted = false,
  } = props;

  const membershipData = useSelector(state => state.membershipPoints.data);
  const applyMembershipData = useSelector(state => state.membershipPoints.applyMembershipData);
  const applyMembershipError = useSelector(state => state.membershipPoints.applyMembershipError);
  const removeMembership = useSelector(state => state.membershipPoints.removeMembership);
  const login = useSelector(state => state.common.login);

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState('');
  const [isApplyMembershipDiscount, setIsApplyMembershipDiscount] = useState(false);
  const [couponDetails, setCouponDetails] = useState(null);
  const [fixedBottomHeight, setFixedBottomHeight] = useState(null);
  const [showBillInfo, setShowBillInfo] = useState(false);

  const fixedBottomRef = useRef(null);

  useEffect(() => {
    const updateCurrentPage = () => {
      const path = window.location.pathname;
      setCurrentPage(path);
    };

    updateCurrentPage();

    window.addEventListener('popstate', updateCurrentPage);

    return () => {
      window.removeEventListener('popstate', updateCurrentPage);
    };
  }, []);

  const isCartPage = currentPage.includes('/cart');
  const isShippingPage = currentPage.includes('/checkout/shipping');
  const isPaymentPage = currentPage.includes('/payment-page');

  useEffect(() => {
    if (isCartPage) {
      setShowBillInfo(true);
    }
  }, [isCartPage]);

  useEffect(() => {
    dispatch(getMembershipPoints());
  }, []);

  useEffect(() => {
    if (applyMembershipError) {
      setIsApplyMembershipDiscount(false);
    }
  }, [applyMembershipError]);

  useEffect(() => {
    if (applyMembershipData) {
      dispatch(getCart());
      setIsApplyMembershipDiscount(false);
    }
  }, [applyMembershipData, dispatch]);

  useEffect(() => {
    if (isApplyMembershipDiscount) {
      dispatch(applyMembershipPoints());
    }
  }, [dispatch, isApplyMembershipDiscount]);

  const handleAddDiscountMembershipPoints = () => {
    setIsApplyMembershipDiscount(true);
  };

  const { FREE, ORDER_TOTAL, BILL_DETAILS, CURRENCY_CODE, USE, MEMBERSHIP_POINTS, ITEM_TOTAL } =
    dataLocale;

  const { NEW_CART_FLOW_DESKTOP } = redisCommonData;
  const currencyCode = cartObj?.currencyCode ?? CURRENCY_CODE;
  const currencySymbol = currencyMark[currencyCode];
  const showCoupon = !!(
    NEW_CART_FLOW_DESKTOP && JSON.parse(NEW_CART_FLOW_DESKTOP)?.enableApplyCoupon === 'ON'
  );

  const giftVoucher = useMemo(() => {
    return (
      cartObj?.finalTotal &&
      cartObj?.finalTotal.length > 0 &&
      cartObj?.finalTotal.filter(el => el.id === 'gv')
    );
  }, [cartObj?.finalTotal]);

  useEffect(() => {
    const updateFixedBottomHeight = () => {
      const height = fixedBottomRef?.current?.scrollHeight;
      const computedStyles = getComputedStyle(fixedBottomRef?.current);
      const paddingTop = parseFloat(computedStyles.paddingTop);
      const paddingBottom = parseFloat(computedStyles.paddingBottom);
      setFixedBottomHeight(height + paddingTop + paddingBottom);
    };

    updateFixedBottomHeight();

    window.addEventListener('resize', updateFixedBottomHeight);

    return () => {
      window.removeEventListener('resize', updateFixedBottomHeight);
    };
  }, []);

  let proceedButtonText = '';
  if (isCartPage) {
    proceedButtonText = 'Select address & checkout';
  } else if (isShippingPage) {
    proceedButtonText = 'Proceed to checkout';
  } else if (isPaymentPage) {
    proceedButtonText = 'Complete payment';
  } else {
    proceedButtonText = 'Button Text';
  }

  const eyeframeItems =
    cartObj &&
    cartObj?.items &&
    cartObj?.items?.length > 0 &&
    cartObj?.items.filter(item => item.classification === 'eyeframe');

  return cartObj ? (
    <>
      <div className="od-cart-bill-details-mobile">
        {/* return if needed delivery section
        {isPaymentPage && (
          <>
            <div className="od-cart-bill-details-mobile__delivery">
              <div className="od-cart-bill-details-mobile__title od-font-bold">Delivering to</div>
              <Link
                className="od-cart-bill-details-mobile__delivery__change od-font-reg"
                to="/checkout/shipping"
              >
                Change
              </Link>
            </div>
            <DeliveryDetails />
          </>
        )} */}
        {!isCartPage && (
          <div
            className="od-cart-bill-details-mobile__title od-font-bold"
            onClick={() => setShowBillInfo(!showBillInfo)}
          >
            <span>{BILL_DETAILS}</span>
            <img alt="arrow down" src={ArrowDown} />
          </div>
        )}

        {showBillInfo && (
          <div className="od-cart-bill-details-mobile__body">
            {/* Item total */}
            <div className="od-cart-bill-details-mobile__item-title od-font-reg">{ITEM_TOTAL}</div>
            <div className="od-cart-bill-details-mobile__item-price od-font-reg">
              {`${cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol} ${
                cartObj?.totals?.subTotal
              }`}
            </div>

            {getDiscountFromCart(cartObj?.finalTotal) ? (
              <>
                <div className="od-cart-bill-details-mobile__item-title od-font-reg">Discount</div>
                <div className="od-cart-bill-details-mobile__item-price od-font-reg">
                  {`- ${
                    cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol
                  } ${getDiscountFromCart(cartObj?.finalTotal)}`}
                </div>
              </>
            ) : null}

            {/* vouchers */}
            {giftVoucher && giftVoucher.length > 0 && (
              <>
                <div className="od-cart-bill-details-mobile__item-title od-font-reg">
                  {giftVoucher[0].label}
                </div>
                <div className="od-cart-bill-details-mobile__item-price od-font-reg">
                  {`${
                    cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol
                  } ${giftVoucher[0].value}`}
                </div>
              </>
            )}

            {/* Taxes */}
            {/* <div className="od-cart-bill-details-mobile__item-title od-font-reg">{TAXES}</div>
          <div className="od-cart-bill-details-mobile__item-price od-font-bold">
            {currencySymbol}
            {cartObj?.totals?.taxes && cartObj?.totals?.taxes.length > 0
              ? cartObj?.totals?.taxes[0].amount
              : ''}
          </div> */}

            {/* Prices */}
            {cartObj?.finalTotal?.length > 0 &&
              cartObj?.finalTotal?.map(
                (entry, index) =>
                  entry?.type === 'tax' && (
                    <React.Fragment key={`price-record-${index}`}>
                      <div className="od-cart-bill-details-mobile__item-title od-font-reg">
                        {entry.label}
                      </div>
                      <div
                        className={`od-cart-bill-details-mobile__item-price od-font-reg ${
                          entry.value === 0 && 'od-cart-bill-details-mobile__shipping-fee'
                        }`}
                      >
                        {entry.value === 0
                          ? FREE
                          : `${entry.type === 'discount' ? '-' : ''}${
                              cartObj?.totals?.currencyCode
                                ? cartObj?.totals?.currencyCode
                                : currencySymbol
                            }${entry.value}`}
                      </div>
                    </React.Fragment>
                  )
              )}

            {isPaymentPage && applyMembershipData && !removeMembership && (
              <MembershipDiscount
                isMobile
                cartObj={cartObj}
                currencySymbol={
                  cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol
                }
                setIsApplyMembershipDiscount={setIsApplyMembershipDiscount}
              />
            )}

            {/* {membershipData ? (
            <>
              <div className="od-cart-bill-details__total od-font-reg">{POINTS_EARNED}</div>
              <div className="od-cart-bill-details__total-price od-font-bold">
                {membershipData.totalEarned} {POINTS}
              </div>
            </>
          ) : null} */}
            {/* {isPayment && <GiftCardList />} */}

            {/* <hr className="od-cart-bill-details-mobile__divider" /> */}

            {/* Total price */}
            <div className="od-cart-bill-details-mobile__total-title od-font-reg">
              {ORDER_TOTAL.toLowerCase()}
            </div>
            <div className="od-cart-bill-details-mobile__total-price od-font-bold">
              {/* {currencySymbol}
            {cartObj?.totals?.total} */}
              {`${cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol} ${
                cartObj?.totals?.total
              }`}
            </div>
          </div>
        )}

        {/* Actions */}
        {showBillInfo && isCartPage && cartObj?.itemsCount > 0 && (
          <>
            {membershipData?.balance && !applyMembershipData ? (
              <div className="od-cart-bill-details-mobile__checkbox">
                <div
                  className={`od-cart-bill-details-mobile__checkbox-inner${
                    isApplyMembershipDiscount ? '--checked' : ''
                  }`}
                  onClick={handleAddDiscountMembershipPoints}
                />
                <span
                  className="od-font-reg"
                  onClick={() => setIsApplyMembershipDiscount(!isApplyMembershipDiscount)}
                >
                  {USE} <span className="od-font-bold">{membershipData?.balance}</span>{' '}
                  {MEMBERSHIP_POINTS.toLowerCase()}
                </span>
              </div>
            ) : null}

            {isPaymentStarted && <OdCartBillDetailsSubmitShimmer />}

            {isCartPage && showBillInfo && (
              <div className="od-cart-bill-details-mobile__actions">
                <div className="od-cart-bill-details-mobile__actions__title od-font-bold">
                  Coupons & giftcards
                </div>
                <div className="od-cart-bill-details-mobile__actions__btns">
                  {showCoupon && (
                    <OdApplyCoupon
                      isMobile
                      cartData={cartData}
                      cartObj={cartObj}
                      currencySymbol={currencySymbol}
                      dataLocale={dataLocale}
                      login={login}
                      redisCommonData={redisCommonData}
                      setCouponDetails={setCouponDetails}
                      style={{ width: '100%' }}
                    />
                  )}

                  <AddGiftCard
                    isMobile
                    cartData={cartData}
                    cartObj={cartObj}
                    currencySymbol={currencySymbol}
                    dataLocale={dataLocale}
                    login={login}
                    redisCommonData={redisCommonData}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            )}
            {showBillInfo && eyeframeItems.length >= 2 && (
              <div className="od-cart-bill-details-mobile__unlocked-discount">
                <img alt="Unlocked Discount" src={UnlockedDiscount} />
                <span>Get 2nd eyeglass at 50% off * Exclude sales items</span>
              </div>
            )}
          </>
        )}
      </div>

      {/* {isCartPage && <UsersAlsoBought isMobile />} */}

      {!hideFeatures && showBillInfo && (
        <div className="od-cart-bill-details-mobile-features">
          <div className="od-cart-bill-details-mobile-features__feature">
            <img
              alt="true"
              className="od-cart-bill-details-mobile-features__image"
              src={CartFeatureWarranty}
            />
            <div className="od-cart-bill-details-mobile-features__title od-font-reg">
              1 Year warranty
            </div>
          </div>
          <div className="od-cart-bill-details-mobile-features__feature">
            <img
              alt="true"
              className="od-cart-bill-details-mobile-features__image"
              src={CartFeatureShipping}
            />
            <div className="od-cart-bill-details-mobile-features__title od-font-reg">
              Free Shipping
            </div>
          </div>
          <div className="od-cart-bill-details-mobile-features__feature">
            <img
              alt="true"
              className="od-cart-bill-details-mobile-features__image"
              src={CartFeatureSecurePayment}
            />
            <div className="od-cart-bill-details-mobile-features__title od-font-reg">
              Secure Payment
            </div>
          </div>
        </div>
      )}
      {(showSubmitBtn || !isPaymentPage) && (
        <>
          <FixedBottomContainer>
            <div ref={fixedBottomRef} className="od-cart-bill-details-mobile-proceed">
              <ButtonOwnDays
                disabled={handleProceedDisabled}
                fontClass="od-font-reg"
                fontSize="20px"
                mode="oval-grey"
                px="24px"
                py="16px"
                width="100%"
                onClick={() => {
                  // odPushClevertapEvent('Proceed On Cart', {
                  //   amount: cartObj?.totals?.total,
                  //   noOfProducts: cartObj?.itemsCount || 0,
                  //   itemNo: cartObj?.itemsCount || 0,
                  //   cartValue: cartObj?.totals?.total,
                  //   isLoggedIn: login,
                  //   // coupon: (couponDetails && couponDetails?.coupon) || 'NA',
                  //   coupon:
                  //     cartObj?.totals?.discounts?.[0]?.type === 'gv'
                  //       ? cartObj?.totals?.discounts?.[0]?.code
                  //       : '' || 'NA',
                  //   membershipPoints: cartObj?.membershipPoints?.membershipPointsRedeemed
                  //     ? cartObj?.membershipPoints?.membershipPointsDiscount?.appliedAmount
                  //     : 0,
                  // });

                  if (handleProceed) {
                    handleProceed();
                  }
                }}
              >
                {proceedButtonText}
              </ButtonOwnDays>
            </div>
          </FixedBottomContainer>
          <div style={{ height: fixedBottomHeight, background: '#FAFAFA' }} />
        </>
      )}
    </>
  ) : null;
};

export default OdCartBillDetails;
