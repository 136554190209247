import React, { useEffect } from 'react';
import './PaymentMethods.scss';

import OdCheckCircleFilledIcon from '../../../../static/assets/svg/od-payment-selected.svg';
import PrimerCard from '../PrimerCard/PrimerCard';

import { useSelector } from 'react-redux';
import Atome from '../../Desktop/PaymentMethods/Atome';
import { validateUserEmail } from '../../../utils/helper';

const PaymentMethods = props => {
  const { headless, isLoading, isRetry, orderId, setSelectedPaymentMethod, selectedPaymentMethod } =
    props;

  const { CHOOSE_PAYMENT_MODE, CREDIT_DEBIT_CARDS } = useSelector(state => state.locale);

  const userInfo = useSelector(state => state.common.userInfo);

  useEffect(() => {
    console.log('this is user info mobile ===>', userInfo);
  }, [userInfo]);

  return (
    <>
      <div className="od-payment-methods__title od-font-bold">{CHOOSE_PAYMENT_MODE}</div>
      <div className="od-payment-methods-active">
        <div className="od-payment-methods-options">
          <div className="od-payment-methods-options__option">
            <div className="od-payment-methods-options__radio">
              {selectedPaymentMethod === 'PRIMER_CARD' ? (
                <img
                  alt="payment check"
                  className="od-payment-methods-options__radio__check-m"
                  src={OdCheckCircleFilledIcon}
                />
              ) : (
                <input
                  className="od-payment-methods-options__radio-input"
                  id="primer"
                  name="paymentMethod"
                  type="radio"
                  onChange={() => setSelectedPaymentMethod('PRIMER_CARD')}
                />
              )}
            </div>

            <img
              alt="card"
              src="https://static.lenskart.com/media/owndays/desktop/img/payment-icons/credit-card.svg"
            />

            <div className="od-payment-methods-options__option-label od-font-bold">
              {CREDIT_DEBIT_CARDS}
            </div>
          </div>
        </div>

        {selectedPaymentMethod === 'PRIMER_CARD' && (
          <PrimerCard
            headless={headless}
            isLoading={isLoading}
            isRetry={isRetry}
            orderId={orderId}
          />
        )}

        {selectedPaymentMethod === 'PRIMER_CARD' && (
          <div className="od-payment-methods-options__others">
            <img
              alt="visa"
              src="https://static.lenskart.com/media/owndays/desktop/img/payment-icons/visa.svg"
            />
            <img
              alt="mastercard"
              src="https://static.lenskart.com/media/owndays/desktop/img/payment-icons/mastercard.svg"
            />
            <img
              alt="amex"
              src="https://static.lenskart.com/media/owndays/desktop/img/payment-icons/amex.png"
            />
          </div>
        )}

        <div className="od-payment-methods-options">
          <div className="od-payment-methods-options__option grab-payment">
            <div className="od-payment-methods-options__radio">
              {selectedPaymentMethod === 'GRABPAY' ? (
                <img
                  alt="payment check"
                  className="od-payment-methods-options__radio__check-m"
                  src={OdCheckCircleFilledIcon}
                />
              ) : (
                <input
                  className="od-payment-methods-options__radio-input"
                  id="grabPay"
                  name="paymentMethod"
                  type="radio"
                  onChange={() => setSelectedPaymentMethod('GRABPAY')}
                />
              )}
            </div>

            <img
              alt="grabpay"
              src="https://static.lenskart.com/media/owndays/desktop/img/payment-icons/grabpay.svg"
            />

            <div className="od-payment-methods-options__option-label od-font-bold">Grabpay</div>
          </div>
        </div>

        {userInfo && validateUserEmail(userInfo?.result?.email) && (
          <div className="od-payment-methods-options">
            <div className="od-payment-methods-options__option">
              <div className="od-payment-methods-options__radio">
                {selectedPaymentMethod === 'ATOME' ? (
                  <img
                    alt="payment check"
                    className="od-payment-methods-options__radio__check-m"
                    src={OdCheckCircleFilledIcon}
                  />
                ) : (
                  <input
                    className="od-payment-methods-options__radio-input"
                    id="atome"
                    name="paymentMethod"
                    type="radio"
                    onChange={() => setSelectedPaymentMethod('ATOME')}
                  />
                )}
              </div>

              <img
                alt="grabpay"
                src="https://static.lenskart.com/media/owndays/desktop/img/atome-pay.svg"
              />

              <div className="od-payment-methods-options__content">
                <span className="od-payment-methods-options__option-label od-font-bold">Atome</span>
                <span className="od-payment-methods-options__option-description od-font-reg">
                  3 monthly payments, 0% interest
                </span>
              </div>
            </div>
          </div>
        )}

        {selectedPaymentMethod === 'ATOME' &&
          userInfo &&
          validateUserEmail(userInfo?.result?.email) && (
            <Atome headless={headless} isLoading={isLoading} isRetry={isRetry} orderId={orderId} />
          )}
      </div>
    </>
  );
};

export default PaymentMethods;
