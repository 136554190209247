/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'react-router-redux';
import { renderRoutes } from 'react-router-config';
import { trigger } from 'redial';
import { createBrowserHistory } from 'history';
import Loadable from 'react-loadable';
import { AppContainer as HotEnabler } from 'react-hot-loader';
import createStore from 'redux/create';
import apiClient from 'helpers/apiClient';
import { routes } from './routes/mobile-routes';
import asyncMatchRoutes from 'utils/asyncMatchRoutes';
import ReduxAsyncConnect from 'ReduxAsyncConnect/ReduxAsyncConnect';
import { Provider } from 'react-redux';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import rootSaga from './sagas';
import { disableLogs } from './own-days/helper';
const dest = document.getElementById('content');
// import { langCodeAllowed } from './locales';

const client = apiClient();
const providers = {
  client,
};

(async () => {
  // const countryCode = process.env.COUNTRY?.toLowerCase();
  // if (process.env.NODE_ENV === 'production' && langCodeAllowed?.[countryCode]?.showSentry) {
  //   Sentry.init({
  //     dsn: "https://e6478bd383bc4a45a22c68aa6ca350a4@o344121.ingest.sentry.io/6391848",
  //     integrations: [new BrowserTracing()],
  //     tracesSampleRate: 1.0,
  //   });
  // }

  const history = createBrowserHistory({
    basename: '/sg/en',
  });
  const store = createStore({
    history,
    data: {
      ...window.__data,
    },
    helpers: providers,
  });

  store.runSaga(rootSaga);

  (function (global) {
    disableLogs(global);
  })(typeof window !== 'undefined' ? window : global);

  const hydrate = async _routes => {
    const { components, match, params } = await asyncMatchRoutes(
      _routes,
      history.location.pathname
    );
    const triggerLocals = {
      ...providers,
      store,
      match,
      params,
      history,
      location: history.location,
    };

    if (window.__data) {
      delete window.__data;
    } else {
      await trigger('fetch', components, triggerLocals);
    }
    await trigger('defer', components, triggerLocals);

    ReactDOM.hydrate(
      <HotEnabler>
        <Provider store={store} {...providers}>
          <ConnectedRouter history={history} store={store}>
            <ReduxAsyncConnect helpers={providers} routes={_routes} store={store}>
              {renderRoutes(_routes)}
            </ReduxAsyncConnect>
          </ConnectedRouter>
        </Provider>
      </HotEnabler>,
      dest
    );
  };

  await Loadable.preloadReady();

  await hydrate(routes);

  // Hot reload
  if (module.hot) {
    module.hot.accept('./routes/mobile-routes', () => {
      const nextRoutes = require('./routes/mobile-routes');
      hydrate(nextRoutes).catch(err => {
        console.error('Error on routes reload:', err);
      });
    });
  }

  // Server-side rendering check
  if (process.env.NODE_ENV !== 'production') {
    window.React = React; // enable debugger

    if (
      !dest ||
      !dest.firstChild ||
      !dest.firstChild.attributes ||
      !dest.firstChild.attributes['data-reactroot']
    ) {
      console.error(
        'Server-side React render was discarded.\n' +
          'Make sure that your initial render does not contain any client-side code.'
      );
    }
  }

  // Dev tools
  if (__DEVTOOLS__ && !window.devToolsExtension) {
    const devToolsDest = document.createElement('div');
    window.document.body?.insertBefore(devToolsDest, null);
    const DevTools = require('./DevTools/DevTools');
    ReactDOM.hydrate(
      <Provider store={store}>
        <DevTools />
      </Provider>,
      devToolsDest
    );
  }
  // Service worker
  if (!__DEVELOPMENT__ && 'serviceWorker' in navigator) {
    try {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.every(reg => {
          if (reg.active && reg.active.scriptURL?.indexOf('/server_mobile.js') === -1) {
            reg.unregister();
          }
          return true;
        });
      });
      // AR Frames issue => Camera permission in ios being asked again and again => https://support.scandit.com/hc/en-us/articles/360008443011-Why-does-iOS-keep-asking-for-camera-permissions-
      const isIOS =
        !!window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform);
      if (isIOS) {
        return;
      }
      navigator.serviceWorker
        .register('/server_mobile.js', {
          insecure: true,
        })
        .then(reg => {
          let updateInterval;
          clearInterval(updateInterval);
          updateInterval = setInterval(() => {
            reg.update();
          }, 1 * 60 * 60 * 1000); /* Check For update in a day */
        })
        .catch(err => {
          console.log('ServiceWorker not registered', err);
        });
    } catch (error) {
      console.log('Error registering service worker: ', error);
    }
    await navigator.serviceWorker.ready;
    console.log('Service Worker Ready');
  }
})();
