import React, { useEffect, useState } from 'react';
import './PaymentSuccess.scss';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { useDispatch, useSelector } from 'react-redux';
import SubmitPrescription from '../../../Mobile/SubmitPrescription/SubmitPrescription';
import { v4 } from 'uuid';
import useGetOrderById from '../../../CommonComponents/SubmitPrescription/useGetOrderById';
import { getQueryParam } from '../../../../utils/helper';
import { getOrders } from '../../../../actionCreators/myAccount';
import FixedBottomContainer from '../../../../CommonComponents/FixedBottomContainer/FixedBottomContainer';
import ViewOrder from './../../../../../static/assets/svg/view-order.svg';
import { showGlobalHeaderFooter } from '../../../../actionCreators/header';
import BottomSheet from '../../../../mobile/components/BottomSheet/BottomSheet';
import { odPushGA4Event } from '../../../../utils/gaFour';

const PaymentSuccess = props => {
  const history = props?.history;
  const grabPayOrderId = getQueryParam(props.location.search.substr(1), 'order_id');
  const grabPayOrderStatus = getQueryParam(props.location.search.substr(1), 'status');
  const grabPayStatusId = getQueryParam(props.location.search.substr(1), 'status_id');

  const { CONTINUE_SHOPPING, AND, FREE } = useSelector(state => state?.locale);

  const [isOpen, setIsOpen] = useState(false);
  const [showOrderProducts, setShowOrderProducts] = useState(false);
  const [uuid, setUuid] = useState(v4());
  const [selectedItem, setSelectedItem] = useState(null);
  const [touchStartY, setTouchStartY] = useState(null);

  const userInfo = useSelector(state => state.common.userInfo);
  const ordersList = useSelector(
    state => (state.myAccount.ordersList && state.myAccount.ordersList.orders) || null
  );

  const handleTouchStart = e => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = e => {
    if (touchStartY !== null) {
      const deltaY = e.touches[0].clientY - touchStartY;
      if (deltaY > 100) {
        setShowOrderProducts(false);
        setTouchStartY(null);
      }
    }
  };

  const handleTouchEnd = () => {
    setTouchStartY(null);
  };

  const orderId = props?.location?.state?.orderId || grabPayOrderId;
  const { orderDetail, fetchingOrderDetails } = useGetOrderById(orderId, uuid, grabPayOrderId);

  const isPaymentSuccess = props?.location?.state?.paymentSuccess;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showGlobalHeaderFooter({ header: true, footer: false, secondaryHeader: false }));
    dispatch(getOrders());
  }, [dispatch]);

  const handleContinueShopping = () => {
    window.location.href = '/';
  };

  const onClosePopup = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isPaymentSuccess && ordersList && ordersList?.length > 0) {
      const gaPLPSelectItemData =
        JSON.parse(sessionStorage.getItem('ga-plp-select-item-data')) || {};

      const gaAddToCartData = JSON.parse(sessionStorage.getItem('ga-add-to-cart-data')) || {};

      odPushGA4Event('purchase', {
        userInfo: userInfo?.result,
        order: ordersList[0],
        gaPLPSelectItemData,
        gaAddToCartData,
      });
    }
  }, [isPaymentSuccess && ordersList]);

  useEffect(() => {
    // OD__CLEVERTAP__EVENT (Payment Made)
    // if (typeof window.clevertap !== 'undefined') {
    //   window.clevertap.event.push('Payment Made', {
    //     paymentMade: true,
    //     orderId,
    //     userInfo: userInfo?.result,
    //   });

    //   // OD__CLEVERTAP__EVENT (Payment Success)
    //   window.clevertap.event.push('Payment Success', {
    //     paymentMade: true,
    //     orderId,
    //     userInfo: userInfo?.result,
    //   });
    // }

    if (grabPayOrderStatus === 'AUTHENTICATION_FAILED') {
      history.push('/customer/account');
    }
  }, []);

  useEffect(() => {
    const element = document.querySelector('.app-content');

    if (element) {
      if (location?.pathname?.indexOf('/payment-success') > -1) {
        element.style.minHeight = 'auto';
        element.style.height = 'auto';
      }
    }
  }, []);

  // return if need "View order"
  //   const onViewDetailClickHandler = () => {
  //     if (getSetCookie.getCookie('isLogined') === '1') {
  //       history.push('#');
  //     } else {
  //       history.push({
  //         pathname: '/',
  //         search: '?type=login',
  //         state: { redirectUrl: '#' },
  //       });
  //     }
  //   };

  const handleSelectedItem = item => {
    setSelectedItem(item);
    setIsOpen(true);
  };

  const removeModalNameFromGiftCard = item => {
    if (item?.productType === 'Gift Cards') {
      return 'OWNDAYS Gift Cards';
    }

    return item?.name;
  };

  const showPrescriptionStatus = item => {
    if (item?.powerRequired === 'POWER_REQUIRED') {
      return (
        <ButtonOwnDays
          fontClass="od-font-bold"
          fontSize="16px"
          mode="oval-grey"
          px="24px"
          py="12px"
          text="Upload prescription"
          width="100%"
          onClick={() => handleSelectedItem(item)}
        />
      );
    }
    return null;

    // else if (item?.powerRequired === 'POWER_SUBMITTED') {
    //   return <div>file uploaded</div>;
    // }
    // return <div>No Prescription required</div>;
  };

  const getAddonName = options => {
    let result = options[0]?.label || '';

    if (options[1]?.label) {
      result = `${result} ${options[1].label}`;
    }

    if (options[2]?.label) {
      result = `${result} ${AND} ${options[2].label}`;
    }

    return result;
  };

  if (fetchingOrderDetails || !userInfo) {
    return null;
  }

  return (
    <>
      {isOpen && (
        <SubmitPrescription
          isOpen={isOpen}
          orderId={orderId}
          orderItem={selectedItem}
          setUuid={setUuid}
          onClosePopup={onClosePopup}
        />
      )}
      <div className="od-payment-success-m">
        <div className="od-payment-success-m-container">
          <div className="od-payment-success-m__banner">
            <img
              alt="payment-success"
              className="od-payment-success-m-img"
              src="https://static1.lenskart.com/owndays/img/payment-success/order-confirm-banner-sp.webp"
            />
            <div className="od-payment-success-m__banner__overlay">
              <div className="od-payment-success-m__banner__info">
                <div className="od-payment-success-m__banner__info__title od-font-bold">
                  Order placed{' '}
                  <span aria-label="success" role="img">
                    🎉
                  </span>{' '}
                  Just one more step
                </div>
              </div>
              <div className="od-payment-success-m__banner__info__description">
                You will receive an order confirmation email <br />
                shortly with the expected delivery date
              </div>
              <div
                className="od-payment-success-m__view-order"
                onClick={() => {
                  window.location.href = '/sg/en/customer/account';
                }}
              >
                <img alt="View order" src={ViewOrder} />
                <span>View order</span>
              </div>
            </div>
          </div>

          {/* <div className="od-payment-success-m__view-order" onClick={onViewDetailClickHandler}> */}

          {/* {orderDetail?.items?.length > 0 &&
            orderDetail?.items.map(item => (
              <div key={v4()} className="upload-prescription-card">
                <div className="upload-prescription-card__thumbnail">
                  <img alt={item?.sku} src={item?.image} />
                </div>
                <div className="upload-prescription-card__details">
                  <div className="upload-prescription-card__details__title">{item?.brandName}</div>
                  <div className="upload-prescription-card__details__name">{item?.name}</div>
                  {showPrescriptionStatus(item)}
                </div>
              </div>
            ))} */}

          <div className="od-payment-success-m-container-what-next">
            <h3 className="od-payment-success-m-container-what-next-title od-font-bold">
              {isPaymentSuccess ? (
                <span>What’s the next step?</span>
              ) : (
                <span>⚠ Action needed: Complete payment</span>
              )}
            </h3>
            <p className="od-payment-success-m-container-what-next-para od-font-reg">
              {isPaymentSuccess ? (
                'Your order contains item(s) that require prescription. You can either share it now, or within 7 days.'
              ) : (
                <span>
                  Oops! it looks like your payment didn’t go through. but don’t worry your order is
                  placed successfully and you can{' '}
                  <strong>complete your payment within 48 hours</strong>
                </span>
              )}
            </p>
          </div>

          <FixedBottomContainer>
            <div className="od-payment-success-m-container__shopping">
              <ButtonOwnDays
                fontClass="od-payment-success-m-container__shopping-btn od-font-bold"
                fontSize="16px"
                mode="oval-white"
                px="24px"
                py="16px"
                text={CONTINUE_SHOPPING}
                width="100%"
                onClick={handleContinueShopping}
              />
              {!isPaymentSuccess && ordersList && ordersList.length > 0 && (
                <ButtonOwnDays
                  fontClass="od-font-bold"
                  fontSize="16px"
                  mode="oval-grey"
                  px="24px"
                  py="16px"
                  text="Complete payment"
                  width="100%"
                  onClick={() => {
                    history.push(
                      `/retry-payment?oid=${window.btoa(ordersList[0].id)}&eid=${window.btoa(
                        userInfo?.result?.email
                      )}`
                    );
                  }}
                />
              )}
              {isPaymentSuccess && ordersList && ordersList.length > 0 && (
                <>
                  {ordersList &&
                    ordersList.length > 0 &&
                    ordersList[0]?.items &&
                    ordersList[0]?.items.length > 0 &&
                    ordersList[0]?.items.some(item => item?.powerRequired === 'POWER_REQUIRED') && (
                      <ButtonOwnDays
                        fontClass="od-font-bold"
                        fontSize="16px"
                        mode="oval-grey"
                        px="24px"
                        py="16px"
                        text="Upload prescription"
                        width="100%"
                        onClick={() => setShowOrderProducts(true)}
                      />
                    )}
                </>
              )}
            </div>
          </FixedBottomContainer>
        </div>
        {showOrderProducts && (
          <BottomSheet
            backdrop
            borderRadius
            expanded
            onClickBackdrop={() => {
              setShowOrderProducts(false);
            }}
          >
            <div
              className="order-products-wrapper"
              onTouchEnd={handleTouchEnd}
              onTouchMove={handleTouchMove}
              onTouchStart={handleTouchStart}
            >
              <div className="swipe-indicator" />
              <div className="order-products-title od-font-bold">Upload prescriptions</div>
              <div className="order-products-description od-font-reg">
                Please upload prescription for following item(s)
              </div>
              <div className="order-products">
                {ordersList &&
                  ordersList.length > 0 &&
                  ordersList[0]?.items.length > 0 &&
                  ordersList[0]?.items
                    ?.filter(item => item?.powerRequired === 'POWER_REQUIRED')
                    ?.map(item => {
                      let addOnMarketPrice = 0;
                      let addOnLenskartPrice = 0;
                      if (item?.options?.length > 0) {
                        item.options.forEach(option => {
                          addOnMarketPrice += option?.catalogPrices[0]?.value;
                          addOnLenskartPrice += option?.catalogPrices[1]?.value;
                        });
                      }
                      return (
                        <div key={v4()} className="order-product-wrapper">
                          <div className="order-product">
                            <div className="order-product__img">
                              <img alt={item?.sku} src={item?.image} />
                            </div>
                            <div className="order-product__info">
                              <div className="order-product__info-row">
                                <div className="order-product__info__title od-font-reg">
                                  {item && (
                                    <>
                                      {item?.classification !== 'loyalty_services'
                                        ? `${removeModalNameFromGiftCard(item)}`
                                        : item?.brandName && item?.frameColour
                                        ? `${item?.brandName} ${item?.frameColour}`
                                        : item?.label}
                                    </>
                                  )}
                                </div>
                                <div className="order-product__info__price od-font-reg">
                                  {' '}
                                  {`${item?.price?.currencyCode} ${item.catalogPrices[1].value}`}
                                </div>
                              </div>
                              {item?.options?.length > 0 && (
                                <div className="order-product__info-row">
                                  <div className="order-product__info__addon od-font-reg">
                                    {getAddonName(item.options)}
                                  </div>

                                  <div className="od-item-product-card__prices">
                                    {item?.isFree && item.quantity === 1 ? (
                                      <>
                                        <s className="order-product__info__price od-font-reg">
                                          {item?.price?.currencyCode}
                                          {addOnLenskartPrice}
                                        </s>
                                        <span className="order-product__info__price od-font-reg">
                                          {FREE}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {addOnLenskartPrice < addOnMarketPrice && (
                                          <s className="order-product__info__price od-font-reg">
                                            {`${item?.price?.currencyCode} ${addOnMarketPrice}`}
                                          </s>
                                        )}
                                        <span className="order-product__info__price od-font-reg">
                                          {`${item?.price?.currencyCode} ${addOnLenskartPrice}`}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {showPrescriptionStatus(item)}
                        </div>
                      );
                    })}
              </div>
            </div>
          </BottomSheet>
        )}
      </div>
    </>
  );
};

export default PaymentSuccess;
