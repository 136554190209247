import React from 'react';
import Loadable from 'react-loadable';
import Spinner from './Spinner';

const SpinnerLoadable = Loadable({
  loader: () => import(/* webpackChunkName: 'auth' */ './Spinner').then(module => module.default),
  loading: () => <Spinner />,
});

export default SpinnerLoadable;
