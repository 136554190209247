import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../../../CommonComponents/Spinner/Spinner';

const HomeLoadable = Loadable({
  loader: () => import(/* webpackChunkName: 'home' */ './Home').then(module => module.default),
  loading: () => <Spinner />,
});

export default HomeLoadable;
