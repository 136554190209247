import React, { useEffect } from 'react';
import * as productAction from '../../../../actionCreators/product';
import Breadcrumb from '../../../../mobile/components/Breadcrumb/Breadcrumb';
import ProductImage from '../../../ProductCard/ProductImage/ProductImage';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import './UploadPrescription.scss';
import { useDispatch, useSelector, connect } from 'react-redux';
import { addToCart } from '../../../../actionCreators/cart';
import { odPushGA4Event } from '../../../../utils/gaFour';

const UploadPrescription = props => {
  const productId = props?.match?.params?.id;
  const history = props?.history;
  const dispatch = useDispatch();
  const product = useSelector(state => state?.product?.productData?.result);
  const dataLocale = useSelector(state => state?.locale);
  const { userInfo, platform } = props;
  const {
    WHAT_ABOUT_MY_EYE_POWER,
    YOU_CAN_SUBMIT_POWER_AFTER_PAYMENT_STEP,
    PHONE_ASSISTANCE,
    POWER_SUBMITTED_TIME,
    NO_ADDITIONAL_CHARGES,
    I_HAVE_READ_AND_UNDERSTOOD_THE_TERMS_OF_POWER_SUBMISSION,
    SELECT_LENSES_AND_PURCHASE,
    CONTACT_US_PHONE,
  } = dataLocale;
  const localInfo = useSelector(state => state?.common?.localeInfo);

  useEffect(() => {
    if (productId) {
      dispatch(productAction.getProductData(productId));
    }

    odPushGA4Event('page_view', {
      screenName: 'submit-power-later-page',
      userInfo,
      platform,
    });

    window.clevertapScreenName = 'prescription-upload-type-page';

    return () => {
      window.clevertapScreenName = null;
    };
  }, []);

  const handleSelectLenses = () => {
    if (typeof window.clevertap !== 'undefined') {
      window.clevertap.event.push('cta_click ', {
        cta_name: 'select-lenses-and-purchase',
        cta_flow_and_page: window.clevertapScreenName,
      });
    }

    dispatch(addToCart({ productId, powerType: 'Frame Only' }));
    history.push('/cart');
  };

  if (product) {
    return (
      <>
        <div className="breadcrumb">
          <Breadcrumb
            breadcrumb={product.breadcrumb}
            countryCode={localInfo?.countryCode}
            dataLocale={dataLocale}
          />
        </div>
        <div className="od-upload-prescription-m">
          <div className="od-upload-prescription-m__productImage">
            <ProductImage alt={product?.description} src={product?.thumbnailImage} />
          </div>
        </div>
        <hr className="od-upload-prescription-m__separator" />

        <div className="od-upload-prescription-m">
          <h4 className="od-upload-prescription-m__title">{WHAT_ABOUT_MY_EYE_POWER}</h4>
          <p className="od-upload-prescription-m__para">
            {YOU_CAN_SUBMIT_POWER_AFTER_PAYMENT_STEP}
          </p>
          <div className="od-upload-prescription-m__upload">
            {/* <a className="od-upload-prescription-m__upload-item" href="tel:6222-0588"> */}
            <a className="od-upload-prescription-m__upload-item" href={`tel:${CONTACT_US_PHONE}`}>
              <img
                alt={PHONE_ASSISTANCE}
                src="https://static.lenskart.com/media/desktop/img/pdp/phone.png"
              />
              <div>{PHONE_ASSISTANCE}</div>
            </a>
            {/* <div className='od-upload-prescription-m__upload-item'>
                            <img src="https://static.lenskart.com/media/desktop/img/pdp/upload_prescription.png" alt="Phone Assistance" />
                            <div>Upload Prescription</div>
                        </div> */}
          </div>
          <div className="od-upload-prescription-m__divider" />
          <p className="od-upload-prescription-m__para">{POWER_SUBMITTED_TIME}</p>
          <div className="od-upload-prescription-m__divider" />
          <p className="od-upload-prescription-m__para">{NO_ADDITIONAL_CHARGES}</p>
          <div className="od-upload-prescription-m__divider" />

          <div className="od-upload-prescription-m__footer">
            <p className="od-upload-prescription-m__para-big">
              {I_HAVE_READ_AND_UNDERSTOOD_THE_TERMS_OF_POWER_SUBMISSION}
            </p>
            <ButtonOwnDays
              mode="grey"
              style={{ marginBotton: 30, width: '100%' }}
              text={SELECT_LENSES_AND_PURCHASE}
              onClick={handleSelectLenses}
            />
          </div>
        </div>
      </>
    );
  }

  return null;
};

const mapStateToProps = state => ({
  userInfo: state.common.userInfo,
  platform: state.DTM.platform,
});

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPrescription);
