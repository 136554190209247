import React, { useEffect, useState } from 'react';
import SideBarPopup from '../../../desktop/components/SideBarPopup/SideBarPopup';
import './SubmitPrescription.scss';
import ChevronRight from '../../../../static/assets/img/chevron-right.png';
import ChevronDown from '../../../../static/assets/img/chevron-down.png';
import ChevronUp from '../../../../static/assets/img/chevron-up.png';
import apiClient from '../../../helpers/apiClient';
import { apiPath } from '../../../config';
import UnCheckedIcon from '../../../../static/assets/svg/od-unchecked.svg';
import ButtonOwnDays from '../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { setToastMessage } from '../../../actionCreators/common';
import { useDispatch, useSelector } from 'react-redux';
import ChevronRightWhite from '../../../../static/assets/svg/od-chevron-right-white.svg';
import PrescriptionsLoader from '../../../../static/assets/svg/od-prescriptions-loader.svg';
import BackButton from '../../../../static/assets/svg/od-chevron-left-gray.svg';
import ProductImage from '../../ProductCard/ProductImage/ProductImage';
import { capitalizeLetters } from '../../helper';
import OdCircleIcon from '../../../../static/assets/svg/od-circle-icon.svg';
import OdCheckCircleFilledIcon from '../../../../static/assets/svg/od-check-circle-filled-icon.svg';
import { v4 } from 'uuid';
import { odPushClevertapEvent } from '../../../utils/clevertap';

function formatUnixTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}

const screensKey = {
  submitPrescription: 'submit-prescription',
  savedPrescription: 'saved-prescription-m',
  uploadPrescription: 'upload-prescription-m',
};

const screens = {
  submitPrescription: {
    key: screensKey.submitPrescription,
    title: 'Submit prescription',
  },
  savedPrescription: {
    key: screensKey.savedPrescription,
    title: 'Saved power',
  },
  uploadPrescription: {
    key: screensKey.uploadPrescription,
    title: 'Upload your prescription details',
  },
};

const SubmitPrescription = ({
  isOpen,
  onClosePopup,
  orderItem,
  orderId,
  addNewPrescription = false,
  hideSavedPowerOption = false,
  setUuid,
}) => {
  const [openIKnowPower, setOpenIKnowPower] = useState(true);
  const [currentScreen, setCurrentScreen] = useState(screens.submitPrescription);
  const [savedPowers, setSavedPowers] = useState([]);
  const [selectedSavedPowers, setSelectedSavedPowers] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [uploadfileError, setUploadfileError] = useState(null);
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.common.userInfo);
  const userEmail = userInfo?.result?.email;
  const userName = userInfo?.result?.firstName;
  const userPhone = userInfo?.result?.telephone;
  const userPhoneCode = userInfo?.result?.phoneCode;
  const dataLocale = useSelector(state => state.locale);
  const { CONTINUE, SUBMIT } = dataLocale;

  useEffect(() => {
    console.log('this is common state ===>', userEmail, userPhone, userName, userInfo);
  }, [userPhone, userName, userEmail]);

  useEffect(() => {
    if (currentScreen.key === screensKey.savedPrescription) {
      const client = apiClient();
      client.setHeader();
      client
        .get(`${apiPath.getSavedPrescription}`)
        .then(res => {
          if (Number(res?.status) === 200) {
            console.log('this is saved prescriptions....', res.result);
            if (res?.result?.length > 0) {
              setSavedPowers(res?.result);
            }
          }
        })
        .catch(() => {
          setSavedPowers([]);
        });
    } else {
      setSavedPowers([]);
      setSelectedSavedPowers(null);
    }
  }, [currentScreen]);

  const uploadPrescriptionForItem = uploadFileRes => {
    if (addNewPrescription) {
      const imageFileName = uploadFileRes?.result?.filename || uploadFileRes?.imageFileName;
      if (imageFileName) {
        const client = apiClient();
        client.setHeader();
        client
          .post(`${apiPath.savePrescription}`, {
            powerType: 'SINGLE_VISION',
            showPd: true,
            userName,
            imageFileName,
            source: 'WEB',
            power_updated_by: userEmail,
            customerEmail: userEmail,
            customerPhone: userPhone,
            phoneCode: userPhoneCode,
          })
          .then(res => {
            if (Number(res?.status) === 200) {
              odPushClevertapEvent('Power Submitted', {
                orderId,
              });

              setFileUploading(false);
              onClosePopup();
              if (setUuid) {
                setUuid();
              }
              console.log('this is after uploading file for order ===>>>> new', res.result);
            }
          })
          .catch(() => {
            setFileUploading(false);
          });
      }
    } else {
      const imageFileName = uploadFileRes?.result?.filename || uploadFileRes?.imageFileName;
      if (imageFileName) {
        const client = apiClient();
        client.setHeader();
        client
          .put(
            `${apiPath.getOrders}/${orderId}/items/${orderItem?.id}/prescription?email=${userEmail}`,
            {
              powerType: 'SINGLE_VISION',
              showPd: true,
              userName,
              imageFileName,
              source: 'WEB',
              power_updated_by: userEmail,
            }
          )
          .then(res => {
            if (Number(res?.status) === 200) {
              setFileUploading(false);
              onClosePopup();
              if (setUuid) {
                setUuid();
              }
              console.log('this is after uploading file for order ===>>>>', res.result);
            }
          })
          .catch(() => {
            setFileUploading(false);
          });
      }
    }
  };

  const handleUseThisPower = () => {
    if (selectedSavedPowers) {
      //  do something
      console.log('this is selected saved powers ===>', selectedSavedPowers);
      uploadPrescriptionForItem(selectedSavedPowers);
    }
  };

  useEffect(() => {
    console.log('this is selected fileeeee ===>', selectedFile);
  }, [selectedFile]);

  const handlePrescriptionUpload = e => {
    if (e.target.files) {
      const prescriptionUpload = e.target.files[0];
      const allowedFormats = ['image/jpeg', 'image/png', 'application/pdf'];
      if (!allowedFormats.includes(prescriptionUpload.type)) {
        setUploadfileError('Invalid file format. Please upload a JPEG, PNG, or PDF file.');
        setSelectedFile(null);
        return;
      }

      const maxSizeInBytes = 5 * 1024 * 1024;
      if (prescriptionUpload.size > maxSizeInBytes) {
        setUploadfileError('File size exceeds the maximum limit of 5 MB.');
        setSelectedFile(null);
        return;
      }

      setUploadfileError(null);

      setSelectedFile(e.target.files[0]);
    }
  };

  const submitUploadFile = () => {
    if (selectedFile) {
      setFileUploading(true);
      const client = apiClient();
      client.setHeader();
      const formData = new FormData();
      formData.append('myfile', selectedFile);
      client
        .post(`${apiPath.uploadprescfile}`, formData)
        .then(res => {
          if (Number(res?.result?.code) === 200) {
            // save file in state...
            dispatch(
              setToastMessage({
                message: res?.result?.message,
                timeout: 3000,
              })
            );

            // upload prescription for item
            uploadPrescriptionForItem(res);
          }
        })
        .catch(err => {
          setFileUploading(false);
          dispatch(
            setToastMessage({
              warning: true,
              message: err?.message || 'Something went wrong',
              timeout: 3000,
            })
          );
        });
    }
  };

  const renderSubmitPrescription = () => {
    return (
      <div className="upload-prescription-m-sidebar__collapaseMenu">
        <div className="upload-prescription-m-sidebar__collapaseMenu-title">
          <div>{/* <img alt="know my power" src={KnowMyPower} /> */}I know my power</div>
          <img
            alt="chevron"
            src={openIKnowPower ? ChevronUp : ChevronDown}
            width="18px"
            onClick={() => setOpenIKnowPower(!openIKnowPower)}
          />
        </div>
        <div className="upload-prescription-m-sidebar__collapaseMenu-body">
          {openIKnowPower && (
            <>
              {!hideSavedPowerOption && (
                <div
                  className="upload-prescription-m-sidebar__collapaseMenu-body-item"
                  onClick={() => {
                    setCurrentScreen(screens.savedPrescription);
                    setSelectedFile(null);
                    setFileUploading(false);
                  }}
                >
                  <div>
                    <img alt="chevron" src={UnCheckedIcon} width="24px" /> Use saved power
                  </div>
                </div>
              )}
              <div
                className="upload-prescription-m-sidebar__collapaseMenu-body-item"
                onClick={() => {
                  setCurrentScreen(screens.uploadPrescription);
                  setSelectedFile(null);
                  setFileUploading(false);
                }}
              >
                <div>
                  <img alt="chevron" src={UnCheckedIcon} width="24px" /> Upload a prescription
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderSavedPower = () => {
    return (
      <div className="saved-prescription-m-sidebar-panel">
        {savedPowers?.length > 0 &&
          savedPowers.map(power => {
            const formattedCreatedAt = formatUnixTimestamp(power?.createdAt);
            return (
              <div
                key={v4()}
                className="saved-prescription-m-radio"
                onClick={() => setSelectedSavedPowers(power)}
              >
                <div className="saved-prescription-m-radio__left">
                  <div className="saved-prescription-m-radio__left_name">
                    {power?.imageFileName || power?.userName}
                  </div>
                  <div className="saved-prescription-m-radio__left_date">
                    Created on {formattedCreatedAt}
                  </div>
                </div>
                <div className="saved-prescription-m-radio__right">
                  <img
                    alt="radio button"
                    className="saved-prescription-m-radio__right_check_uncheck"
                    src={
                      selectedSavedPowers?.id === power?.id ? OdCheckCircleFilledIcon : OdCircleIcon
                    }
                  />
                </div>
              </div>
            );
          })}
        <div className="saved-prescription-m-sidebar-panel__footer">
          {/* <ButtonOwnDays
            noHover
            classes="hto-hero__button"
            disabled={fileUploading}
            fontClass="od-font-bold"
            fontSize="16px"
            px="24px"
            py="16px"
            style={{ fontWeight: 400, border: 'none', backgroundColor: '#fafafa' }}
            onClick={handleAddNewPower}
          >
            Add new power
          </ButtonOwnDays> */}
          <ButtonOwnDays
            noHover
            disabled={!selectedSavedPowers || fileUploading}
            fontClass="od-font-bold"
            fontSize="20px"
            mode="grey"
            py="12px"
            style={{ marginBottom: '15px' }}
            text={capitalizeLetters(SUBMIT.toLowerCase())}
            width="100%"
            onClick={handleUseThisPower}
          />
        </div>
      </div>
    );
  };

  const renderUploadPrescription = () => {
    return (
      <div className="upload-prescription-m-sidebar-panel">
        {!fileUploading ? (
          <>
            <div className="upload-prescription-m-sidebar-panel__heading-footer-m">
              Please ensure the following information are available in the prescription
            </div>
            <ul className="upload-prescription-m-sidebar-panel__list">
              <li className="upload-prescription-m-sidebar-panel__list_item">Full name</li>
              <li className="upload-prescription-m-sidebar-panel__list_item">Optical store name</li>
              <li className="upload-prescription-m-sidebar-panel__list_item">
                Optician's name and the updated prescription / expiry date
              </li>
            </ul>
            <div className="upload-prescription-m-sidebar-panel__heading-footer-m">
              File should be in PDF, JPEG or PNG formats and should not exceed 5MB in size.
            </div>
            {selectedFile && (
              <div className="upload-prescription-m-sidebar-panel__img">
                <img alt="Selected File" src={URL.createObjectURL(selectedFile)} />
              </div>
            )}
            {uploadfileError && (
              <div className="od-custom-error-msg od-font-reg">{uploadfileError}</div>
            )}
            <div className="upload-prescription-m-sidebar-panel__uploadBtn">
              {selectedFile ? (
                <ButtonOwnDays
                  noHover
                  classes="upload-prescription-btn-change-file"
                  fontClass="od-font-bold"
                  fontSize="16px"
                  px="24px"
                  py="16px"
                >
                  <input type="file" onChange={handlePrescriptionUpload} />
                  Change file
                  <img
                    alt="upload file"
                    src={ChevronRight}
                    style={{ marginBottom: 2, marginLeft: 10 }}
                    width={18}
                  />
                </ButtonOwnDays>
              ) : (
                <ButtonOwnDays
                  noHover
                  classes="upload-prescription-btn-upload"
                  fontClass="od-font-bold"
                  fontSize="16px"
                  mode="grey"
                  px="24px"
                  py="16px"
                  width="181px"
                >
                  <input type="file" onChange={handlePrescriptionUpload} />
                  Upload
                  <img alt="upload file" src={ChevronRightWhite} width={20} />
                </ButtonOwnDays>
              )}
            </div>
            <div className="upload-prescription-m-sidebar-panel__footer">
              <ButtonOwnDays
                noHover
                disabled={!selectedFile || fileUploading}
                fontClass="od-font-bold"
                fontSize="20px"
                mode="grey"
                py="12px"
                style={{ marginBottom: '15px' }}
                width="100%"
                onClick={submitUploadFile}
              >
                {currentScreen.key !== screensKey.submitPrescription
                  ? capitalizeLetters(SUBMIT.toLowerCase())
                  : CONTINUE}
              </ButtonOwnDays>
            </div>
          </>
        ) : (
          <div className="od-prescriptions-loader">
            <div className="od-prescriptions-loader__title">Loader</div>
            <img alt="Prescriptions Loader" src={PrescriptionsLoader} />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (isOpen) {
      const headerHeight = document.querySelector('.od-header__panel')?.offsetHeight;
      const contentElement = document.querySelector('.upload-prescription-m-sidebar');

      if (contentElement && headerHeight) {
        contentElement.style.marginTop = `${headerHeight}px`;
      }
    }
  }, [isOpen]);

  return (
    <SideBarPopup
      crossIconStyle="upload-prescription-m-sidebar__cross"
      dialogClassName="upload-prescription-m-sidebar"
      open={isOpen}
      width="100%"
      onClose={onClosePopup}
    >
      <div className="upload-prescription-m-sidebar__back-to">
        <img
          alt="back button"
          src={BackButton}
          onClick={() => {
            if (currentScreen.key !== screensKey.submitPrescription) {
              setCurrentScreen(screens.submitPrescription);
              setSelectedFile(null);
              setFileUploading(false);
            } else {
              onClosePopup();
            }
          }}
        />
        {orderId && currentScreen.key !== screensKey.submitPrescription && (
          <div className="upload-prescription-m-sidebar__order-id">Order No : {orderId}</div>
        )}
      </div>
      {currentScreen.key === screensKey.submitPrescription && (
        <>
          {orderItem && (
            <div className="upload-prescription-m-sidebar__heading">
              <ProductImage alt={orderItem?.description} src={orderItem?.thumbnailImage} />
              <div className="upload-prescription-m-sidebar__heading__order-id">
                Order No : {orderId}
              </div>
              <div className="upload-prescription-m-sidebar__heading__title">
                {orderItem?.brandName}
              </div>
              <div className="upload-prescription-m-sidebar__heading__description">
                {orderItem?.name}
              </div>
            </div>
          )}
          <div className="upload-prescription-m-sidebar__divider">
            <svg
              fill="none"
              height="4"
              viewBox="0 0 180 4"
              width="180"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path clipRule="evenodd" d="M180 4H0V0H180V4Z" fill="#546073" fillRule="evenodd" />
            </svg>
          </div>
        </>
      )}
      <div className="upload-prescription-m-sidebar__title">{currentScreen.title}</div>
      <div className="upload-prescription-m-sidebar__content">
        {currentScreen.key === screensKey.submitPrescription && renderSubmitPrescription()}
        {currentScreen.key === screensKey.savedPrescription && renderSavedPower()}
        {currentScreen.key === screensKey.uploadPrescription && renderUploadPrescription()}
        {/* <div className="upload-prescription-m-sidebar__collapaseMenu">
          <div className="upload-prescription-m-sidebar__collapaseMenu-title">
            <div>
              {' '}
              I don't know my power_updated_by
              <img alt="dont know my power" src={SelectPowerIcon} />
            </div>
            <img
              alt="chevron"
              src={dontKnowPower ? ChevronUp : ChevronDown}
              onClick={() => setDontKnowPower(!dontKnowPower)}
            />
          </div>
          <div className="upload-prescription-m-sidebar__collapaseMenu-body">
            {dontKnowPower && (
              <>
                <div className="upload-prescription-m-sidebar__collapaseMenu-body-item">
                  <div>Use saved power</div>
                  <img alt="chevron" src={ChevronRight} />
                </div>
                <div className="upload-prescription-m-sidebar__collapaseMenu-body-item">
                  <div>Upload a prescription</div>
                  <img alt="chevron" src={ChevronRight} />
                </div>
              </>
            )}
          </div>
        </div> */}
      </div>
    </SideBarPopup>
  );
};

export default SubmitPrescription;
