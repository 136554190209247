import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../Spinner/Spinner';

const RedisMappingLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'redis-mapping' */ './RedisMapping').then(module => module.default),
  loading: () => <Spinner />,
});

export default RedisMappingLoadable;
