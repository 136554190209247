import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../../../CommonComponents/Spinner/Spinner';

const ShippingLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'shipping' */ './Shipping').then(module => module.default),
  loading: () => <Spinner />,
});

export default ShippingLoadable;
