import React from 'react';
import Shimmer from '../../../CommonComponents/Shimmer/Shimmer';

export const PaymentPageSubmitShimmer = () => <Shimmer height={56} style={{ marginTop: 24 }} />;

export const PaymentPageShimmer = () => (
  <>
    <Shimmer line height={23} style={{ marginBottom: 10, maxWidth: 250 }} />
    <Shimmer line height={48} style={{ marginBottom: 40, maxWidth: 400 }} />
    <Shimmer line height={20} style={{ marginBottom: 16, maxWidth: 125 }} />
    <Shimmer height={380} />
    <PaymentPageSubmitShimmer />
  </>
);
