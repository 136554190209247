import { langCodeAllowed } from '../locales';
const isEmpty = value => value === undefined || value === null || value === '';

export function email(value) {
  let error = null;
  if (
    (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) ||
    isEmpty(value)
  ) {
    error = 'Please enter a valid Email';
  }
  return error;
}

export function required(value) {
  const len = value ? value.trim().length : 0;
  if (isEmpty(value) || len < 1) {
    return 'This is required';
  }
}

export const clPowerRequired = isRequired => value => {
  const len = value ? value.trim().length : 0;
  if (
    isRequired &&
    (isEmpty(value) ||
      len < 1 ||
      ['Call Me/Email Me for Color', 'Call Me/Email Me for Power'].includes(value))
  ) {
    return 'This is required';
  }
};

export function isIfsc(value) {
  if (!/^[A-Za-z\d]{11}$/i.test(value)) {
    return 'Please enter valid ifsc code.';
  }
}

export function minLength(min) {
  return value => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max, name) {
  return value => {
    if (!isEmpty(value) && value.length > max) {
      return name
        ? `${name} can not be more than ${max} characters`
        : `Must be no more than ${max} characters`;
    }
  };
}

export function integer(value) {
  if ((!isEmpty(value) && !Number.isInteger(Number(value))) || isEmpty(value)) {
    return 'Must be an integer';
  }
}

export function oneOf(enumeration) {
  return value => {
    if (!enumeration.includes(value)) {
      return `Must be one of: ${enumeration.join(', ')}`;
    }
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match';
      }
    }
  };
}

export function isMobileEmail(value, initialValues) {
  const countryMark = initialValues.countryCode;
  const countrySymbol =
    countryMark && langCodeAllowed.hasOwnProperty(countryMark.toLowerCase())
      ? countryMark.toLowerCase()
      : 'in';
  const { maxMobileNumberLength, minMobileNumberLength, regex } = langCodeAllowed[countrySymbol];
  if (
    regex.test(value) &&
    value &&
    value.length <= maxMobileNumberLength &&
    value.length >= minMobileNumberLength
  ) {
    return;
  }
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    return;
  }
  return 'Please enter a valid Email or Mobile Number';
}

export function isMobile(value, initialValues) {
  const lkCountry = initialValues.lkCountry || 'SG';
  const countrySymbol = lkCountry && lkCountry.toLowerCase();
  let maxMobileNumberLength;
  let minMobileNumberLength;
  let regex;
  if (langCodeAllowed?.[countrySymbol]) {
    maxMobileNumberLength = langCodeAllowed[countrySymbol].maxMobileNumberLength;
    minMobileNumberLength = langCodeAllowed[countrySymbol].minMobileNumberLength;
    regex = langCodeAllowed[countrySymbol].regex;
  }

  let error = null;
  if (
    !regex?.test(value) ||
    value.length > maxMobileNumberLength ||
    value.length < minMobileNumberLength
  ) {
    error = 'Please enter a valid Mobile Number';
  }
  return error;
}

// export function isMobile(value, initialValues) {
//   const countryMark = initialValues.phoneCodeCountry || initialValues.countryCode;
//   const countrySymbol = countryMark && countryMark.toLowerCase();
//   let maxMobileNumberLength;
//   let minMobileNumberLength;
//   let regex;
//   if (langCodeAllowed?.[countrySymbol]) {
//     maxMobileNumberLength = langCodeAllowed[countrySymbol].maxMobileNumberLength;
//     minMobileNumberLength = langCodeAllowed[countrySymbol].minMobileNumberLength;
//     regex = langCodeAllowed[countrySymbol].regex;
//   } else if (countryMark) {
//     maxMobileNumberLength = 12;
//     minMobileNumberLength = 7;
//     regex = langCodeAllowed.ae.regex;
//   } else {
//     maxMobileNumberLength = 10;
//     minMobileNumberLength = 10;
//     regex = langCodeAllowed.in.regex;
//   }

//   let error = null;
//   if (
//     !regex.test(value) ||
//     value.length > maxMobileNumberLength ||
//     value.length < minMobileNumberLength
//   ) {
//     error = 'Please enter a valid Mobile Number';
//   }
//   return error;
// }

export function isName(val) {
  let error = null;
  if (!val.trim()) {
    error = 'Only spaces are not allowed';
  } else if ((val && val.length < 1) || !/^[(a-zA-Z )]+$/.test(val)) {
    error = 'Only alphabets and space are allowed';
  }
  return error;
}

export function validateAlphabets(val) {
  let error;
  const len = val ? val.trim().length : 0;
  if (len < 1) {
    error = 'This is a required field.';
  } else if (/\d/.test(val)) {
    error = 'Only Alphabets are allowed.';
  }
  return error;
}

/* Credit/Debit card validation */
export function validateCardNumber(cardNo) {
  const regx = /^\d{13,18}$/; // This is the standard one /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/;
  return regx.test(cardNo) ? '' : 'Please enter valid card no';
}

export function validateExpiryDate(date) {
  const isValidDate = /^\d{2}\/\d{2}$/;
  if (!date || !isValidDate.test(date)) return 'Please enter valid expiry date';
  const month = date.toString().slice(0, 2);
  let fullyear = '20';
  const year = date.toString().slice(3);
  if (year.length === 2) {
    fullyear += year;
  }
  const today = new Date();
  const expiryDay = new Date();
  expiryDay.setFullYear(fullyear, month, 0);
  return expiryDay < today || month > 12 ? 'Please enter valid expiry date' : '';
}

export function validateCvv(val) {
  return /^\d{3,4}$/.test(val) ? '' : 'Please enter valid cvv';
}
/* Credit/Debit card validation */

export function validateSpecialChars(name) {
  return value => {
    const regex = /[!#@^&?*]/g;
    if (regex.test(value)) {
      return `Special characters ! # @ ^ & ? * are not allowed in ${name}`;
    }
    return null;
  };
}
export function validateSpecialCharsAddress(name) {
  return value => {
    const regex = /[!@^&?]/g;
    if (regex.test(value)) {
      return `Special characters ! @ ^ & ? are not allowed in ${name}`;
    }
    return null;
  };
}

export function validateName(val, formVals) {
  const fullName = `${val}  ${formVals.lastName || ''}`;
  const error = maxLength(100, 'First name + Last name')(fullName);
  if (error) return error;
  return null;
}

export function validateNumber(name) {
  return value => {
    const regex = /^\d+\.?\d*$/;
    if (regex.test(value)) {
      return `Please enter valid ${name}`;
    }
    return null;
  };
}

export function validateNumberSpecialChars(value) {
  const regex = /^[0-9#$%^&*()@!-_]*$/;
  if (regex.test(value.toLowerCase())) {
    return `Please enter valid value`;
  }
  return null;
}

export function validateAlphabetsOnly(value) {
  const regex = /^[A-Za-z]+[A-Za-z ]*$/;
  if (!regex.test(value.toLowerCase())) {
    return `Please enter valid value`;
  }
  return null;
}

const diffBetweenTwoDays = (date1, date2) => {
  const difference = new Date(date1).getTime() - new Date(date2).getTime();
  const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

  return TotalDays;
};

export const validateDateOfBirthday = birthday => {
  const now = new Date();
  const maxValueOfDob = -1;
  const minValueOfDob = -36619;

  let err = false;

  if (!birthday || birthday.toString() === 'Invalid Date') {
    err = 'Invalid Date of Birth.';
  } else {
    if (diffBetweenTwoDays(birthday, now) > maxValueOfDob) {
      err = 'Date of Birth must be less than the current date.';
    }

    if (diffBetweenTwoDays(birthday, now) < minValueOfDob) {
      err = 'Date of Birth must be more than the 1923 year.';
    }
  }

  return err;
};
