import * as actionTypes from '../actionTypes/DTM';

export function getLatestOrder() {
  return { type: actionTypes.GET_LP_DATE };
}

export function setLPDate(data) {
  return { type: actionTypes.SET_LP_DATE, data };
}

export function setDtmPlatform(platform) {
  return { type: actionTypes.SET_PLATFORM, platform };
}
