import React from 'react';

const FixedBottomContainer = ({ children }) => {
  return (
    <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 100 }}>
      {children}
    </div>
  );
};

export default FixedBottomContainer;
