import React, { useState } from 'react';
import Panel from '../../../mobile/components/Panel/Panel';
import { v4 } from 'uuid';

const AccordionGroup = ({ content, className, activeClass, multiOpen, state }) => {
  const [isExpanded, setIsExpanded] = useState(Array(content.length).fill(false));

  const handleAccordionToggle = index => {
    setIsExpanded(prevState => {
      const updatedState = { ...prevState };
      updatedState[index] = multiOpen ? !prevState[index] : false;
      return updatedState;
    });
  };

  return (
    <>
      {content.map((panel, index) => {
        const { header, body, extraSection } = panel;
        return (
          <div
            key={v4()}
            aria-multiselectable="true"
            className={`accordion-group ${
              isExpanded[index] && body && activeClass ? activeClass : className
            }`}
            role="tablist"
          >
            {extraSection}
            {header && (
              <div
                aria-label="accordion header"
                className={`accordion-group-header ${isExpanded[index] && body ? 'expanded' : ''}`}
                onClick={() => handleAccordionToggle(index)}
              >
                {header}
              </div>
            )}
            {body && (
              <Panel expanded={state ? state[header.key] : isExpanded[index]}>
                <div className="accordion-group-body" role="tabpanel">
                  {body}
                </div>
              </Panel>
            )}
          </div>
        );
      })}
    </>
  );
};

export default AccordionGroup;
