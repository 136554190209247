import { extractUtmSourceFromUrl } from '../../../helper';

export const triggerTracking = (userInfo, dataLocale, timeOutId, setTimeOutId) => {
  const {
    utm_source = null,
    utm_medium = null,
    utm_campaign = null,
    utm_term = null,
    utm_content = null,
  } = extractUtmSourceFromUrl(location.search);
  if (utm_source || !localStorage.getItem('utmSource')) {
    localStorage.setItem(
      'utmSource',
      JSON.stringify({ utm_source, utm_medium, utm_campaign, utm_term, utm_content })
    );
  }

  const utmSource =
    localStorage.getItem('utmSource') && JSON.parse(localStorage.getItem('utmSource'));
  const dlUpdate = {
    event: 'VirtualPageView',
    PageUrl: location.href,
    pageType: `${dataLocale?.Profile} Information`,
    mobile: btoa(userInfo?.telephone),
    email: btoa(userInfo?.email),
    loginStatus: userInfo?.login ? 'loggedIn' : 'guest',
    lkUserType: userInfo?.hasPlacedOrder ? 'lkRepeat' : 'lkNew',
    customerID: userInfo?.id,
    countryCode: userInfo?.countryCode,
    currencyCode: userInfo?.currencyCode,
    website_source: `${userInfo?.countryCode} headless`,
    utmSource: utmSource?.utm_source || '(direct)',
    utmMedium: utmSource?.utm_medium || '(none)',
    utmCampaign: utmSource?.utm_campaign || '(direct)',
    utmTerm: utmSource?.utm_term || '',
    utmContent: utmSource?.utm_content || '',
  };
  if (typeof window.dataLayer !== 'undefined') window.dataLayer.push(dlUpdate);
  else {
    if (timeOutId) clearTimeout(timeOutId);
    const newTimeOutId = setTimeout(() => {
      window.dataLayer.push(dlUpdate);
    }, 2000);
    setTimeOutId(newTimeOutId);
  }
};
