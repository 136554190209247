import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './UserInfo.scss';
import { setPreviousUrl } from '../../../actionCreators/auth';
import { addToCart } from '../../../actionCreators/cart';
import { lazyLoadImgs } from '../../../utils/helper';
import ButtonOwnDays from './../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import apiClient from '../../../helpers/apiClient';
import { apiPath } from '../../../config';

const styleUserInfoBtn = {
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
  padding: '8px 0',
  marginTop: '16px',
  marginBottom: '8px',
};

const UserInfo = props => {
  const {
    login,
    userName,
    setPreviousUrl,
    // addToCart,
    dataLocale,
    userInfo,
    redisCommonData,
    isCartDataLoaded,
    isCartUpdating,
    isUserBtnsExpanded,
    handleUserInfoBtns,
  } = props;
  const { isLoyalty, loyaltyExpiryDate, loyaltyDiscount, tierLabel } = userInfo;

  const {
    DAYS_LEFT_TEXT,
    LOGIN_SIGNUP_TEXT,
    LOGIN_OR_REGISTER,
    // RENEW_GOLD_MEMBERSHIP,
    // GET_GOLD_MEMBERSHIP,
    SAVING,
    NUM_365_PLUS,
    CURRENCY_SYMBOL,
  } = dataLocale;

  const [goToCart, setGoToCart] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [membershipData, setMembershipData] = useState(null);
  const goldTeirConfig =
    redisCommonData && redisCommonData.GOLD_TIER ? JSON.parse(redisCommonData.GOLD_TIER) : {};
  const { three65Enable, savings } = goldTeirConfig;
  const currentDate = new Date().getTime();
  const difference = (loyaltyExpiryDate || 0) - currentDate;
  const daysLeft = Math.round(difference / (1000 * 60 * 60 * 24));

  if (isCartDataLoaded && goToCart && !isCartUpdating) {
    window.location.assign('/cart');
    setGoToCart(false);
  }

  useEffect(() => {
    setIsMounted(true);

    const client = apiClient();
    client.setHeader();
    client
      .get(`${apiPath.getCustomerMembership}`)
      .then(res => {
        if (Number(res?.status) === 200) {
          console.log('this is customer membership ===>', res.result);

          setMembershipData(res.result);
        }
      })
      .catch(() => {
        console.log('Failed to fertch account membership data');
      });
  }, []);

  if (isMounted) lazyLoadImgs();

  return (
    <div className="od-userinfo-wrapper">
      <div className="od-userinfo">
        <div className="od-userinfo__content">
          <div className="od-userinfo__content__icon">
            <img
              alt="user-icon"
              className="lazy-load-image"
              data-src="https://static.lenskart.com/media/mobile/images/user-icon.svg"
              height="72px"
              src="https://static1.lenskart.com/media/desktop/img/GreyBackground.png"
              width="72px"
            />
          </div>
          <div>
            <div className="od-userinfo__content__name od-font-bold">{userName}</div>
            {tierLabel && (
              <div className="tier-name">
                <span
                  className="fsp10 bora-8 text-uppercase text-color_grey_black bg-color_dark_yellow inline-block pd-l12 pd-r12 lh-24"
                  data-testid="tierLabel"
                >
                  {tierLabel}
                </span>
              </div>
            )}
            {login ? (
              <React.Fragment>
                {isLoyalty ? (
                  <div className="display-flex mr-t10">
                    <div className="days-left mr-r25">
                      <p className="fsp12 text-color_cc mr-b5">{DAYS_LEFT_TEXT}</p>
                      <span className="fw700 fsp18 lh-18">
                        {three65Enable === 'ON' && daysLeft > 365 ? NUM_365_PLUS : daysLeft}
                      </span>
                    </div>
                    {savings === 'ON' && loyaltyDiscount && (
                      <React.Fragment>
                        <div className="days-divider">&nbsp;</div>
                        <div className="total-savings mr-l15">
                          <p className="fsp12 text-color_cc mr-b5">{SAVING}</p>
                          <span className="fw700 fsp18 lh-18">
                            {CURRENCY_SYMBOL}
                            {Math.round(loyaltyDiscount.value) || ' 0'}
                          </span>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                ) : (
                  <div className="od-userinfo__content__login od-font-reg">
                    {membershipData && (
                      <>
                        <span
                          className={`od-membership-current-rank od-font-bold od-membership-status-${membershipData.currentMemberRank}`}
                        >
                          {membershipData?.currentMemberRank}
                        </span>{' '}
                        {membershipData?.totalEarnedPoints} |{' '}
                        {membershipData?.nextMemberRankDetails?.minPointsForEligibility -
                          (membershipData?.totalEarnedPoints || 0)}{' '}
                        pts more to reach {membershipData?.nextMemberRankDetails?.name}
                      </>
                    )}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div className="od-userinfo__content__login od-font-reg">{LOGIN_SIGNUP_TEXT}</div>
            )}
          </div>
        </div>
        {login && (
          <img
            alt="chevron down black"
            className={`od-userinfo__user-btns-toggler ${
              isUserBtnsExpanded && 'od-userinfo__user-btns-toggler--active'
            }`}
            src="https://static.lenskart.com/media/owndays/mobile/img/od-misc/od-chevron-down-black.svg"
            onClick={handleUserInfoBtns}
          />
        )}
      </div>
      <div className="od-userinfo__content__btn">
        {!login && (
          <ButtonOwnDays
            mode="grey"
            style={styleUserInfoBtn}
            text={LOGIN_OR_REGISTER}
            width="100%"
            onClick={() => {
              setPreviousUrl({ url: window.location.pathname });
              window.location.assign('/customer/account/login');
            }}
          />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const userInfo = state.common.userInfo ? state.common.userInfo.result : {};
  return {
    login: state.common.login,
    isCartDataLoaded: state.cart.isCartDataLoaded,
    isCartUpdating: state.cart.isCartLoader,
    userInfo,
    dataLocale: state.locale,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setPreviousUrl: data => dispatch(setPreviousUrl(data)),
    addToCart: data => dispatch(addToCart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
