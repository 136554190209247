import React, { useEffect, useRef, useState } from 'react';
import './CheckoutBack.scss';
import CheckoutBackIcon from './../../../../static/assets/svg/checkout-back.svg';

const COUNTRY = 'sg';
const LANG = 'en';
const baseImgUrl = 'https://static.lenskart.com/media/owndays/desktop/img/od-logos-new';

const CheckoutBack = ({ onClick }) => {
  const [checkoutBackHeight, setCheckoutBackHeight] = useState(0);
  const checkoutBackRef = useRef(null);

  useEffect(() => {
    const checkoutBackElement = checkoutBackRef.current;
    if (checkoutBackElement) {
      const height = checkoutBackElement.clientHeight;
      setCheckoutBackHeight(height);
    }
  }, []);

  const onClickLogo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    window.location.href = `${window.location.origin}/${COUNTRY}/${LANG}/`;
  };

  return (
    <div className="od-checkout-back-container">
      <div ref={checkoutBackRef} className="od-checkout-back">
        <img alt="checkout back" src={CheckoutBackIcon} onClick={onClick} />
        <img
          alt="owndays logo"
          src={`${baseImgUrl}/logo-type-black.png`}
          className="od-checkout-back__logo"
          onClick={onClickLogo}
        />
      </div>
      <div className="od-content-offset" style={{ height: `${checkoutBackHeight}px` }} />
    </div>
  );
};

export default CheckoutBack;
