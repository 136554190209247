import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../../../CommonComponents/Spinner/Spinner';

const ForgotPassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'forgotPassword' */ './ForgotPassword').then(
      module => module.default
    ),
  loading: () => <Spinner />,
});

export default ForgotPassword;
