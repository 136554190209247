import React from 'react';
import './OdLoader.scss';

const OdLoader = () => {
  return (
    <div className="od-loader">
      <div className="od-loader__spinner"></div>
    </div>
  );
};

export default OdLoader;
