import React, { useState, useEffect, useRef } from 'react';
import './NeedHelpButtonFloating.scss';

export const NeedHelpButtonFloating = props => {
  const { onClick } = props;

  const getSavedPosition = () => {
    const savedPosition = localStorage.getItem('NeedHelpButtonFloatingPosition');
    return savedPosition ? JSON.parse(savedPosition) : { bottom: 4, left: 6 };
  };

  const [position, setPosition] = useState(getSavedPosition());
  const positionRef = useRef(position);
  const [dragging, setDragging] = useState(false);
  const [start, setStart] = useState({ x: 0, y: 0 });

  const preventScroll = e => e.preventDefault();

  useEffect(() => {
    if (dragging) {
      document.addEventListener('touchmove', preventScroll, { passive: false });
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.removeEventListener('touchmove', preventScroll);
      document.body.style.overflow = '';
      document.body.style.height = '';
    }

    return () => {
      document.removeEventListener('touchmove', preventScroll);
      document.body.style.overflow = '';
      document.body.style.height = '';
    };
  }, [dragging]);

  const handleStart = e => {
    e.preventDefault();

    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;

    setDragging(true);
    setStart({
      x: clientX - position.left,
      y: window.innerHeight - clientY - position.bottom,
    });
  };

  const handleMove = e => {
    if (!dragging) return;
    e.preventDefault();

    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;

    let newLeft = clientX - start.x;
    let newBottom = window.innerHeight - clientY - start.y;

    // Keep within window bounds
    const buttonSize = 60;
    const maxLeft = window.innerWidth - buttonSize;
    const maxBottom = window.innerHeight - buttonSize;

    newLeft = Math.max(0, Math.min(newLeft, maxLeft));
    newBottom = Math.max(0, Math.min(newBottom, maxBottom));

    const newPosition = { left: newLeft, bottom: newBottom };
    setPosition(newPosition);
    positionRef.current = newPosition;
  };

  const handleEnd = () => {
    setDragging(false);
    localStorage.setItem('NeedHelpButtonFloatingPosition', JSON.stringify(positionRef.current));
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMove);
    window.addEventListener('mouseup', handleEnd);
    window.addEventListener('touchmove', handleMove, { passive: false });
    window.addEventListener('touchend', handleEnd);

    return () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('touchmove', handleMove);
      window.removeEventListener('touchend', handleEnd);
    };
  }, [dragging]);

  return (
    <div
      className="need-help-button"
      id="zendesk-chat-widget"
      style={{
        bottom: `${position.bottom}px`,
        left: `${position.left}px`,
      }}
      onClick={onClick}
      onMouseDown={e => {
        e.stopPropagation();
        handleStart(e);
      }}
      onTouchStart={e => {
        e.stopPropagation();
        handleStart(e);
      }}
    >
      <img
        alt="zendesk"
        className="zendesk-chat-desktop-img"
        src="https://static.lenskart.com/media/owndays/desktop/img/help-chat-sp.svg"
      />
    </div>
  );
};
