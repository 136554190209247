import './App.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { provideHooks } from 'redial';

import { msiteGlobals } from '../../../config';
// utils
import getSetCookie from './../../../cookie';
import sessionStorageHelper from '../../../utils/sessionStorageHelper';
import {
  extractUtmSourceFromUrl,
  isNavigationRequired,
  isEmptyObject,
  enableBackgroundScroll,
  getQueryParam,
  isSearchCrawler,
  appendScriptToDOM,
  getUserEventData,
  setGAEventListener,
  initChatBot,
  showZendeskWidget,
} from '../../../utils/helper';
// actions
import {
  setJunoSession,
  validateSessionV2,
  getRedisData,
  shortlist,
  getCountryData,
  setIsWebView,
  setPhonepeView,
  passUtmData,
} from './../../../actionCreators/common';
import { showGlobalHeaderFooter, getMobileNavigationData } from '../../../actionCreators/header';
import { getYotpoSiteRatings } from './../../../actionCreators/yotpo';
import { hideNav, cleanReturnExchange } from '../../../actionCreators/returnExchange';
import { setDtmPlatform } from '../../../actionCreators/DTM';
import { getLocaleData } from '../../../actionCreators/locale';
import * as commonCallsActionCreators from '../../../actionCreators/common';
// components
// import HeaderContainer from '../../containers/HeaderContainer/HeaderContainer';
import SWUpdateBanner from '../../components/SWUpdateBanner/Loadable';
import OfflineToast from '../../components/OfflineToast/Loadable';
import ToastMessage from '../../components/ToastMessage/ToastMessage';
import { setLPDate, getLatestOrder } from '../../../actionCreators/DTM';
// import { setWindowDtm } from '../../../utils/DTM';
import { setAndTriggerDataLayer } from '../../../utils/DataLayer';
import Footer from '../../components/Footer/Footer';
import AppHelmet from '../../../CommonComponents/Helmet/AppHelmet';
import ExchangeSession from '../../components/Popup/ExchangeSessionLoadable';
import { langCodeAllowed } from '../../../locales';
import { setCountryData } from '../../../helpers/countryConfig';
import HeaderContainer from './../../../own-days/Mobile/HeaderOD/HeaderContainer/HeaderContainer';
import { CookiesModal } from '../../../../src/own-days/CommonComponents/CookiesModal/CookiesModal';
import OdLoader from '../../../own-days/CommonComponents/OdLoader/OdLoader';
import CommonLoader from '../../../desktop/components/Common/Loader';
import { NeedHelpButtonFloating } from '../../../CommonComponents/NeedHelpButtonFloating/NeedHelpButtonFloating';
import { PopupNotice } from '../../../own-days/CommonComponents/PopupNotice/PopupNotice';
// OD_NOTE: this is required file for carousel DO NOT REMOVE THIS!
import 'theme/vendor/slick.min.scss';

let countryCode = process.env.COUNTRY;
function handleFooter(location) {
  let appContent = document.getElementsByClassName('app-content');
  appContent = appContent && appContent[0];
  if (appContent) {
    if (
      [
        '/checkout/success',
        '/cart',
        '/shortlist',
        '/checkout/uploadprescsms',
        '/checkout/onepage/uploadprescsms',
        '/customer/account/editneft',
      ]?.indexOf(location.pathname) > -1
    ) {
      appContent.style.minHeight = 'calc(100vh - 352px)';
    } else {
      appContent.style.minHeight = 'calc(100vh - 48px)';
    }
  }
}

@provideHooks({
  fetch: ({ location, reqHeaders, reqCookies, store: { dispatch, getState } }) => {
    if (__SERVER__) {
      countryCode = langCodeAllowed[countryCode.toLowerCase()] ? countryCode.toLowerCase() : 'in';
      dispatch(commonCallsActionCreators.localeInfo(langCodeAllowed[countryCode]));
      setCountryData(langCodeAllowed[countryCode]);
    }
    const navReq = isNavigationRequired(location.pathname);
    const state = getState();
    const supportedClients = msiteGlobals ? msiteGlobals.appXApiClients : [];
    const { isWebView, redisCommonData, isPhonepeView } = state.common;
    const isAppClient = reqHeaders && supportedClients.includes(reqHeaders['x-api-client']);
    if (__SERVER__) {
      if ((reqCookies && reqCookies.clientV1) || isWebView || isAppClient) {
        dispatch(validateSessionV2());
      } else {
        dispatch(setJunoSession());
      }
    }
    // get locale config data
    if (isEmptyObject(state.locale)) {
      dispatch(getLocaleData());
    }
    if (
      isWebView ||
      isAppClient ||
      !navReq ||
      location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === 'ar-frames'
    ) {
      dispatch(showGlobalHeaderFooter({ header: false, footer: false }));
      if (isAppClient) {
        dispatch(setIsWebView());
        dispatch(hideNav());
        dispatch(setDtmPlatform(reqHeaders['x-api-client']));
      }
    }
    if (isWebView) {
      enableBackgroundScroll();
    }
    if (__CLIENT__) {
      if (!window.appNavContainer && typeof window.initializeMenuVars === 'function') {
        window.initializeMenuVars();
      }
      if (typeof window.hideMenu === 'function') window.hideMenu();
    }
    const navigationData = state.header.mobileNavigationData;
    if (navReq && !navigationData) {
      dispatch(getMobileNavigationData());
    }
    if (__SERVER__) {
      const uA = reqHeaders['user-agent'];
      if (!redisCommonData || isEmptyObject(redisCommonData)) {
        const isBot = uA ? isSearchCrawler(uA) : false;
        dispatch(getRedisData(isBot));
      }
      if (typeof isPhonepeView === 'undefined') {
        dispatch(setPhonepeView(false));
      }
    }
  },
  defer: ({ location, store: { getState, dispatch } }) => {
    const state = getState();
    const { sessionToken, countryData, isPhonepeView, localeInfo } = state.common;
    const { showGlobalHeader } = state.header;
    const countryCodeCookie = getSetCookie.getCookie('countryCode');
    const trafficSource = getSetCookie.getCookie('trafficSource');
    countryCode =
      countryCodeCookie && langCodeAllowed.hasOwnProperty(countryCodeCookie.toLowerCase())
        ? countryCodeCookie.toLowerCase()
        : 'in';
    const clientV1 = getSetCookie.getCookie('clientV1');
    const frontend = getSetCookie.getCookie('frontend');
    if (!clientV1 && (sessionToken || frontend)) {
      // if (frontend) {
      //   getSetCookie.setCookie('clientV1', frontend, 30);
      // } else {
      //   getSetCookie.setCookie('clientV1', sessionToken);
      // }
      getSetCookie.deleteCookie('frontend');
      let i = 0;
      while (getSetCookie.getCookie('frontend') && i < 5) {
        getSetCookie.deleteCookie('frontend');
        i++;
      }
    }
    if (!localeInfo) {
      dispatch(commonCallsActionCreators.localeInfo(langCodeAllowed[countryCode]));
    }
    setCountryData(langCodeAllowed[countryCode]);
    // get locale config data
    if (isEmptyObject(state.locale)) {
      dispatch(getLocaleData());
    }
    handleFooter(location);
    dispatch(getYotpoSiteRatings());
    if (
      countryCodeCookie &&
      trafficSource &&
      countryCodeCookie.toLowerCase() !== trafficSource.toLowerCase() &&
      trafficSource.toLowerCase() !== 'in' &&
      !countryData &&
      showGlobalHeader
    ) {
      dispatch(getCountryData(trafficSource));
    }
    // fix for browsers where www is not appeneded automatically
    const host = window.location.host + '';
    if (host.toLowerCase() === 'lenskart.com') {
      window.location.host = 'www.lenskart.com';
    }
    if (typeof isPhonepeView === 'undefined') {
      dispatch(setPhonepeView(false));
    }
  },
})
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOffline: false,
      notExchangeSession: false,
      isAppLoaded: false,
    };
    this.closeExchangeFlow = this.closeExchangeFlow.bind(this);
  }

  componentDidMount() {
    const {
      setJunoSession,
      validateSession,
      initialDataLayer,
      redisCommonData,
      getRedisData,
      shortlist,
      location,
      isPhonepeView,
      localeInfo,
      showGlobalHeaderFooter,
    } = this.props;

    const path = window.location.pathname;

    const isCartPage = path.includes('/cart');
    const isShippingPage = path.includes('/checkout/shipping');
    const isPaymentPage = path.includes('/payment-page');
    const isPaymentSuccessPage = path.includes('/payment-success');
    const isAccPage = path.includes('/customer');

    if (isShippingPage || isPaymentPage) {
      showGlobalHeaderFooter({ header: false, footer: false, secondaryHeader: false });
    }

    if (isCartPage || isAccPage || isPaymentSuccessPage || isAccPage) {
      showGlobalHeaderFooter({ header: true, footer: false, secondaryHeader: false });
    }

    const visitedBefore = JSON.parse(localStorage.getItem('visitedBefore'));

    if (visitedBefore === null) {
      localStorage.setItem('visitedBefore', JSON.stringify(true));
    }

    const lastVisitTime = localStorage.getItem('lastVisitTime');
    const currentTime = new Date().getTime();
    const isNewSession = !lastVisitTime || currentTime - lastVisitTime > 30 * 60 * 1000;

    if (isNewSession) {
      localStorage.setItem('lastVisitTime', currentTime.toString());
    }

    const urlParams = new URLSearchParams(window.location.search);
    const utmParameters = {};
    for (const [key, value] of urlParams) {
      if (key.startsWith('utm_')) {
        utmParameters[key] = value;
      }
    }

    const { countryCode } = localeInfo;
    setAndTriggerDataLayer({ website_source: `${countryCode} headless` }, false);

    this.setState({ isAppLoaded: false });

    try {
      if (getSetCookie.getCookie('clientV1') === '') {
        setJunoSession();
      } else {
        validateSession();
      }

      if (!window.appNavContainer && typeof window.initializeMenuVars === 'function') {
        window.initializeMenuVars();
      }

      if (typeof window.hideMenu === 'function') window.hideMenu();

      if (isEmptyObject(redisCommonData)) {
        getRedisData();
      }

      this.loadThirdPartyLibraries();

      const exchangeMethod = getSetCookie.getCookie('exchangeMethod');
      const isExchangeSessionValid =
        sessionStorageHelper.getItem('returnAction') ||
        sessionStorageHelper.getItem('returnProduct')?.returnAction;

      if (exchangeMethod && !isExchangeSessionValid) {
        this.setState({ notExchangeSession: true });
      }

      const {
        utm_source = null,
        utm_medium = null,
        utm_campaign = null,
        utm_content = null,
        utm_term = null,
      } = extractUtmSourceFromUrl(location.search);

      getSetCookie.setCookie('utm_source', utm_source, 1);

      if (localStorage.getItem('utm_source_msite') !== 'admitad') {
        localStorage.setItem('utm_source_msite', utm_source);
      }

      getSetCookie.setCookie('utm_medium', utm_medium, 1);
      getSetCookie.setCookie('utm_campaign', utm_campaign, 1);
      getSetCookie.setCookie('utm_content', utm_content, 1);
      getSetCookie.setCookie('utm_term', utm_term, 1);

      const query = location.search.substr(1);
      const newMSiteOfflineSalesFlow = getQueryParam(query, 'newMSiteOfflineSalesFlow');

      if (newMSiteOfflineSalesFlow) {
        getSetCookie.setCookie('newMSiteOfflineSalesFlow', 'true', 1);
      }

      setTimeout(() => {
        if (
          typeof window.FMApi === 'object' &&
          typeof window.FMApi.manualTriggerExperiment === 'function'
        ) {
          window.FMApi = window.FMApi || [];
          window.FMApi.push('fm_activate_adobe_integration');
        }
      }, 800);

      // 2nd param set false if you do not want to trigger VirtualPageView
      setAndTriggerDataLayer(initialDataLayer, false);

      window.addEventListener('offline', () => {
        this.setOfflineState(true);
      });

      window.addEventListener('online', () => {
        this.setOfflineState(false);
      });

      if (window.navigator && !window.navigator.onLine) {
        this.setOfflineState(true);
      } else {
        this.setOfflineState(false);
      }

      shortlist('', 'get', '');

      //  preload components
      // CrossCountryStrip.preload();
      ExchangeSession.preload();

      // Phonepe switch case view handling
      if (isPhonepeView) document.getElementById('content').classList.add('phonepeview');
      else document.getElementById('content').classList.remove('phonepeview');

      setGAEventListener();
    } catch (error) {
      console.error('Error during component initialization:', error);
    } finally {
      this.setState({ isAppLoaded: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      sessionCode,
      setJunoSession,
      loginStatus,
      getLatestOrder,
      shortlist,
      userInfo,
      gupshupWhatsapp,
    } = this.props;
    if (sessionCode === 401) {
      setJunoSession();
    }
    if (loginStatus !== prevProps.loginStatus) {
      shortlist('', 'get', '');
      if (loginStatus && userInfo) {
        const {
          result: { phoneCode, telephone, lastName, firstName, gender, email, dob },
        } = userInfo;

        const gaData = {
          Name: `${firstName} ${lastName}`,
          Identity: telephone,
          Phone: `${phoneCode}${telephone}`,
          'MSG-push': false,
        };

        if (gender?.toLowerCase()?.indexOf('male') > -1) {
          const genderType = gender.toLowerCase();
          gaData.Gender = genderType === 'male' ? 'M' : 'F';
        }
        if (dob) {
          gaData.DOB = new Date(Number(dob));
        }
        if (email) {
          gaData.email = email;
          gaData['MSG-email'] = true;
        }
        if (telephone) {
          gaData['MSG-sms'] = true;
          gaData['MSG-whatsapp'] = true;
        }

        localStorage.setItem(
          'user-info',
          JSON.stringify({
            name: firstName,
            email,
            phone: phoneCode && telephone ? `${phoneCode?.substr(1)}${telephone}` : '',
            gender,
          })
        );

        getLatestOrder();
      } else {
        setLPDate({ lastPurchaseDate: null });

        localStorage.removeItem('user-info');
      }
    }
    if (
      gupshupWhatsapp &&
      prevProps.gupshupWhatsapp.isOptWhatsApp !== gupshupWhatsapp.isOptWhatsApp &&
      userInfo &&
      loginStatus
    ) {
      const { phoneCode, telephone } = userInfo?.result;
      if (location.pathname?.indexOf('/checkout') > -1) {
        this.passingUtmData('ADD_TO_CART_LOGIN', phoneCode, telephone);
      }
      if (location.pathname === '/') {
        this.passingUtmData('LOGGED_IN', phoneCode, telephone);
      }
    }
    const freshBotHide = document
      .getElementById('mobile-chat-container')
      ?.className?.includes('joe-hide');
    if (location.pathname?.indexOf('/support') === -1 && window.Freshbots && !freshBotHide) {
      window.Freshbots.hideWidget(true);
    }

    // sprinklr bot hide
    if (window?.sprChat && location.pathname?.indexOf('/support') === -1) {
      const botElem = document.createElement('style');
      botElem.setAttribute('id', 'sprinklr-bot-hide-custom');
      botElem.innerHTML = '#spr-live-chat-app{display:none}';
      document.body.appendChild(botElem);
    }
  }

  componentWillUnmount() {
    document.querySelector('body').removeEventListener('click', () => {}, false);
  }

  setOfflineState(isOffline) {
    this.setState({ isOffline });
  }

  loadThirdPartyLibraries = () => {
    const { redisCommonData } = this.props;
    const sprinklrBotConfig =
      redisCommonData?.SPRINKLR_BOT_CONFIG &&
      JSON.parse(redisCommonData?.SPRINKLR_BOT_CONFIG)?.msite_enabled;
    if (sprinklrBotConfig === 'OFF') {
      initChatBot();
    }
    const freshmarketerEnabled = redisCommonData && redisCommonData.ENABLE_FRESHWORKS === 'ON';
    if (freshmarketerEnabled) {
      appendScriptToDOM(
        'https://static.lenskart.com/media/desktop/js/jquery-3.5.1.slim.min.js',
        '',
        false,
        null
      );
      appendScriptToDOM(
        'https://cdn.freshmarketer.com/232805/806426.js',
        'vendor_freshmarketer',
        true,
        null
      );
    }
  };

  passingUtmData(eventName, phoneCode, telephone) {
    const { passUtmData } = this.props;
    const data = getUserEventData(eventName);
    data.mobileNumber = `${phoneCode}${telephone}`;
    passUtmData(data);
  }

  closeExchangeFlow() {
    const { cleanReturnExchange } = this.props;
    cleanReturnExchange();
    getSetCookie.deleteCookie('exchangeMethod');
    getSetCookie.deleteCookie('exchangeOrderId');
    getSetCookie.deleteCookie('exchangeItemId');
    sessionStorageHelper.removeItem('returnAction');
    window.location.reload();
  }

  loadZendeskChatScript = () => {
    // Create a <script> element
    const script = document.createElement('script');
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=85b011ea-d0e4-463a-b1ee-d7e54f7e2a0f';
    script.async = true;
    script.id = 'ze-snippet';

    // Append the <script> element to the document body
    document.body.appendChild(script);
  };

  toggleChatWidget() {
    if (typeof window !== 'undefined' && window?.$zopim?.livechat) {
      window.$zopim.livechat.window.show();
    } else if (showZendeskWidget(window?.location?.href)) {
      this.loadZendeskChatScript();
      setTimeout(() => {
        window.$zopim?.livechat?.window?.show();
      }, 1000);
    }
    // Toggle the visibility by toggling the CSS class
    // const chatWidget = document.querySelector('.chat-widget');
    // chatWidget.classList.toggle('visible');
  }

  render() {
    const {
      section,
      route,
      redisCommonData,
      showGlobalHeader,
      showGlobalSecondaryHeader,
      showGlobalFooter,
      location,
      countryData,
      isPhonepeView,
      dataLocale,
      history,
      localeInfo,
      utmMapping,
      showOdLoader,
    } = this.props;
    const { UPDATE_TOAST_CONFIG, OFFLINE_ORDER_NUMBER = '01296613916' } = redisCommonData || {};
    const { isOffline, notExchangeSession, isAppLoaded } = this.state;

    const trafficSource = getSetCookie.getCookie('trafficSource');

    if (!isAppLoaded) {
      return <CommonLoader />;
    }

    return (
      <React.Fragment>
        <AppHelmet
          localeInfo={localeInfo}
          location={location}
          pathname={location.pathname}
          section={section}
        ></AppHelmet>
        {showGlobalHeader && (
          <HeaderContainer
            dataLocale={dataLocale}
            history={history}
            isPhonepeView={isPhonepeView}
          />
        )}
        {showGlobalSecondaryHeader && (
          <HeaderContainer
            dataLocale={dataLocale}
            history={history}
            isPhonepeView={isPhonepeView}
          />
        )}
        <main className="app-content">{renderRoutes(route.routes)}</main>
        {showGlobalFooter && <Footer history={history} />}
        <ToastMessage />
        {showOdLoader ? <OdLoader /> : null}
        <SWUpdateBanner dataLocale={dataLocale} updateToastConfig={UPDATE_TOAST_CONFIG} />
        {isOffline && <OfflineToast dataLocale={dataLocale} offlineNumber={OFFLINE_ORDER_NUMBER} />}
        {notExchangeSession && (
          <ExchangeSession closeExchangeFlow={this.closeExchangeFlow} dataLocale={dataLocale} />
        )}
        <CookiesModal />
        <PopupNotice />
        {showZendeskWidget(window?.location?.href) ? (
          <NeedHelpButtonFloating onClick={() => this.toggleChatWidget()} />
        ) : null}
      </React.Fragment>
    );
  }
}

App.propTypes = {
  route: PropTypes.objectOf(PropTypes.any).isRequired,
  loginStatus: PropTypes.bool,
};

App.defaultProps = {
  loginStatus: false,
};

const mapStateToProps = state => ({
  redisCommonData: state.common.redisCommonData,
  utmMapping: state.common.utmMapping,
  loginStatus: state.common.login,
  countryData: state.common.countryData,
  sessionCode: state.common.errorCode,
  initialDataLayer: state.DataLayer,
  showGlobalHeader: state.header.showGlobalHeader,
  showGlobalSecondaryHeader: state.header.showGlobalSecondaryHeader,
  showGlobalFooter: state.header.showGlobalFooter,
  isPhonepeView: state.common.isPhonepeView,
  userInfo: state.common.userInfo,
  dataLocale: state.locale,
  gupshupWhatsapp: state.gupshupWhatsapp,
  localeInfo: state.common.localeInfo,
  section: state.common.section,
  platform: state.DTM.platform,
  showOdLoader: state.ui.showOdLoader,
});

function mapDispatchToProps(dispatch) {
  return {
    setJunoSession: () => dispatch(setJunoSession()),
    validateSession: data => dispatch(validateSessionV2(data)),
    getLatestOrder: () => dispatch(getLatestOrder()),
    setLPDate: data => dispatch(setLPDate(data)),
    shortlist: (productId, method, suffixUrl) => dispatch(shortlist(productId, method, suffixUrl)),
    getRedisData: isBot => dispatch(getRedisData(isBot)),
    cleanReturnExchange: () => dispatch(cleanReturnExchange()),
    passUtmData: data => dispatch(passUtmData(data)),
    showGlobalHeaderFooter: data => dispatch(showGlobalHeaderFooter(data)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
