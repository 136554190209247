import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';
import ExternalResources from '../CommonComponents/ExternalResources/ExternalResources';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
<meta name="og:title" content="Memory Metal | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta name="description" content="A collection featuring Nitinol (NiTi) frames that are made using the latest ion plating (IP) process. Nitinol is a shape memory alloy consisting of nickel and titanium. The material exhibits great elasticity and durability. It is able to retain its shape despite being bent by force." />
<meta name="keywords" content="Memory Metal,Ion Plating,Nitinol,NiTi,Elasticity,spectacles,glasses,eye glasses" />
<meta name="og:description" content="A collection featuring Nitinol (NiTi) frames that are made using the latest ion plating (IP) process. Nitinol is a shape memory alloy consisting of nickel and titanium. The material exhibits great elasticity and durability. It is able to retain its shape despite being bent by force." />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/memorymetal">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/memorymetal2023/ogp_1200x630.jpg?1677111156">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    
    
    <style>
    .loading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(234 220 192 / 95%);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
    }
</style>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick-theme.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css">
<link rel="stylesheet" href="https://www.owndays.com/web/css/specials-memorymetal2023.css">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Nokora:wght@400;700&family=Noto+Sans+TC:wght@400;500&family=Nunito+Sans:wght@400;600&display=swap" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    
    
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">Memory Metal | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            Memory Metal                    </li>
        
            </ul>
</div>


<div class="l-memorymetal2023">
    <div class="loading">
        <span>
            <img src="https://www.owndays.com/images/specials/products/memorymetal2023/brand-logo.svg" alt="Memory Metal" style="width: 250px">
        </span>
    </div>
    <section class="main">
        <div class="main__bg">
            <picture>
                <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/memorymetal2023/main-pc.webp">
                <img src="https://www.owndays.com/images/specials/products/memorymetal2023/main-sp.webp" alt="Memory Metal" data-aos="fade-down">
            </picture>
        </div>
        <div class="main__content">
            <div class="l-memorymetal2023__container">
                <div class="main__heading">
                    <p class="main__heading-logo" data-aos="fade-right" data-aos-delay="300" data-aos-offset="50">
                        <img src="https://www.owndays.com/images/specials/products/memorymetal2023/brand-logo.svg" alt="Memory Metal">
                    </p>
                    <p class="main__heading-text" data-aos="fade-right" data-aos-delay="600" data-aos-offset="50">Great Elasticity and Durability, The New Standards of Spectacle Frames</p>
                </div>
                <div class="main__points" data-aos="fade-up" data-aos-delay="900">
                    <ul class="main__points-list">
                        <li class="main__points-list-item point">
                            <div class="point__icon">
                                <img src="https://www.owndays.com/images/specials/products/memorymetal2023/point-1.svg" alt="Unparalleled Elasticity">
                            </div>
                            <div class="point__details">
                                <h5 class="point__details-title">Unparalleled Elasticity</h5>
                                <p class="point__details-text">Made of Nitinol is able to retain its shape despite being bent by force</p>
                            </div>
                        </li>
                        <li class="main__points-list-item point">
                            <div class="point__icon">
                                <img src="https://www.owndays.com/images/specials/products/memorymetal2023/point-2.svg" alt="Amazingly Lightweight">
                            </div>
                            <div class="point__details">
                                <h5 class="point__details-title">Amazingly Lightweight</h5>
                                <p class="point__details-text">So light you will forget you are wearing spectacles</p>
                            </div>
                        </li>
                        <li class="main__points-list-item point">
                            <div class="point__icon">
                                <img src="https://www.owndays.com/images/specials/products/memorymetal2023/point-3.svg" alt="Durable and Rust-Resistant">
                            </div>
                            <div class="point__details">
                                <h5 class="point__details-title">Durable and Rust-Resistant</h5>
                                <p class="point__details-text">Applying ion plating to enhance durability and resistance to rust</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="concept">
        <div class="l-memorymetal2023__container l-memorymetal2023__container--sm">
            <h2 class="l-memorymetal2023__title" data-aos>
                <span>NiTi Shape Memory Alloy</span>
            </h2>
            <div class="concept__content">
                <div class="concept__movie">
                    <a class="concept__movie-link" data-fancybox="etc" data-caption="Memory Metal PR" href="https://www.youtube.com/watch?v=mJWPHuYB324">
                        <div class="concept__movie-icon">
                            <img src="https://www.owndays.com/images/specials/products/memorymetal2023/play.svg" alt="Memory Metal NiTi Shape Memory Alloy">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 198">
                                <circle cx="98" cy="98" r="97" stroke="none"></circle>
                            </svg>
                        </div>
                        <img class="concept__movie-thumb" src="https://www.owndays.com/images/specials/products/memorymetal2023/thumbnail-1.webp" alt="Memory Metal" />
                    </a>
                                    </div>
                <p class="concept__text" data-aos="fade-up">A collection featuring Nitinol (NiTi) frames that are made using the latest ion plating (IP) process.<br>Nitinol is a shape memory alloy consisting of nickel and titanium. The material exhibits great elasticity and durability. It is able to retain its shape despite being bent by force.<br>Ion plating is applied on the frame surface to enhance durability and resistance to rust. This also prevents the frame coating from peeling or scratching while acting as a protective layer for people with metal allergies.</p>
            </div>
        </div>
        <p class="l-memorymetal2023__heading" data-aos="fade-right">NITINOL FRAMES</p>
    </section>
    <section class="testing">
        <div class="l-memorymetal2023__container l-memorymetal2023__container--sm">
            <h2 class="l-memorymetal2023__title" data-aos>
                <span>Japanese Industrial Standards (JIS) <br class="u-sp-only">Temple Performance Test</span>
            </h2>
            <div class="testing__content">
                <p class="testing__text" data-aos="fade-up">The elasticity and durability of Memory Metal are validated by bridge distortion and durability tests carried out based on Japanese Industrial Standards (JIS B 7285:2016). In addition, a test conducted by the Japan Spectacles and Popular Optics Inspection Institute has shown that the temples are able to retain their shape without distortion despite being twisted over 10,000 times.</p>
            </div>
        </div>
        <p class="l-memorymetal2023__heading" data-aos="fade-left">GREAT ELASTICITY</p>
        <div class="testing__img" data-aos="fade-down">
            <img src="https://www.owndays.com/images/specials/products/memorymetal2023/main-2.webp" alt="Japanese Industrial Standards (JIS) Temple Performance Test" />
        </div>
    </section>
    <section class="lineup">
        <div class="l-memorymetal2023__container l-memorymetal2023__container--sm">
            <h2 class="l-memorymetal2023__title" data-aos>
                <span>Memory Metal Frames</span>
            </h2>
        </div>
        <div class="lineup__content">
            <p class="l-memorymetal2023__heading"><span data-aos="fade-right">LINEUP</span></p>
            <div class="l-memorymetal2023__container">
                <p class="lineup__text" data-aos="fade-up">Introducing 3 new models! From the currently-trending polygonal styles to the always-chic rectangle styles, <br class="u-pc-only">discover an array of designs to suit any occasion.</p>
                <ul class="lineup__list">
                    <!-- Products new -->
                    <!-- MM1013B-3S -->
                    <li class="lineup__list-item lineup__list-item--new">
                        <div class="product product--slider product--for">
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1013B-3S/MM1013B-3S_C1_01.webp" alt="MM1013B-3S C1">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1013B-3S C1</p>
                                    <a href="/sg/en/products/MM1013B-3S?sku=6586" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1013B-3S/MM1013B-3S_C2_01.webp" alt="MM1013B-3S C2">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1013B-3S C2</p>
                                    <a href="/sg/en/products/MM1013B-3S?sku=6650" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1013B-3S/MM1013B-3S_C3_01.webp" alt="MM1013B-3S C3">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1013B-3S C3</p>
                                    <a href="/sg/en/products/MM1013B-3S?sku=6651" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1013B-3S/MM1013B-3S_C4_01.webp" alt="MM1013B-3S C4">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1013B-3S C4</p>
                                    <a href="/sg/en/products/MM1013B-3S?sku=6652" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="product product--slider product--nav">
                            <span class="product__color" style="background-color: #000000"></span>
                            <span class="product__color" style="background-image: url(https://www.owndays.com/images/specials/products/memorymetal2023/colors/C2.png)"></span>
                            <span class="product__color" style="background-color: #754C27"></span>
                            <span class="product__color" style="background-image: url(https://www.owndays.com/images/specials/products/memorymetal2023/colors/C4.png)"></span>
                        </div>
                    </li>
                    <!-- MM1014B-3S -->
                    <li class="lineup__list-item lineup__list-item--new">
                        <div class="product product--slider product--for">
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1014B-3S/MM1014B-3S_C1_01.webp" alt="MM1014B-3S C1">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1014B-3S C1</p>
                                    <a href="/sg/en/products/MM1014B-3S?sku=6587" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1014B-3S/MM1014B-3S_C2_01.webp" alt="MM1014B-3S C2">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1014B-3S C2</p>
                                    <a href="/sg/en/products/MM1014B-3S?sku=6653" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1014B-3S/MM1014B-3S_C3_01.webp" alt="MM1014B-3S C3">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1014B-3S C3</p>
                                    <a href="/sg/en/products/MM1014B-3S?sku=6654" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1014B-3S/MM1014B-3S_C4_01.webp" alt="MM1014B-3S C4">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1014B-3S C4</p>
                                    <a href="/sg/en/products/MM1014B-3S?sku=6655" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="product product--slider product--nav">
                            <span class="product__color" style="background-image: url(https://www.owndays.com/images/specials/products/memorymetal2023/colors/C1.png)"></span>
                            <span class="product__color" style="background-color: #754C27"></span>
                            <span class="product__color" style="background-image: url(https://www.owndays.com/images/specials/products/memorymetal2023/colors/C3.png)"></span>
                            <span class="product__color" style="background-image: url(https://www.owndays.com/images/specials/products/memorymetal2023/colors/C4.png)"></span>
                        </div>
                    </li>
                    <!-- MM1015B-3S -->
                    <li class="lineup__list-item lineup__list-item--new">
                        <div class="product product--slider product--for">
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1015B-3S/MM1015B-3S_C1_01.webp" alt="MM1015B-3S C1">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1015B-3S C1</p>
                                    <a href="/sg/en/products/MM1015B-3S?sku=6588" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1015B-3S/MM1015B-3S_C2_01.webp" alt="MM1015B-3S C2">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1015B-3S C2</p>
                                    <a href="/sg/en/products/MM1015B-3S?sku=6656" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1015B-3S/MM1015B-3S_C3_01.webp" alt="MM1015B-3S C3">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1015B-3S C3</p>
                                    <a href="/sg/en/products/MM1015B-3S?sku=6657" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div class="product__img">
                                    <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1015B-3S/MM1015B-3S_C4_01.webp" alt="MM1015B-3S C4">
                                </div>
                                <div class="product__details">
                                    <p class="product__sku">MM1015B-3S C4</p>
                                    <a href="/sg/en/products/MM1015B-3S?sku=6658" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                        <span data-hover="More Details">More Details</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="product product--slider product--nav">
                            <span class="product__color" style="background-color: #000000"></span>
                            <span class="product__color" style="background-image: url(https://www.owndays.com/images/specials/products/memorymetal2023/colors/C2-2.png)"></span>
                            <span class="product__color" style="background-image: url(https://www.owndays.com/images/specials/products/memorymetal2023/colors/C3-2.png)"></span>
                            <span class="product__color" style="background-image: url(https://www.owndays.com/images/specials/products/memorymetal2023/colors/C4-2.png)"></span>
                        </div>
                    </li>

                    <!-- Products normal -->
                    <!-- MM1001B-0S -->
                    <li class="lineup__list-item">
                        <div class="product">
                            <div class="product__img">
                                <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1001B-0S_C3.webp" alt="MM1001B-0S C3">
                            </div>
                            <div class="product__details">
                                <p class="product__sku">MM1001B-0S C3</p>
                                <a href="/sg/en/products/MM1001B-0S?sku=4787" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                    <span data-hover="More Details">More Details</span>
                                </a>
                            </div>
                        </div>
                    </li>
                    <!-- MM1002B-0S -->
                    <li class="lineup__list-item id-none">
                        <div class="product">
                            <div class="product__img">
                                <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1002B-0S_C4.webp" alt="MM1002B-0S C4">
                            </div>
                            <div class="product__details">
                                <p class="product__sku">MM1002B-0S C4</p>
                                <a href="/sg/en/products/MM1002B-0S?sku=4792" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                    <span data-hover="More Details">More Details</span>
                                </a>
                            </div>
                        </div>
                    </li>
                                        <!-- MM1005B-0S -->
                    <li class="lineup__list-item">
                        <div class="product">
                            <div class="product__img">
                                <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1005B-0S_C2.webp" alt="MM1005B-0S C2">
                            </div>
                            <div class="product__details">
                                <p class="product__sku">MM1005B-0S C2</p>
                                <a href="/sg/en/products/MM1005B-0S?sku=4802" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                    <span data-hover="More Details">More Details</span>
                                </a>
                            </div>
                        </div>
                    </li>
                    <!-- MM1006B-0S -->
                    <li class="lineup__list-item">
                        <div class="product">
                            <div class="product__img">
                                <img src="https://www.owndays.com/images/specials/products/memorymetal2023/products/MM1006B-0S_C1.webp" alt="MM1006B-0S C1">
                            </div>
                            <div class="product__details">
                                <p class="product__sku">MM1006B-0S C1</p>
                                <a href="/sg/en/products/MM1006B-0S?sku=" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown-light">
                                    <span data-hover="More Details">More Details</span>
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="l-memorymetal2023__btn-group">
                    <a href="/sg/en/products?productLines[]=32" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown">
                        <span data-hover="See All Memory Metal Products">See All Memory Metal Products</span>
                    </a>
                    <a href="/sg/en/shops?autoSearchCurrentLocation=true" class="l-memorymetal2023__btn l-memorymetal2023__btn--brown">
                        <span data-hover="Search For Nearest OWNDAYS Stores">Search For Nearest OWNDAYS Stores</span>
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section class="recommend">
        <p class="l-memorymetal2023__heading" data-aos="fade-left">RECOMMENDED BRANDS</p>
        <div class="l-memorymetal2023__container l-memorymetal2023__container--sm">
            <h2 class="l-memorymetal2023__title" data-aos>
                <span>
                    <small>Lightweight, Durable, Flexible</small>OWNDAYS Spectacle Frames                </span>
            </h2>
            <div class="recommend__content" data-aos>
                <span class="recommend__content-border recommend__content-border--top"></span>
                <span class="recommend__content-border recommend__content-border--left"></span>
                <span class="recommend__content-border recommend__content-border--right"></span>
                <span class="recommend__content-border recommend__content-border--bottom"></span>
                <ul class="recommend__list">
                    <li class="recommend__list-item" data-aos="zoom-in">
                        <div class="recommend__img">
                            <picture>
                                <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/memorymetal2023/brand-airultem-pc.webp">
                                <img src="https://www.owndays.com/images/specials/products/memorymetal2023/brand-airultem-sp.webp" alt="AIR Ultem">
                            </picture>
                        </div>
                        <div class="recommend__details">
                            <p class="recommend__text">A collection of frames made of ultem, an ultra-flexible material that is amazingly light and durable. These unique properties make AIR Ultem a highly functional collection that is perfect for day-to-day use.</p>
                            <a href="/sg/en/news/airultem" class="l-memorymetal2023__btn l-memorymetal2023__btn--black">
                                <span data-hover="More Details on AIR Ultem">More Details on AIR Ultem</span>
                            </a>
                        </div>
                    </li>
                    <li class="recommend__list-item" data-aos="zoom-in">
                        <div class="recommend__img">
                            <picture>
                                <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/memorymetal2023/brand-airfit-pc.webp">
                                <img src="https://www.owndays.com/images/specials/products/memorymetal2023/brand-airfit-sp.webp" alt="AIR FIT">
                            </picture>
                        </div>
                        <div class="recommend__details">
                            <p class="recommend__text">So comfortable you feel like you’re not wearing glasses spectacles ― this is the idea which has inspired us to create a collection featuring frames made of a material that is both lightweight, flexible and corrosion-resistant.</p>
                            <a href="/sg/en/products?productLines[]=10" class="l-memorymetal2023__btn l-memorymetal2023__btn--black">
                                <span data-hover="See All AIR FIT Products">See All AIR FIT Products</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</div>

        </main>
            </div>


    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/scrollReveal.js/4.0.9/scrollreveal.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"></script>
<script src="https://www.owndays.com/web/js/specials-memorymetal2023.js"></script>
  
</body>

</html>`;

const MemoryProductsHTML = () => {
  return (
    <>
      <ExternalResources />
      <MetaTagsManager
        canonical="/news/memorymetal"
        description="Experience the new standards of spectacle frames with amazing elasticity and durability. OWNDAYS presents Nitinol frames that retain shape and resist rust."
        title="Memory Metal Frames | OWNDAYS Singapore Online Store"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default MemoryProductsHTML;
