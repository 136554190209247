import React from 'react';
import Shimmer from '../../CommonComponents/Shimmer/Shimmer';

export const PrimerCardShimmer = () => (
  <>
    <div style={{ padding: '16px 0' }}>
      <Shimmer line height={52} />
      <div
        style={{ display: 'grid', marginTop: 12, gridTemplateColumns: 'repeat(2, 1fr)', gap: 12 }}
      >
        <Shimmer line height={52} />
        <Shimmer line height={52} />
      </div>
      <Shimmer line height={52} style={{ marginTop: 12 }} />
    </div>
  </>
);
