import React, { useMemo } from 'react';
import LinkProvider from '../../../CommonComponents/LinkProvider/LinkProvider';
import { removeDomainName } from '../../../utils/helper';
import './Breadcrumb.scss';

const Breadcrumb = props => {
  const {
    breadcrumb = [],
    dataLocale: { FORWARD_SLASH },
    countryCode = 'in',
    hideFirstCrumb = false,
  } = props;

  const displayedBreadcrumbs = useMemo(() => {
    // return breadcrumb.filter(({ link, label }) => link && label !== 'Mobile');
    return breadcrumb;
  }, [breadcrumb]);

  return displayedBreadcrumbs.map((item, index) => {
    if (index === 0 && hideFirstCrumb) return null;
    return (
      <span
        key={`link-${index}`}
        className={`od-font-bold ${
          index === displayedBreadcrumbs.length - 1 ? 'breadcrumb-active' : 'breadcrumb-item'
        }`}
      >
        <LinkProvider
          aria-label={`breadcrumb-${index}`}
          to={`/${removeDomainName(item.link, 'mobile/', countryCode)}`}
        >
          {item.label}
        </LinkProvider>
        {index <= displayedBreadcrumbs.length - 2 && (
          <span className="breadcrumb-separator">{FORWARD_SLASH}</span>
        )}
      </span>
    );
  });
};

export default Breadcrumb;
