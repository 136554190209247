import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../../../CommonComponents/Spinner/Spinner';

const CartLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'Cart' */ './CartContainer').then(module => module.default),
  loading: () => <Spinner />,
});

export default CartLoadable;
