import React from 'react';
import HarryPotterBase from '../../../CommonComponents/SpecialPages/HarryPotter/HarryPotterBase';
import Breadcrumb from '../../../../mobile/components/Breadcrumb/Breadcrumb';
import './HarryPotterSP.scss';

const HarryPotterSP = () => {
  return (
    <>
      <div className="od-breadcrumb-mobile">
        <Breadcrumb
          breadcrumb={[
            {
              label: 'Homepage',
            },
            {
              label: 'News',
              link: 'news',
            },
            {
              label: 'Harry Potter × OWNDAYS',
            },
          ]}
          dataLocale={{
            FORWARD_SLASH: '/',
          }}
        />
      </div>
      <HarryPotterBase className="l-hp" />
    </>
  );
};

export default HarryPotterSP;
