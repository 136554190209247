import React, { useEffect } from 'react';
import './WishlistPage.scss';

import { connect, useDispatch } from 'react-redux';
import * as commonCallsActionCreators from '../../../../actionCreators/common';

import WishlistPageItems from './WishlistPageItems/WishlistPageItems';
import OdEmptyWishlistImage from '../../../../../static/assets/img/wishlist/od-empty-wishlist.svg';
import { Helmet } from 'react-helmet';
import { odPushClevertapEvent } from '../../../../utils/clevertap';

const WishListPage = props => {
  const { dataLocale, shortListData } = props;

  const { WISHLIST, ITEMS, YOUR_WISHLIST_IS_EMPTY } = dataLocale;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonCallsActionCreators.shortlist('', 'get', '?attributes=true'));

    odPushClevertapEvent('Page Viewed', {
      pageName: 'Wishlist',
      pageUrl: window.location.href,
    });
  }, []);

  useEffect(() => {
    const headerHeight = window.document.getElementById('header')?.offsetHeight;
    const wishlistPage = window.document.getElementById('od-wishlist-page-mobile');

    if (wishlistPage) wishlistPage.style.minHeight = `calc(100vh - ${Math.floor(headerHeight)}px)`;
  });

  return (
    <main className="od-wishlist-page-mobile" id="od-wishlist-page-mobile">
      <Helmet
        meta={[
          {
            name: 'description',
            content: `${WISHLIST}｜OWNDAYS ONLINE STORE - OPTICAL SHOP`,
          },
        ]}
        title={`${WISHLIST}｜OWNDAYS ONLINE STORE - OPTICAL SHOP`}
      />
      <div className="od-wishlist-page-mobile__layout">
        <h1 className="od-wishlist-page-mobile__title od-font-bold">{`${WISHLIST} (${
          shortListData?.result?.numberOfProducts || 0
        } ${ITEMS})`}</h1>

        {shortListData?.result?.numberOfProducts ? (
          <WishlistPageItems />
        ) : (
          <>
            <p className="od-wishlist-page-mobile__empty">{YOUR_WISHLIST_IS_EMPTY}</p>
            <img
              alt={YOUR_WISHLIST_IS_EMPTY}
              className="od-wishlist-page-mobile__empty-image"
              src={OdEmptyWishlistImage}
              width="250px"
            />
          </>
        )}
      </div>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    dataLocale: state.locale,
    shortListData: state.common.shortListData,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WishListPage);
