import React, { useEffect, useState } from 'react';
import './Signup.scss';
import { registerUser } from '../../../actionCreators/auth';
import { validateSingaporeMobileNumber } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { showGlobalHeaderFooter } from '../../../actionCreators/header';
import { setToastMessage } from '../../../actionCreators/common';
import CustomDatePicker from '../../../own-days/CommonComponents/CustomDatePicker/CustomDatePicker';
import { validateDateOfBirthday } from '../../../utils/validation';
import { parseQueryParams } from '../../../own-days/helper';
import { odPushClevertapEvent } from '../../../utils/clevertap';
import ReceiveNews from '../../../CommonComponents/ReceiveNews/ReceiveNews';
import Top from './Top';
import { checkCustomersByToken } from '../../../servicesOwndaysNet/customer';

const Signup = props => {
  const { history } = props;
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [password, setPassword] = useState('');
  const [gender, setGender] = useState('male');
  const [refCode, setRefCode] = useState('');
  const [showRefCode, setShowRefCode] = useState(false);
  const [phoneErr, setPhoneErr] = useState('');
  const [dobErr, setDobErr] = useState('');
  const [nameErr, setNameErr] = useState('');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [whatsappInfo, setWhatsappInfo] = useState(false);
  const [visitReason, setVisitReason] = useState('buyGlasses');
  const [receiveNews, setReceiveNews] = useState({
    email: false,
    sms: false,
  });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [agreeAll, setAgreeAll] = useState(false);
  const [customerData, setCustomerData] = useState(null);

  const authLoading = useSelector(state => state?.auth?.loading);
  const isLogin = useSelector(state => state.common?.login);
  const registerError = useSelector(state => state?.auth?.registerError?.message);
  const { storeCode } = parseQueryParams(['storeCode']);
  const { whatsappId, mobileNo, staffCode } = parseQueryParams([
    'whatsappId',
    'mobileNo',
    'staffCode',
    'storeCode',
  ]);

  const dispatch = useDispatch();
  const prevUrl = props?.location?.state?.prevUrl;

  useEffect(() => {
    odPushClevertapEvent('Page Viewed', {
      pageName: 'Signup Page',
      pageUrl: window.location.href,
    });
  }, []);

  useEffect(() => {
    dispatch(setToastMessage({ warning: true, message: registerError, timeout: 3000 }));
  }, [registerError]);

  useEffect(() => {
    console.log('@SINGUP LOGIC ====>', isLogin, prevUrl);
  }, [prevUrl]);

  useEffect(() => {
    dispatch(showGlobalHeaderFooter({ header: false, footer: false, secondaryHeader: false }));
  }, [dispatch]);

  useEffect(() => {
    if (isLogin) {
      const redirectTo = prevUrl ? prevUrl.replace(/\/sg\/en/g, '') : '';
      if (redirectTo?.length > 0 || redirectTo === '') {
        window.location.href = redirectTo === '/' ? redirectTo : `/sg/en${redirectTo}`;
        // history.push(redirectTo);
      } else {
        window.location.href = '/';
      }
    }
  }, [isLogin]);

  useEffect(() => {
    const { whatsappId, mobileNo, staffCode, storeCode } = parseQueryParams([
      'whatsappId',
      'mobileNo',
      'staffCode',
      'storeCode',
    ]);

    if (mobileNo && whatsappId) {
      setWhatsappInfo(prevState => {
        return { ...prevState, mobileNo, whatsappId };
      });
    }

    if (staffCode && storeCode) {
      setWhatsappInfo(prevState => {
        return { ...prevState, staffCode, storeCode };
      });
    }
  }, []);

  useEffect(() => {
    setPhoneNumber(whatsappInfo?.mobileNo || '');
  }, [whatsappInfo]);

  useEffect(() => {
    checkCustomersByToken(setCustomerData);
  }, []);

  useEffect(() => {
    if (customerData) {
      const {
        firstName = '',
        lastName = '',
        karteEmail = '',
        eMail = '',
        birthDay = '',
        telNo = '',
        gender = 'male',
      } = customerData || {};

      setFullName(`${firstName} ${lastName}`);
      setEmail(karteEmail || eMail);
      setPhoneNumber(telNo);
      setDob(birthDay);
      setGender(gender);
    }
  }, [customerData]);

  const handleSubmit = e => {
    e.preventDefault();
    const nameArr = fullName?.trim()?.split(' ');
    const firstName = nameArr[0];
    const lastName = nameArr[nameArr.length - 1];

    if (firstName.length < 1 || lastName.length < 1) {
      if (firstName.length === 0) {
        setNameErr('Firstname is required');
      }
      if (lastName.length === 0) {
        setNameErr('Lastname is required');
      }
      if (nameArr.length < 2) {
        setNameErr('Firstname and Lastname is required');
      }
      if (!dob) {
        setNameErr('DOB is required');
      }
      return;
    }
    setNameErr('');

    if (!validateSingaporeMobileNumber(phoneNumber)) {
      setPhoneErr('Please enter a valid singapore mobile number');
      return;
    }
    setPhoneErr('');

    const dobError = validateDateOfBirthday(dob);

    if (dobError) {
      setDobErr(dobError);
      return;
    }
    setDobErr('');

    if (!termsAccepted) {
      setNameErr('Please accept the terms and conditions');
      return;
    }
    setNameErr('');

    const data = {
      dob,
      firstName,
      lastName,
      mobile: phoneNumber,
      email,
      password,
      referCode: refCode,
      gender,
      phoneCode: '+65',
      visitReason,
      ...whatsappInfo,
      marketingSubscription: {
        ...receiveNews,
      },
      ...(customerData ? { clientOrgCustomerId: customerData.customerNo } : {}),
    };

    console.log('form dataa ===>', data);
    dispatch(registerUser(data));
  };

  const handleDateOfBirthChange = value => {
    setDob(value);
    setIsCalendarOpen(false);
  };

  const toggleCalendar = event => {
    event.stopPropagation();
    setIsCalendarOpen(!isCalendarOpen);
  };

  return (
    <div className="od-signup-page-m">
      <Top />
      <div className="od-signup-page-m__content">
        <h4 className="od-signup-page-m-h4">Signup</h4>
        <p className="od-signup-page-m-p">Create an account and enjoy membership benefits</p>
        <form id="signupForm" onSubmit={handleSubmit}>
          <input
            required
            className="od-signup-page-m__input"
            placeholder="Full name"
            type="text"
            value={fullName}
            onChange={e => setFullName(e.target.value)}
          />
          {nameErr && <div className="od-signup-page-m-input-error">{nameErr}</div>}
          <div className="od-signup-page-m-number-wrapper">
            <input
              disabled
              className={`od-signup-page-m-number-wrapper_code od-signup-page-m__input ${
                phoneNumber && whatsappInfo?.mobileNo && 'od-signup-page-m__input--disabled'
              }`}
              placeholder="+65"
            />
            <input
              required
              className={`od-signup-page-m__input od-signup-page-m-number-wrapper_number ${
                phoneNumber && whatsappInfo?.mobileNo && 'od-signup-page-m__input--disabled'
              }`}
              placeholder="Phone no"
              type="number"
              value={phoneNumber}
              onChange={e => {
                const phoneValue = e.target.value;
                setPhoneNumber(phoneValue.slice(0, 8));
              }}
            />
          </div>
          {phoneErr && <div className="od-signup-page-m-input-error">{phoneErr}</div>}
          <input
            required
            className="od-signup-page-m__input"
            placeholder="Email id"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          {/* <input
            required
            className="od-signup-page-m__input"
            placeholder="Date of birth (dd/mm/yyyy)"
            type="date"
            value={dob}
            onChange={e => setDob(e.target.value)}
          /> */}
          <div>
            <CustomDatePicker
              containerClassName="od-signup-page-m__dob-container"
              inputClass="od-signup-page-m__input"
              isCalendarOpen={isCalendarOpen}
              setIsCalendarOpen={setIsCalendarOpen}
              value={dob}
              onChange={handleDateOfBirthChange}
              onClick={toggleCalendar}
            />
          </div>
          {dobErr && <div className="od-signup-page-m-input-error">{dobErr}</div>}
          <input
            required
            className="od-signup-page-m__input"
            placeholder="Password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <select
            className="od-signup-page-m__input"
            id="gender"
            name="gender"
            value={gender}
            onChange={e => setGender(e.target.value)}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          {storeCode && (
            <select
              className="visitPurpose_od_desktop"
              id="visitPurpose"
              name="genvisitPurposeder"
              value={visitReason}
              onChange={e => setVisitReason(e.target.value)}
            >
              <option value="buyGlasses">Glasses</option>
              <option value="contactLens">Contact Lens</option>
              <option value="lensReplacement">Lens Replacement</option>
              <option value="afterCare">Warranty</option>
            </select>
          )}
          {showRefCode && (
            <input
              className="od-signup-page-m__input"
              placeholder="Referral code (optional)"
              type="text"
              value={refCode}
              onChange={e => setRefCode(e.target.value)}
            />
          )}

          <ReceiveNews
            agreeAll={agreeAll}
            receiveNews={receiveNews}
            setAgreeAll={setAgreeAll}
            setReceiveNews={setReceiveNews}
            setTermsAccepted={setTermsAccepted}
            termsAccepted={termsAccepted}
          />

          {!showRefCode && (
            <div>
              <span className="od-signup-page-m__linkText" onClick={() => setShowRefCode(true)}>
                Add Referral Code Here
              </span>
            </div>
          )}
        </form>
      </div>
      <div className="od-signup-page-m__footer">
        <div className="od-login-page-m__footer__content">
          <div className="text-center">
            Already have an account?{' '}
            <span
              className="od-signup-page-m__linkText"
              onClick={() => {
                if (storeCode) {
                  history.push(
                    `/customer/account/login?whatsappId=${whatsappId}&mobileNo=${mobileNo}&storeCode=${storeCode}`
                  );
                } else {
                  history.push('/customer/account/login');
                }
              }}
            >
              Sign in
            </span>
          </div>
          <button
            className={`od-signup-page-m__sb ${termsAccepted ? 'terms-accepted' : ''}`}
            disabled={!termsAccepted}
            form="signupForm"
            type="submit"
          >
            {authLoading ? 'Loading...' : 'Complete signup'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Signup;
