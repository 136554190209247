import './spinner.scss';
import React from 'react';

const Spinner = props => (
  <div
    className={`${props.fullpage ? 'page-loading-overlay' : 'lkspinner-container'}`}
    data-testid="lkspinner"
    style={props.style}
  >
    <div className="lkspinner"></div>
  </div>
);

export default Spinner;
