import React from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { connect } from 'react-redux';
import {
  showBarcodeModal,
  fetchPidDataFromBarcode,
  setToastMessage,
} from '../../../actionCreators/common';

const qrcodeRegionId = 'html5qr-code-full-region';
let html5QrCode;

class Html5QrcodePlugin extends React.Component {
  componentWillUnmount() {
    try {
      html5QrCode.stop();
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    const { fetchPidDataFromBarcode } = this.props;
    const width = window.innerWidth;
    const height = window.innerHeight;
    const aspectRatio = width / height;
    const reverseAspectRatio = height / width;

    const mobileAspectRatio =
      reverseAspectRatio > 1.5
        ? reverseAspectRatio + (reverseAspectRatio * 7) / 100
        : reverseAspectRatio;

    Html5Qrcode.getCameras()
      .then(devices => {
        if (devices && devices.length) {
          html5QrCode = new Html5Qrcode(qrcodeRegionId);
          html5QrCode
            .start(
              { facingMode: 'environment' },
              {
                fps: 10,
                qrbox: { width: 288, height: 288 },
                aspectRatio: width < 600 ? mobileAspectRatio : aspectRatio,
              },
              (decodedText, decodedResult) => {
                fetchPidDataFromBarcode({ barcodeId: decodedText });
              },
              errorMessage => {
                // parse error, ignore it.
              }
            )
            .catch(err => {
              console.log(err);
            });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentDidUpdate(prevProps) {
    const {
      pidFromBarcodeData,
      history,
      showBarcodeModal,
      showSearch,
      pidFromBarcodeError,
      setToastMessage,
    } = this.props;
    if (
      pidFromBarcodeData?.productId &&
      prevProps.pidFromBarcodeData?.productId !== pidFromBarcodeData?.productId
    ) {
      showBarcodeModal(false);
      showSearch(false);
      history.push({
        pathname: `/product/${pidFromBarcodeData?.productId}`,
      });
    }
    if (pidFromBarcodeError && prevProps.pidFromBarcodeError !== pidFromBarcodeError) {
      setToastMessage({
        message: pidFromBarcodeError,
        timeout: 4000,
        warning: true,
      });
    }
  }

  render() {
    return <div id={qrcodeRegionId} />;
  }
}

const mapStateToProps = state => ({
  pidFromBarcodeData: state.common.pidFromBarcodeData,
  pidFromBarcodeError: state.common.pidFromBarcodeError,
});

function mapDispatchToProps(dispatch) {
  return {
    fetchPidDataFromBarcode: data => dispatch(fetchPidDataFromBarcode(data)),
    showBarcodeModal: data => dispatch(showBarcodeModal(data)),
    setToastMessage: data => dispatch(setToastMessage(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Html5QrcodePlugin);
