import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getSetCookie from './../../../../cookie';
import { dittoAuth } from '../../../../actionCreators/common';
import { addNewDitto, saveUpdateDitto } from './../../../../actionCreators/ditto';
import Panel from './../../../../mobile/components/Panel/Panel';
import UserInfo from '../../UserInfo/UserInfo';
import LinkProvider from './../../../../CommonComponents/LinkProvider/LinkProvider';
import { setDataAttr, capitalizeFirstLetter } from './../../../../utils/helper';
import './Nav.scss';
import AccordionGroup from './../../../Mobile/AccordionGroup/AccordionGroup';
import { v4 } from 'uuid';
import { logoutUser } from './../../../../actionCreators/auth';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { setPreviousUrl } from '../../../../actionCreators/auth';
import { hideOdLoader, showOdLoader } from '../../../../actionCreators/ui';
import { pushDataLayerEvent } from '../../../../utils/DataLayer';

const Nav = ({ history, toggleMenu }) => {
  const login = useSelector(state => state.common.login);
  const userInfo = useSelector(state =>
    state.common.userInfo ? state.common.userInfo.result : null
  );
  const redisCommonData = useSelector(state => state.common.redisCommonData);
  const navigationData = useSelector(state =>
    state.header.mobileNavigationData ? state.header.mobileNavigationData.menuData : null
  );
  const appDownloadHeader = useSelector(state => state.header.appDownloadHeader);
  const dataLocale = useSelector(state => state.locale);
  const {
    LOGOUT,
    HI_GUEST,
    STORES,
    GIFT_CARD,
    COLLECTIONS,
    // Hi,
    // SYMBOL_COMMA,
    // MANAGE_NOTIFICATIONS,
    // MY_ORDERS,
    LOG_IN_SIGN_UP = 'Log in/Sign up',
  } = dataLocale;

  const dispatch = useDispatch();

  console.log('this is navigation data ===> mobile', navigationData);
  const [toggleuserInfoBtns, setToggleuserInfoBtns] = useState(false);
  const [isOpenContactLensCategory, setIsOpenContactLensCategory] = useState(false);

  const navVisible = __CLIENT__ && document.querySelector('.od-nav-visible');

  useEffect(() => {
    if (userInfo && userInfo.dittos && userInfo.dittos.length) {
      const dittoId = getSetCookie.getCookie('isDittoID');
      if (dittoId && login) {
        dispatch(addNewDitto(dittoId));
        dispatch(saveUpdateDitto(dittoId));
        dispatch(dittoAuth(dittoId));
      } else if (userInfo.dittos[0]) {
        getSetCookie.setCookie('isDittoID', userInfo.dittos[0]);
        dispatch(dittoAuth(userInfo.dittos[0]));
      } else if (userInfo?.cygnus?.cygnusId) {
        getSetCookie.setCookie('isDittoID', userInfo?.cygnus?.cygnusId);
        dispatch(dittoAuth(userInfo?.cygnus?.cygnusId));
      }
    }
  }, [dispatch, login, userInfo]);

  useEffect(() => {
    if (!redisCommonData?.ENABLE_CYGNUS && userInfo && userInfo.dittos && userInfo.dittos.length) {
      getSetCookie.setCookie('isDittoID', userInfo.dittos[0]);
      if (userInfo.dittos[0]) dispatch(dittoAuth(userInfo.dittos[0]));
    }
    if (redisCommonData?.ENABLE_CYGNUS && userInfo?.cygnus?.cygnusId) {
      getSetCookie.setCookie('isDittoID', userInfo?.cygnus?.cygnusId);
      if (userInfo.dittos[0]) dispatch(dittoAuth(userInfo?.cygnus?.cygnusId));
    }
  }, [dispatch, redisCommonData?.ENABLE_CYGNUS, userInfo]);

  const triggerDataLayerOnCtaClick = name => {
    name = name?.trim()?.toLowerCase() || '';
    const data = {
      event: 'cta_click',
      cta_name: name.replace(/[ ]+/gi, '-'),
      cta_flow_and_page: 'navigation-bar',
    };
    pushDataLayerEvent(data);
  };

  const onlogoutClick = () => {
    dispatch(showOdLoader());
    dispatch(logoutUser());

    triggerDataLayerOnCtaClick(LOGOUT);

    // This is just TEMP hack for time being :FIX_ME: later please
    setTimeout(() => {
      dispatch(hideOdLoader());
      toggleMenu();
      if (typeof window !== 'undefined') {
        window.location.assign('/sg/en');
      }
    }, [4000]);
    // toggleMenu();
    // localStorageHelper.removeItem('isCartDataOnce');
    // dispatch(logoutUser());
    // dispatch(validateSessionV2());
    // dtmMenu('Logout');
    // document.location.href = '/';
    // window?.Freshbots?.resetCustomer(true);
    // window?.sprChat?.('updateUserSettings');
  };

  const getWelcomeTitle = () => {
    if (userInfo && userInfo.firstName) {
      // Return if needed
      // return `${Hi}${SYMBOL_COMMA} ${userInfo.firstName} ${userInfo.lastName || ''}`;
      return `${userInfo.firstName} ${userInfo.lastName || ''}`;
    }
    return redisCommonData.GUEST_MSG ? redisCommonData.GUEST_MSG : HI_GUEST;
  };

  const handleUserInfoBtns = () => {
    setToggleuserInfoBtns(!toggleuserInfoBtns);
  };

  const [openSubcategories, setOpenSubcategories] = useState({});

  const handleAccordionToggle = subCategory => {
    setOpenSubcategories(prevState => ({
      ...prevState,
      [subCategory]: !prevState[subCategory],
    }));
  };

  const subCategoryData = subCategory => {
    const url = subCategory.href || subCategory.routerLink;
    const haveSubMenu = Boolean(subCategory.catItems);
    const subCatDataAttr =
      subCategory.gaObj && Object.keys(subCategory.gaObj).length > 0
        ? setDataAttr(subCategory.gaObj)
        : null;
    return { url, haveSubMenu, subCatDataAttr };
  };

  const menuSubCategory = (subCategory, subCatDataAttr, url, haveSubMenu) => {
    const menu = (
      <div
        key={subCategory.label}
        className={`od-nav__unit__item ${subCategory?.label?.replace(/\s+/g, '-')?.toLowerCase()}`}
        onClick={() => {
          handleAccordionToggle(subCategory.label);
        }}
      >
        <LinkProvider
          {...subCatDataAttr}
          className={`${haveSubMenu ? 'nav-link-accordion od-font-bold' : 'nav-link od-font-bold'}`}
          to={url}
          onClick={() => {
            if (!haveSubMenu) {
              triggerDataLayerOnCtaClick(subCategory.label);
              toggleMenu();
            }
          }}
        >
          {capitalizeFirstLetter(subCategory?.label?.toLowerCase())}
          {haveSubMenu && (
            <img
              alt="chevron"
              src={
                openSubcategories[subCategory.label]
                  ? '/assets/svg/od-chevron-down.svg'
                  : '/assets/svg/od-chevron-right.svg'
              }
            />
          )}
        </LinkProvider>
      </div>
    );
    return { menu };
  };

  const subMenus = subCategory => {
    return subCategory.catItems.map(item => {
      if (item.hideMenuItem) return null;
      const url = item.routerLink || item.href;
      const dataAttr =
        item.gaObj && Object.keys(item.gaObj).length > 0 ? setDataAttr(item.gaObj) : null;
      return (
        <div
          key={item.label}
          className={`od-nav__unit__item ${item?.label?.replace(/\s+/g, '-')?.toLowerCase()}`}
        >
          <LinkProvider
            {...dataAttr}
            className="nav-sublink"
            to={url}
            onClick={() => triggerDataLayerOnCtaClick(subCategory?.label)}
          >
            <img alt={item.img.alt} height="32px" src={item.img.src} width="32px" />
            <span className="od-nav__unit__item-text">{item.label}</span>
          </LinkProvider>
        </div>
      );
    });
  };

  return (
    <nav
      aria-labelledby="secondary-navigation"
      className={`od-nav ${navVisible ? 'od-nav-visible' : ''} ${
        appDownloadHeader ? 'with-app-download-header' : ''
      }`}
    >
      <Panel className="app-nav-container">
        <div className="app-nav-menu-container">
          <img
            alt="true"
            className="od-nav__button-close"
            height="24px"
            src="https://static.lenskart.com/media/owndays/mobile/img/od-misc/od-x-close-icon.svg"
            width="24px"
            onClick={toggleMenu}
          />
          {login && (
            <div className="od-nav-user-info">
              <UserInfo
                handleUserInfoBtns={handleUserInfoBtns}
                isUserBtnsExpanded={toggleuserInfoBtns}
                redisCommonData={redisCommonData}
                userName={getWelcomeTitle()}
              />

              {login && toggleuserInfoBtns && (
                <div className="od-nav-user-info__btns">
                  <ButtonOwnDays
                    borderless
                    noHover
                    classes="od-nav-user-info__btn"
                    fontClass="od-font-reg"
                    mode="grey-secondary"
                    text="My Account"
                    onClick={() => {
                      window.location.assign('/sg/en/customer/account/edit');
                    }}
                  />
                  <ButtonOwnDays
                    borderless
                    noHover
                    classes="od-nav-user-info__btn"
                    fontClass="od-font-reg"
                    mode="grey-secondary"
                    text="Prescriptions"
                    onClick={() => {
                      window.location.assign('/sg/en/customer/prescriptions');
                    }}
                  />
                  <ButtonOwnDays
                    borderless
                    noHover
                    classes="od-nav-user-info__btn"
                    fontClass="od-font-reg"
                    mode="grey-secondary"
                    text="Wishlist"
                    onClick={() => {
                      window.location.assign('/sg/en/shortlist');
                    }}
                  />
                  <ButtonOwnDays
                    borderless
                    noHover
                    classes="od-nav-user-info__btn"
                    fontClass="od-font-reg"
                    mode="grey-secondary"
                    text="Membership"
                    onClick={() => {
                      window.location.assign('/sg/en/customer/membership');
                    }}
                  />
                  {/* <ButtonOwnDays
                    borderless
                    noHover
                    classes="od-nav-user-info__btn"
                    fontClass="od-font-reg"
                    mode="grey-secondary"
                    text="Saved Card"
                    onClick={() => {
                      window.location.assign('/');
                    }}
                  /> */}
                  <ButtonOwnDays
                    borderless
                    noHover
                    classes="od-nav-user-info__btn"
                    fontClass="od-font-reg"
                    mode="grey-secondary"
                    text="Order History"
                    onClick={() => {
                      window.location.assign('/sg/en/customer/account');
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {navigationData &&
            navigationData.map(category => {
              const unitContent = [
                category.categoryTitle && (
                  <div
                    key={category.categoryTitle}
                    className={`nav-title ${category.categoryTitle
                      ?.replace(/\s+/g, '-')
                      ?.toLowerCase()}`}
                  >
                    <strong>{category.categoryTitle}</strong>
                  </div>
                ),
              ];
              if (category.items) {
                const accordionMenus = [];
                category.items.forEach(subCategory => {
                  if (subCategory.hideMenuItem) return null;
                  const { url, haveSubMenu, subCatDataAttr } = subCategoryData(subCategory);
                  const { menu } = menuSubCategory(subCategory, subCatDataAttr, url, haveSubMenu);
                  if (haveSubMenu) {
                    const submenus = subMenus(subCategory);
                    accordionMenus.push({ header: menu, body: submenus });
                  } else {
                    unitContent.push(menu);
                  }
                  // need to improve the return, because now it is useless
                  if (haveSubMenu) {
                    return { menu, submenus: true };
                  }
                  return { menu, submenus: null };
                });
                if (accordionMenus.length)
                  unitContent.push(
                    <AccordionGroup
                      key={`accordion-menu-${category.categoryTitle}`}
                      multiOpen
                      activeClass="nav-submenu-accordion--active"
                      className="nav-submenu-accordion"
                      content={accordionMenus}
                      state={openSubcategories}
                    />
                  );
              }
              return (
                <div key={v4()} className="od-nav__unit">
                  {unitContent}
                </div>
              );
            })}
          <div className="od-nav__unit">
            {navigationData &&
              navigationData.map(item => {
                return (
                  item.blocks &&
                  item.blocks.length > 0 &&
                  item.blocks.map(block => {
                    if (block.type === 'COLLECTIONS') {
                      return (
                        <div key={v4()} className="od-nav__unit__item">
                          <div
                            className="nav-link od-font-bold contact-us"
                            onClick={() => {
                              triggerDataLayerOnCtaClick(COLLECTIONS?.toLowerCase());
                              window.location.href = block?.url;
                            }}
                          >
                            {capitalizeFirstLetter(block.type?.toLowerCase())}
                          </div>
                        </div>
                      );
                    } else if (block.type === 'STORES') {
                      return (
                        <div key={v4()} className="od-nav__unit__item">
                          <div
                            className="nav-link od-font-bold contact-us"
                            onClick={() => {
                              triggerDataLayerOnCtaClick(STORES?.toLowerCase());
                              window.location.href = block?.url;
                            }}
                          >
                            {capitalizeFirstLetter(STORES?.toLowerCase())}
                          </div>
                        </div>
                      );
                    } else if (block.type === 'TYPE_GIFT_CARD') {
                      return (
                        <div key={v4()} className="od-nav__unit__item">
                          <div
                            className="nav-link od-font-bold gift-card"
                            onClick={() => {
                              triggerDataLayerOnCtaClick(GIFT_CARD?.toLowerCase());
                              window.location.href = block?.url;
                            }}
                          >
                            {capitalizeFirstLetter(GIFT_CARD?.toLowerCase())}
                          </div>
                        </div>
                      );
                    } else if (block.type === 'CONTACT_LENS') {
                      const hasSubcategories =
                        block.subcategories && block.subcategories.length > 0;
                      return (
                        <div key={v4()} className="od-nav__unit__item">
                          <div
                            className="nav-link od-font-bold gift-card"
                            onClick={() => {
                              if (!isOpenContactLensCategory)
                                setIsOpenContactLensCategory(!isOpenContactLensCategory);
                            }}
                          >
                            <span>{capitalizeFirstLetter(block?.title?.toLowerCase())}</span>
                            {hasSubcategories && (
                              <img
                                alt="chevron down black"
                                className={`od-nav__unit__item-toggler ${
                                  isOpenContactLensCategory && 'od-nav__unit__item-toggler--active'
                                }`}
                                src="https://static.lenskart.com/media/owndays/mobile/img/od-misc/od-chevron-down-black.svg"
                              />
                            )}
                          </div>
                          {block.subcategories &&
                            block.subcategories.length > 0 &&
                            isOpenContactLensCategory && (
                              <div className="od-subcategories">
                                {block.subcategories.map(subcategory => (
                                  <div key={v4()} className="od-subcategory">
                                    <div
                                      className="od-font-reg"
                                      onClick={() => {
                                        triggerDataLayerOnCtaClick(
                                          subcategory?.title?.toLowerCase()
                                        );
                                        window.location.href = subcategory?.url;
                                      }}
                                    >
                                      {capitalizeFirstLetter(subcategory?.title?.toLowerCase())}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      );
                    }
                    // Return if need additional blocks: TYPE_HELP, TYPE_CONTACT_US, STORES
                    // } else if (block.type === 'TYPE_CONTACT_US') {
                    //   return (
                    //     <div key={v4()} className="od-nav__unit__item">
                    //       <div
                    //         className="nav-link contact-us getGaData"
                    //         data-action="TYPE_MENU_LINK"
                    //         data-category="Menu_Clicks"
                    //         data-event="Menu_Clicks"
                    //         data-label="Contact Us"
                    //         onClick={handleWidget}
                    //       >
                    //         {CONTACT_US}
                    //       </div>
                    //     </div>
                    //   );
                    // }
                    // if (block.type === 'TYPE_HELP') {
                    //   return (
                    //     <div key={v4()} className="od-nav__unit__item">
                    //       <Link className="nav-sublink" to={block.data.url}>
                    //         HELP
                    //       </Link>
                    //     </div>
                    //   );
                    // }
                    return null;
                  })
                );
              })}
          </div>
          {login ? (
            <div className="od-nav__unit">
              <div className="od-nav__unit__item" onClick={onlogoutClick}>
                <span className="nav-link od-font-bold logout">{LOGOUT}</span>
              </div>
            </div>
          ) : (
            <div className="od-nav__unit">
              <div
                className="od-nav__unit__item"
                onClick={() => {
                  dispatch(setPreviousUrl({ url: window.location.pathname }));
                  triggerDataLayerOnCtaClick(LOG_IN_SIGN_UP);
                  history.push({
                    pathname: '/customer/account/login',
                    state: { prevUrl: window.location.pathname },
                  });
                }}
              >
                <span className="nav-link od-font-bold logout">{LOG_IN_SIGN_UP}</span>
              </div>
            </div>
          )}
          <ButtonOwnDays
            classes="od-nav__book-eye-test"
            fontClass="od-font-reg"
            onClick={() => {
              triggerDataLayerOnCtaClick('Book Free Eye Test');
              if (window?.location?.href?.includes('preproduction')) {
                window.location.href = 'https://preproduction.owndays.com/stores/sg';
              } else {
                window.location.href = 'https://www.owndays.com/stores/sg';
              }
            }}
          >
            <img
              alt="Book Free Eye Test"
              height="24px"
              src="https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-book-eye-test.svg"
              width="22px"
            />

            <span>Book Free Eye Test</span>
          </ButtonOwnDays>
        </div>
      </Panel>
    </nav>
  );
};

export default Nav;
