import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../../../CommonComponents/Spinner/Spinner';

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'notFound' */ './NotFound').then(module => module.default),
  loading: () => <Spinner />,
});

export default NotFound;
