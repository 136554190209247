import React, { useState, useEffect, useCallback } from 'react';
import { disableBackgroundScroll, enableBackgroundScroll } from '../../../helper';
import Search from '../../../../mobile/containers/Search/Search';
import QrScanner from '../../../../mobile/components/QrScanner/QrScanner';
import { useSelector } from 'react-redux';
import { triggerTracking } from './HeaderContainer.helper';
import Header from '../Header/Header';
import Nav from './../Nav/Nav';

const HeaderContainer = props => {
  const { dataLocale, history, isSecondaryHeader } = props;

  const [scrolled, setScrolled] = useState(false);
  const [appNavContainer, setAppNavContainer] = useState(null);
  const [appNav, setAppNav] = useState(null);
  const [appMenu, setAppMenu] = useState(null);
  const [isMenuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    setAppNavContainer(document.querySelector('.app-nav-container .collapse'));
    setAppNav(document.querySelector('nav.od-nav'));
    setAppMenu(document.querySelector('.od-header__panel .od-header__toggle'));

    const blockElement = document.getElementById('od-home-page-video');
    let heightBlock;
    if (blockElement) {
      const height = blockElement.offsetHeight;
      heightBlock = height;
    }

    const handleScroll = () => {
      const isScrolled = window.scrollY > heightBlock / 2;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isExchange = useSelector(state => state.returnExchange.isExchange);
  const userInfo = useSelector(
    state => (state.common.userInfo && state.common.userInfo.result) || null
  );
  const isBarcodeModalActive = useSelector(state => state.common.isBarcodeModalActive);

  const showMenu = useCallback(() => {
    if (appNavContainer && appNav && appMenu) {
      document.body.classList.add('overflow-hidden');
      appNavContainer.classList.add('in');
      appNav.classList.add('od-nav-visible');
      appMenu.classList.add('od-nav-visible');
      setMenuVisible(true);
    }
  }, [appMenu, appNav, appNavContainer]);

  const hideMenuHandler = useCallback(() => {
    if (appNavContainer) {
      document.body.classList.remove('overflow-hidden');
      appNavContainer.classList.remove('in');
      appNav.classList.remove('od-nav-visible');
      appMenu.classList.remove('od-nav-visible');
      const freshdeskContainer = document.querySelector('#freshworks-container');
      if (freshdeskContainer) {
        freshdeskContainer.style.display = 'none';
      }
      setMenuVisible(false);
    }
  }, [appMenu, appNav, appNavContainer]);

  const [showSearch, setShowSearch] = useState(false);
  const [timeOutId, setTimeOutId] = useState(false);

  const showSearchHandler = showSearch => {
    setShowSearch(showSearch);
    hideMenuHandler();
    if (showSearch) {
      disableBackgroundScroll();
    } else {
      enableBackgroundScroll();
    }
  };

  const toggleMenu = useCallback(() => {
    if (appMenu) {
      const hideMenu = isMenuVisible;
      if (hideMenu) {
        hideMenuHandler();
      } else {
        showMenu();
        if (userInfo?.login) triggerTracking(userInfo, dataLocale, timeOutId, setTimeOutId);
      }
    }
  }, [appMenu, isMenuVisible, hideMenuHandler, showMenu, userInfo, dataLocale, timeOutId]);

  useEffect(() => {
    if (timeOutId) clearTimeout(timeOutId);
  }, [timeOutId]);

  return (
    <>
      <Header
        dataLocale={dataLocale}
        isSecondaryHeader={isSecondaryHeader && !scrolled}
        showSearch={showSearchHandler}
        onClickMenu={toggleMenu}
      />
      <Nav history={history} toggleMenu={toggleMenu} />
      {!isExchange && <Search showSearch={showSearchHandler} showSearchModal={showSearch} />}
      {isBarcodeModalActive && <QrScanner history={history} showSearch={showSearchHandler} />}
    </>
  );
};

export default HeaderContainer;
