import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../../../CommonComponents/Spinner/Spinner';

const ResetPassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'resetPassword' */ './ResetPassword').then(
      module => module.default
    ),
  loading: () => <Spinner />,
});

export default ResetPassword;
