import React from 'react';
import Loadable from 'react-loadable-visibility/react-loadable';
import Spinner from '../../../CommonComponents/Spinner/Spinner';

const LoadableModule = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'exchange-session' */ './ExchangeSession').then(
      module => module.default
    ),
  loading: () => <Spinner />,
});

export default LoadableModule;
