import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../../../CommonComponents/Spinner/Spinner';

const CheckoutLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'checkout' */ './Checkout').then(module => module.default),
  loading: () => <Spinner />,
});

export default CheckoutLoadable;
