import React, { useState } from 'react';
import './WishlistPageItem.scss';

import Pricing from '../../../../../CommonComponents/ProductCard/Pricing';

import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as commonCallsActionCreators from '../../../../../actionCreators/common';
import { removeDomainName, getCtaLabel, sunglassOrAccessories } from '../../../../helper';
import { addToCart } from '../../../../../actionCreators/cart';
import { toggleLensesSelectionPopup } from '../../../../../actionCreators/product';
import SelectLenses from '../../../../CommonComponents/SelectLensesModal/SelectLenses';
import { isGundamCollection } from '../../../desktop/Gundam/gundamHelper';

const WishlistPageItem = props => {
  const [toggleSelectLens, setToggleSelectLens] = useState(false);
  const { dataLocale, productData, localeInfo, history, commonCallsAction, login } = props;

  const { CURRENCY_SYMBOL, REMOVE } = dataLocale;

  const lenskartPrice = productData && productData?.prices[1].price;
  const firstBuyPrice =
    productData && productData?.prices.length === 3 && productData?.prices[2]?.price;
  const finalprice = productData?.prices.length === 3 ? firstBuyPrice : lenskartPrice;
  const marketPrice = productData?.prices[0].price;
  const productId = productData?.id;

  const dispatch = useDispatch();

  const redirectToProduct = () => {
    const url = productData.productUrl;
    if (url?.length > 0 && url.includes('sg/en/')) {
      window.location.assign(`/${removeDomainName(url)}`);
    } else {
      window.location.assign(`/sg/en/${removeDomainName(url)}`);
    }
  };

  const handleAddToCartCTA = () => {
    if (sunglassOrAccessories(productData?.classification)) {
      if (isGundamCollection(productId)) {
        dispatch(addToCart({ productId }));
      } else {
        dispatch(addToCart({ productId, powerType: 'Frame Only' }));
      }
      // setAddingToCart(true);
    } else if (productData?.classification === 'contact_lens') {
      // TODO: handle for contact lens
    } else {
      setToggleSelectLens(true);
    }
  };

  const removeFromWishlist = () => {
    commonCallsAction.shortListType(true);

    if (window.dtm?.LenskartRewamp)
      window.dtm.LenskartRewamp?.Listing?.click?.removeProdcutShortlist(login, productData.id);

    commonCallsAction.shortlist(productData.id, 'delete', `/${productData.id}`);

    dispatch(commonCallsActionCreators.shortlist('', 'get', '?attributes=true'));
  };

  const handleLensesModal = value => {
    props.toggleLensesSelectionPopup(value);
  };

  return (
    <>
      {toggleSelectLens && (
        <div style={{ position: 'absolute', zIndex: '15' }}>
          <SelectLenses handleModal={handleLensesModal} productData={productData} />
        </div>
      )}
      <div className="od-wishlist-page-item-mobile">
        <div
          className="od-wishlist-page-item-mobile__image"
          style={{
            backgroundImage: `url(${productData?.productImageUrl})`,
          }}
          onClick={redirectToProduct}
        />

        <p className="od-wishlist-page-item-mobile__title od-font-bold" onClick={redirectToProduct}>
          {productData?.brandName} {productData?.modelName}
        </p>

        <Pricing
          isMobile
          currencySymbol={CURRENCY_SYMBOL}
          dataLocale={dataLocale}
          finalprice={finalprice}
          localeInfo={localeInfo}
          marketPrice={marketPrice}
        />

        <div className="od-wishlist-page-item-mobile__model-name od-font-reg">{productId}</div>

        <hr className="od-wishlist-page-item-mobile__divider" />

        <div className="od-wishlist-page-item-mobile__actions">
          <button
            className="od-wishlist-page-item-mobile__action od-font-bold"
            onClick={removeFromWishlist}
          >
            {REMOVE}
          </button>

          {/* <div className="od-wishlist-page-item-mobile__actions-divider" /> */}

          <button
            className="od-wishlist-page-item-mobile__action od-font-bold"
            disabled={!productData?.inStock}
            style={!productData?.inStock ? { color: '#666', textDecoration: 'none' } : {}}
            onClick={handleAddToCartCTA}
          >
            {getCtaLabel(productData?.inStock ? 1 : 0, productData?.classification)}
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    dataLocale: state.locale,
    localeInfo: state.common.localeInfo,
    login: state.common.login,
    isLensesSelectionPopupOpen: state.product.isLensesSelectionPopupOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    commonCallsAction: bindActionCreators(commonCallsActionCreators, dispatch),
    toggleLensesSelectionPopup: data => dispatch(toggleLensesSelectionPopup(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistPageItem);
