import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { setWindowDtm } from './../../../../utils/DTM';
import { Link } from 'react-router-dom';
import './Header.scss';
import { pushDataLayerEvent } from '../../../../utils/DataLayer';
import BurgerBlueIcon from './../../../../../static/assets/svg/od-menu-burger-blue.svg';
import HeartBlueIcon from './../../../../../static/assets/svg/od-heart-blue.svg';
import DiscountBanner from '../../../../CommonComponents/DiscountBanner/DiscountBanner';

const COUNTRY = 'sg';
const LANG = 'en';
const baseImgUrl = 'https://static.lenskart.com/media/owndays/desktop/img/od-logos-new';

const getBadge = count => {
  if (!count) return null;
  return <div className="badge">{count}</div>;
};

const Header = props => {
  const { onClickMenu, showSearch, isSecondaryHeader } = props;
  const cartCount = useSelector(state => state.common.isCartCount);
  const wishlistCount = useSelector(state => state.common.isWishlistCount);
  const appLogo = useSelector(state => state.common.appLogo);
  const { HOST_COUNTRY } = useSelector(state => state.common.redisCommonData);
  const localeInfo = useSelector(state => state.common.localeInfo);
  const userInfo = useSelector(state => state.common.userInfo);
  const loginSuccess = useSelector(state => state.auth.loginSuccess);

  const [headerHeight, setHeaderHeight] = useState(0);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const navRef = useRef(null);
  const path = window.location.pathname;

  const isCheckoutFlow =
    path.includes('/cart') ||
    path.includes('/payment-success') ||
    path.includes('/payment-confirm');

  const [isResetPassword, setIsResetPassword] = useState(false);

  useEffect(() => {
    const bannerElement = document.querySelector('.discount-banner');

    if (bannerElement) {
      const bannerHeight = bannerElement.offsetHeight;
      setBannerHeight(bannerHeight);
    }

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [bannerHeight]);

  useEffect(() => {
    const handleLoad = () => {
      if (navRef.current) {
        const height = navRef.current.offsetHeight;
        setHeaderHeight(height);
      }
    };

    window.addEventListener('load', handleLoad);
    return () => window.removeEventListener('load', handleLoad);
  }, []);

  const onClickLogo = () => {
    // OD__CLEVERTAP__EVENT (Logo Click)
    // if (typeof window.clevertap !== 'undefined') {
    //   window.clevertap.event.push('Logo Click', {
    //     pageName: window.location.pathname,
    //   });
    //   console.log(
    //     `🚀 ~ Logo Click ~ pageName: ${window.location.pathname}:`,
    //     window.clevertap.event
    //   );
    // }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    window.location.href = `${window.location.origin}/${COUNTRY}/${LANG}/`;
  };

  const dtmShortlist = () => {
    const dtm = {
      linkName: 'web|header|wishlist',
      linkPageName: window.dtm.pageName || {},
      event: 'generic-int-link',
    };
    setWindowDtm(dtm, true);
  };

  const dtmCart = () => {
    const dtm = {
      linkName: 'web|home|cart',
      linkPageName: window.dtm.pageName || {},
      event: 'generic-int-link',
    };
    setWindowDtm(dtm, true);
  };

  const triggerDataLayerOnCtaClick = name => {
    name = name?.trim().toLowerCase().replace(/[ ]+/gi, '-') || '';
    const data = {
      event: 'cta_click',
      cta_name: name,
      cta_flow_and_page: 'navigation-bar',
    };
    pushDataLayerEvent(data);
  };

  // useEffect(() => {
  //   if (loginSuccess && userInfo) {
  //     // OD__CLEVERTAP__EVENT (Login)
  //     if (typeof window.clevertap !== 'undefined') {
  //       window.clevertap.event.push('Login', {
  //         name: userInfo.firstName,
  //         email: userInfo.email,
  //         dob: userInfo.dob,
  //         phoneNumber: userInfo.telephone,
  //       });
  //       console.log('🚀 ~ Login ~ userInfo:', userInfo);
  //       console.log('🚀 ~ Login:', window.clevertap.event);
  //     }

  //     odPushClevertapEvent('LogIn', {
  //       loginSuccess,
  //     });
  //   }
  // }, [loginSuccess, userInfo]);

  const handleBookEyeTest = () => {
    if (window?.location?.href?.includes('preproduction')) {
      window.location.href = 'https://preproduction.owndays.com/stores/sg';
    } else {
      window.location.href = 'https://www.owndays.com/stores/sg';
    }
  };

  useEffect(() => {
    if (window.location.pathname && !isResetPassword) {
      const detectPath = (currentPath, targetPath) =>
        currentPath?.includes(`/sg/en${targetPath}`) || currentPath?.includes(targetPath);
      setIsResetPassword(
        Boolean(detectPath(window.location.pathname, '/customer/account/resetpassword'))
      );
    }
  }, [isResetPassword]);

  return (
    <>
      {!isResetPassword && (
        <>
          {/* show discount banner all page used header component */}
          <DiscountBanner />
          <div
            style={{
              minHeight: '61px',
              height: !isSecondaryHeader && headerHeight ? `${headerHeight}px` : '0',
            }}
          />
          <header className="od-header">
            <nav
              ref={navRef}
              aria-labelledby="primary-navigation"
              className="od-header__panel"
              style={{
                backgroundColor: isSecondaryHeader ? '' : '#fff',
                top: scrollPosition < bannerHeight / 2 ? bannerHeight : 0,
              }}
            >
              <div className="od-header__toggle">
                <img
                  alt="true"
                  src={
                    isCheckoutFlow
                      ? BurgerBlueIcon
                      : isSecondaryHeader
                      ? 'https://static.lenskart.com/media/owndays/mobile/img/od-misc/od-menu-burger-white.svg'
                      : 'https://static.lenskart.com/media/owndays/mobile/img/od-misc/od-menu-burger.svg'
                  }
                  style={
                    isSecondaryHeader
                      ? { width: '25px', height: '25px' }
                      : { width: '20px', height: '20px' }
                  }
                  onClick={() => onClickMenu()}
                />
                <div className="od-header__logo">
                  <img
                    alt="owndays logo"
                    src={
                      isSecondaryHeader
                        ? `${baseImgUrl}/logo-type-white.png`
                        : appLogo || `${baseImgUrl}/logo-type-black.png`
                    }
                    className="od-header__logo-img"
                    onClick={onClickLogo}
                  />
                </div>
              </div>
              {!isCheckoutFlow && (
                <input
                  disabled
                  name="hidden-Field"
                  style={{
                    width: HOST_COUNTRY ? '20px' : 0,
                    height: '17px',
                    marginLeft: HOST_COUNTRY ? '10px' : 0,
                    visibility: !HOST_COUNTRY ? 'hidden' : 'visible',
                    padding: 0,
                  }}
                  value={localeInfo?.countryCode}
                />
              )}

              <div className="inline-block pull-right">
                {!isCheckoutFlow && (
                  <div
                    className="icon"
                    data-testid="showSearch"
                    onClick={() => {
                      showSearch(true);
                      triggerDataLayerOnCtaClick('search');
                    }}
                  >
                    <img
                      alt="true"
                      height="25px"
                      src={
                        isSecondaryHeader
                          ? 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-search-white-icon.svg'
                          : 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/search-md.svg'
                      }
                      width="25px"
                    />
                  </div>
                )}
                <Link
                  className="icon pos-rel"
                  data-testid="dtmShortlist"
                  to="/shortlist"
                  onClick={() => {
                    dtmShortlist();
                    triggerDataLayerOnCtaClick('wishlist');
                  }}
                >
                  <img
                    alt="true"
                    height="25px"
                    src={
                      isCheckoutFlow
                        ? HeartBlueIcon
                        : isSecondaryHeader
                        ? 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-heart-white-icon.svg'
                        : 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-heart-icon.svg'
                    }
                    width="25px"
                  />
                  {getBadge(wishlistCount)}
                </Link>
                {!isCheckoutFlow && (
                  <Link
                    className="icon pos-rel"
                    data-testid="dtmCart"
                    to="/cart"
                    onClick={() => dtmCart()}
                  >
                    <img
                      alt="true"
                      height="25px"
                      src={
                        isSecondaryHeader
                          ? 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-cart-white-icon.svg'
                          : 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-cart-icon.svg'
                      }
                      width="25px"
                    />
                    {getBadge(cartCount)}
                  </Link>
                )}
              </div>
            </nav>
          </header>
        </>
      )}
    </>
  );
};

export default Header;
